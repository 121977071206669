import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {getCandidateById} from '../selectors';

const useCandidate = candidateId => {
  const doGetCandidateById = useCallback(state => (candidateId ? getCandidateById(state, candidateId) : null), [candidateId]);

  return useSelector(doGetCandidateById);
};

export default useCandidate;
