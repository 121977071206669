import React from 'react';
import Wrapper from './Wrapper';
import PropTypes from 'prop-types';
import {Position} from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import {omit} from './deepMerge';

export default class DatePicker extends Wrapper {
  inputRef = React.createRef();

  static propTypes = {
    format: PropTypes.string.isRequired,
  };

  static defaultProps = {
    format: 'D MMM, YYYY',
  };

  forceFocus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  onChange = date => {
    const {onChange, fieldApi} = this.props;

    fieldApi.setValue(date);

    if (onChange) {
      onChange(date);
    }
  };

  formatDate = date => {
    if (!date) return '';

    const parsedDate = dayjs(date);

    return parsedDate.isValid() ? dayjs(date).format(this.props.format) : '';
  };

  parseDate = str => {
    if (!str) return null;

    const date = dayjs(str);

    return date.isValid() ? date.toDate() : false;
  };

  getValue() {
    return this.parseDate(this.props.fieldApi.value);
  }

  getMaxDate() {
    return dayjs(this.props.maxDate || this.getValue() || undefined)
      .startOf('month')
      .add(10, 'years')
      .toDate();
  }

  getMinDate() {
    return dayjs(this.getValue() || undefined)
      .startOf('month')
      .subtract(99, 'years')
      .toDate();
  }

  render() {
    const props = {
      ...omit(this.props, [
        'api',
        'required',
        'name',
        'field',
        'label',
        'rules',
        'children',
        'className',
        'onChange',
        'fieldApi',
        'autoFocus',
      ]),
      value: this.getValue(),
    };

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel({onClick: this.forceFocus})}
        <DateInput
          fill
          formatDate={this.formatDate}
          parseDate={this.parseDate}
          onChange={this.onChange}
          maxDate={this.getMaxDate()}
          minDate={this.getMinDate()}
          inputProps={{
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            inputRef: this.inputRef,
          }}
          popoverProps={{
            position: Position.BOTTOM,
            boundary: 'viewport',
          }}
          {...props}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
