import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getLanguagesFilteredBy, getLanguagesLoading, getLanguagesError, getLanguagesLoaded, sortLanguagesByName} from '../selectors';
import {fetchLanguages} from '../actionCreators';

const filterLanguagesByName = name => state => getLanguagesFilteredBy(state, {name}, sortLanguagesByName);

const useLanguages = (nameFilter, params, forceReload = false) => {
  const languages = useSelector(filterLanguagesByName(nameFilter));
  const loaded = useSelector(getLanguagesLoaded);
  const loading = useSelector(getLanguagesLoading);
  const error = useSelector(getLanguagesError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchLanguages(params));
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, forceReload]);

  return [languages, loading, error];
};

export default useLanguages;
