import {combineReducers} from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AUTHORITIES':
      return action.authorities.reduce(
        (nextRole, role) => ({
          ...nextRole,
          [role.id]: {...state[role.id], ...role},
        }),
        {}
      );

    case 'UPDATE_AUTHORITIES':
      return action.authorities.reduce(
        (nextRole, role) => ({
          ...nextRole,
          [role.id]: {...nextRole[role.id], ...role},
        }),
        {...state}
      );

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'SET_AUTHORITIES':
      return action.authorities.map(authority => authority.id);

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_AUTHORITIES_LOADING':
      return true;
    case 'SET_AUTHORITIES':
    case 'UPDATE_AUTHORITIES':
    case 'SET_AUTHORITIES_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_AUTHORITIES':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_AUTHORITIES_LOADING':
    case 'SET_AUTHORITIES':
    case 'UPDATE_AUTHORITIES':
      return false;
    case 'SET_AUTHORITIES_ERROR':
      return action.message;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ids,
  loading,
  loaded,
  error,
});
