import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateProfile} from '../actionCreators';

const useCandidateMarkAsNew = candidateId => {
  const dispatch = useDispatch();

  return useCallback(data => dispatch(updateCandidateProfile(candidateId, {status: 'suggested'})), [dispatch, candidateId]);
};

export default useCandidateMarkAsNew;
