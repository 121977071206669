/**
 * deep clone an object
 * @param {object} obj - the object to clone
 * @return {object} cloned object
 */
export const deepClone = obj => JSON.parse(JSON.stringify(obj));

/**
 * Simple object check.
 * @param {mixed} item - the item to check if is an object
 * @return {boolean} whether the item is an object or not
 */
export const isObject = item => item && typeof item === 'object' && !Array.isArray(item) && item !== null;

/**
 * Deeply merge two objects.
 * @param {object} target - the target object to merge into
 * @param {object} ...sources - the object to merge into the target object
 * @return {object} the cloned object
 */
const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }

  return deepMerge(target, ...sources);
};

/**
 * Return a copy of an object excluding the given key, or array of keys. Also accepts an optional filter function as the last argument.
 * @param {object} obj object to filter
 * @param {mixed} props either a string or array of keys to omit
 * @param {function} fn function to handle the filtering
 * @return {object} object of filtered keys
 */
export const omit = (obj, props, fn) => {
  if (!isObject(obj)) return {};
  if (typeof props === 'function') {
    fn = props;
    props = [];
  }
  if (typeof props === 'string') {
    props = [props];
  }
  const isFunction = typeof fn === 'function';
  const keys = Object.keys(obj);
  const res = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const val = obj[key];

    if (!props || (props.indexOf(key) === -1 && (!isFunction || fn(val, key, obj)))) {
      res[key] = val;
    }
  }
  return res;
};

export default deepMerge;
