/* eslint-disable camelcase */
import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateWorkHistoryDefaultValues = (historyItem = {}) => {
  const {date_start, date_end, position, scout_firms_id, scout_addresses_id, notes, is_current} = historyItem || {};

  return useMemo(() => {
    if (!historyItem || !Object.keys(historyItem).length) {
      return {};
    }

    const startDate = dayjs.utc(date_start, 'YYYY-MM-DD');
    const endDate = dayjs.utc(date_end, 'YYYY-MM-DD');

    return {
      position,
      scout_firms_id: +scout_firms_id,
      scoutHistoryDateRange: {
        startDate: startDate.isValid() ? startDate.local() : '',
        endDate: endDate.isValid() ? endDate.local() : '',
      },
      scout_addresses_id,
      notes,
      is_current: +is_current === 1,
    };
  }, [historyItem, date_start, date_end, position, scout_firms_id, scout_addresses_id, notes, is_current]);
};

export default useCandidateWorkHistoryDefaultValues;
