import {ServiceForbidden, ServiceNotFound} from 'api';

import * as WorkmixApi from './api';
import {getWorkmixLoading} from './selectors';

export const fetchWorkmix = () => async (dispatch, getState) => {
  if (getWorkmixLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setWorkmixLoading());

  try {
    const response = await WorkmixApi.list();

    dispatch(setWorkmix(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setWorkmix([]));

      return null;
    }
    dispatch(setWorkmixError(e.message));
  }
  return null;
};

export const createWorkmix = workmix => async (dispatch, getState) => {
  const response = await WorkmixApi.post(workmix);

  dispatch(setWorkmix([response.data]));

  return response.data;
};

export const saveWorkmix = (id, workmix) => async (dispatch, getState) => {
  const response = await WorkmixApi.put(id, workmix);

  dispatch(setWorkmix([response.data]));

  return response.data;
};

export const destroyWorkmix = id => async (dispatch, getState) => {
  const response = await WorkmixApi.destroy(id);

  dispatch(removeWorkmix([id]));

  return response.data;
};

export const detachWorkmix = (id, areaOfExpertiseId) => async (dispatch, getState) => {
  const response = await WorkmixApi.detach(id, areaOfExpertiseId);

  dispatch(setWorkmixDetachAreaOfExpertise(id, areaOfExpertiseId));

  return response.data;
};

export const setWorkmixDetachAreaOfExpertise = (id, areaOfExpertiseId) => ({
  type: 'SET_WORKMIX_DETACH_AREA_OF_EXPERTISE',
  areaOfExpertiseId,
  id,
});

export const setWorkmixError = message => ({
  type: 'SET_WORKMIX_ERROR',
  message,
});

export const setWorkmixLoading = () => ({
  type: 'SET_WORKMIX_LOADING',
});

export const setWorkmix = workmix => ({
  type: 'SET_WORKMIX',
  workmix,
});

export const removeWorkmix = ids => ({
  type: 'REMOVE_WORKMIX',
  ids,
});
