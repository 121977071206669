import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    limit: 0,
    sort: 'created desc',
    ...params,
  });

  return Service.get(`pipelines?${parsedParams}`, config);
};

export const get = (id, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`pipelines/${id}?${parsedParams}`, config);
};

export const put = (id, data = {}, config = {}) => Service.put(`pipelines/${id}`, data, config);
export const post = (data = {}, config = {}) => Service.post('pipelines', data, config);
