/* eslint-disable camelcase */
import {ServiceFactory, CancelToken} from 'api';
import {applyFilter} from './helpers';

const Service = ServiceFactory();

const jsonBody = {
  q: null,
  filters: {},
};

// Do an API search request. This gets a list of candidate IDs matching the search
// filters provided.
export const search = (filters, sort, cancel) => {
  const requestBody = {...jsonBody};

  /* eslint-disable fp/no-mutation */
  requestBody.q = filters.fullnameOrKeywords ?? null;

  const {sortCriteria, ...remainingFilters} = filters;

  requestBody.filters = Object.entries(remainingFilters).reduce((nextFilters, [name, filter]) => {
    if (name === 'fullnameOrKeywords') return nextFilters;

    if (filter) {
      // eslint-disable-next-line no-param-reassign
      nextFilters[name] = applyFilter(name, filter);
    }

    return nextFilters;
  }, {});

  requestBody.withOrder = sortCriteria || [];

  /* eslint-enable fp/no-mutation */

  // May need to confirm default filters:
  // Status active, practicing true, doNotApproach true.

  // TODO: Sort in candidate search results.

  const Result = Service.post(`candidates/search`, requestBody, {
    cancelToken: cancel ? new CancelToken(cancel) : undefined,
  });

  return Result;
};
