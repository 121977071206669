import React from 'react';

const Message = ({type, show, message}) => {
  // When there are mutliple form validation errors on a field, only display the first one.
  message = typeof message === 'object' ? message[0] : message;

  const validationClassShow = show ? 'validation__message--show' : '';
  const validationClass = `validation__message validation__message--${type} ${validationClassShow}`;

  return (
    <div className="validation__container">
      <small className={validationClass}>{message}</small>
    </div>
  );
};

export default Message;
