import {useMemo} from 'react';

const useFilterSearchSelect = (array, available, idProp = 'id', valueProp = 'name', searchProp = 'name') =>
  useMemo(
    () =>
      array.map(item => ({
        id: item[idProp],
        search: String(item[searchProp || 'name'] ?? '').toLowerCase(),
        text: item[valueProp],
        unavailable: Array.isArray(available) ? !available.includes(item[idProp]) : false,
      })),
    [array, available, idProp, valueProp, searchProp]
  );

export default useFilterSearchSelect;
