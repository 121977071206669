import React from 'react';
import ValidateJS from 'validate.js';

import * as validators from './validators';

/**
 * Map over the props and apply any validation rules we recognise.
 * The available rules are exposed by our `validators` import above.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {Object} props       The properties to map over in or
 * @param  {Object} validate    [description]
 *
 * @return {Object} A map of validation rules compatible with validate.js
 */
const prepRules = (props, validate) =>
  Object.keys(props).reduce((nextRules, propKey) => {
    if (propKey in validators) {
      const rule = props[propKey];
      const validator = validators[propKey];

      return {
        ...nextRules,
        ...validator(rule),
      };
    }
    return nextRules;
  }, validate);

/**
 * Parses passed rules and
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {Component}  Field     A React component - should be a react-forms Field
 * @param  {Object}     defaults  Mapping of default validation rules to apply to the field.
 *
 * @return {Component} The wrapped Field component
 */
const withValidation = (Field, defaults = {}) => {
  return ({validate = {}, ...props}) => {
    // Get the prepared rules, and combine with any field validation rule definitions.
    // Note that passed rules will override field defined rules on purpose.
    const preparedRules = prepRules(props, validate);

    const rules = {
      error: {...defaults.error, ...preparedRules.error},
      warning: {...defaults.warning, ...preparedRules.warning},
      success: {...defaults.success, ...preparedRules.success},
    };

    return (
      <Field
        validate={value => {
          let error = null;
          let warning = null;
          let success = null;

          if ('error' in rules) error = ValidateJS.single(value, rules.error) || null;
          if ('warning' in rules) warning = ValidateJS.single(value, rules.warning) || null;
          if ('success' in rules) success = ValidateJS.single(value, rules.success) || null;

          return {
            error,
            warning,
            success,
          };
        }}
        rules={rules}
        {...props}
      />
    );
  };
};

export default withValidation;
