import React from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import Wrapper from './Wrapper';

import './RangeSlider.scss';

const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value',
};

export default class FormRangeSlider extends Wrapper {
  static propTypes = {
    allowSameValues: PropTypes.bool, //	Set to true to allow minValue and maxValue to be the same.
    classNames: PropTypes.object, // Override the default CSS classes applied to your component and its sub-components.
    disabled: PropTypes.bool, // If this property is set to true, your component is disabled. This means you'll not able to interact with it.
    draggableTrack: PropTypes.bool, // If this property is set to true, you can drag the entire track.
    formatLabel: PropTypes.func, // By default, value labels are displayed as plain numbers.
    // If you want to change the display, you can do so by passing in a function. The function can return something different,
    // i.e.: append a unit, reduce the precision of a number.
    // (value) => `${value}cm`
    maxValue: PropTypes.number.isRequired, // Set a maximum value for your component. You cannot drag your slider beyond this value.
    minValue: PropTypes.number.isRequired, // Set a minimum value for your component. You cannot drag your slider beyond this value.
    name: PropTypes.string, // Set a name for your form component.
    onChange: PropTypes.func,
    onChangeStart: PropTypes.func, // Whenever your user starts interacting with your component (i.e.: onMouseDown, or onTouchStart), this function gets called.
    onChangeComplete: PropTypes.func, // Every mouse / touch event can trigger multiple updates, therefore causing onChange callback to fire multiple times. On the other hand, onChangeComplete callback only gets called when the user stops dragging.
    step: PropTypes.number, // The default increment/decrement of your component is 1. You can change that by setting a different number to this property.
    includeCss: PropTypes.bool, // disable loading of the components base css
    isArchive: PropTypes.bool,
  };

  static defaultProps = {
    allowSameValues: false,
    classNames: DEFAULT_CLASS_NAMES,
    disabled: false,
    maxValue: 10,
    minValue: 0,
    includeCss: true,
    isArchive: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.getValue(),
    };
  }

  componentDidMount() {
    const {api, includeCss, isArchive} = this.props;
    if (isArchive) this.setState({value: 0});
    if (includeCss === true) {
      import('react-input-range/lib/css/index.css');
    }
    if (api) api(this);
  }

  onChange = value => {
    const {onChange, fieldApi} = this.props;

    this.setState({value});

    if (onChange) {
      onChange(value);
    }
  };

  onChangeStart = value => {
    const {onFocus, onChangeStart} = this.props;

    this.showTooltip();
    this.setState({hasFocus: true});

    if (onFocus) {
      onFocus(value);
    }
    if (onChangeStart) {
      onChangeStart(value);
    }
  };

  onChangeComplete = value => {
    const {onBlur, onChangeComplete, fieldApi} = this.props;

    fieldApi.setTouched();
    fieldApi.setValue(value);
    this.hideTooltip();
    this.setState({hasFocus: false, value});

    if (onBlur) {
      onBlur(value);
    }
    if (onChangeComplete) {
      onChangeComplete(value);
    }
  };

  renderLabel({...labelProps} = {}) {
    const {id, label, required, isArchive, formatLabel} = this.props;
    let labelStr = label;

    if (!label) {
      return null;
    }

    if (typeof label === 'function') {
      labelStr = label(this.state.value);
    }
    if (!isArchive) {
      return (
        <label htmlFor={id} className={this.getLabelClass()} {...labelProps}>
          {labelStr}&nbsp;
          {required && <span className="highlight">*</span>}
        </label>
      )
    }
    return (
      <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
        <label htmlFor={id} className={this.getLabelClass()} {...labelProps}>
          {labelStr}&nbsp;
          {required && <span className="highlight">*</span>}
        </label>
        <label htmlFor={id} className={this.getLabelClass()} {...labelProps}>
          {formatLabel(this.state.value)}&nbsp;
          {required && <span className="highlight">*</span>}
        </label>
      </div>
    );
  }

  render() {
    const {classElement, required, fieldApi, label, className, value, onChange, includeCss, ...rest} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}

        <InputRange
          value={this.state.value}
          onChange={this.onChange}
          onChangeStart={this.onChangeStart}
          onChangeComplete={this.onChangeComplete}
          {...rest}
        />

        {this.renderMessage()}
      </div>
    );
  }
}
