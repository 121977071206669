/* eslint-disable camelcase */
import {useMemo} from 'react';

const useSectorDefaultValues = (sector = {}) => {
  const {id, name, description, search} = sector || {};

  return useMemo(() => {
    if (!sector || !Object.keys(sector).length) {
      return {};
    }

    return {
      id,
      name,
      description,
      search,
    };
  }, [sector, id, name, description, search]);
};

export default useSectorDefaultValues;
