import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateAdmissionLegacy, destroyCandidateAdmission} from '../actionCreators';

const useCandidateAdmissionActions = (candidateId, admissionId) => {
  const dispatch = useDispatch();

  const onSaveAdmission = useCallback(
    // eslint-disable-next-line camelcase
    ({pqe_offset, ...admissions}) =>
      dispatch(
        updateCandidateAdmissionLegacy({
          id: candidateId,
          admissions,
          pqe_offset,
        })
      ),
    [candidateId, dispatch]
  );

  const onDeleteAdmission = useCallback(
    id => dispatch(destroyCandidateAdmission(candidateId, admissionId)),
    [admissionId, candidateId, dispatch]
  );

  return {
    onSaveAdmission,
    onDeleteAdmission,
  };
};

export default useCandidateAdmissionActions;
