/* eslint-disable camelcase */
import {useMemo} from 'react';

const usePracticeAreaDefaultValues = (practiceArea = {}) => {
  const {id, name, description, search, practice_areas, workmix} = practiceArea || {};

  return useMemo(() => {
    if (!practiceArea || !Object.keys(practiceArea).length) {
      return {};
    }

    return {
      id,
      name,
      description,
      search,
      practice_areas,
      workmix,
    };
  }, [practiceArea, id, name, description, practice_areas, workmix, search]);
};

export default usePracticeAreaDefaultValues;
