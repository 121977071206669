import {ServiceForbidden, ServiceNotFound} from 'api';
import {getSessionUserId} from 'features/session';
import {getPreferencesLoading} from './selectors';
import * as PreferencesApi from './api';

export const fetchPreferences = () => async (dispatch, getState) => {
  if (getPreferencesLoading(getState())) return Promise.resolve();

  dispatch(setPreferencesLoading());

  try {
    const response = await PreferencesApi.list();

    dispatch(setPreferences(response.data));

    return response;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setPreferences([]));

      return null;
    }
    dispatch(setPreferencesError(e.message));
    return null;
  }
};

export const fetchPreferenceSecurity = () => async (dispatch, getState) => {
  try {
    const otp = await PreferencesApi.otp();

    dispatch(setPreferenceSecurity(otp));

    return otp;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setPreferenceSecurity([]));

      return null;
    }
    return null;
  }
};

export const savePreference = preference => async (dispatch, getState) => {
  dispatch(setPreferencesSaving());

  const response = await PreferencesApi.post({
    user_id: getSessionUserId(getState()),
    ...preference,
  });

  dispatch(updatePreferences([preference]));

  /**
   * when saving 2fa, needs to reload because the qr code been loaded to the UI
   * is the old one, not the one its being saved.
   */
  const pref = response.data;
  if (pref.type === 'otp2fa' && pref.value === 'instant') {
    window.location.reload(false);
  }

  return response.data;
};

export const setPreferences = preferences => ({
  type: 'SET_PREFERENCES',
  preferences,
});

export const updatePreferences = preferences => ({
  type: 'UPDATE_PREFERENCES',
  preferences,
});

export const setPreferenceSecurity = preferenceSecurity => ({
  type: 'SET_PREFERENCES_SECURITY',
  preferenceSecurity,
});

export const setPreferencesLoading = () => ({
  type: 'SET_PREFERENCES_LOADING',
});

export const setPreferencesSaving = () => ({
  type: 'SET_PREFERENCES_SAVING',
});

export const setPreferencesError = (message = 'Sorry, something went wrong') => ({
  type: 'SET_PREFERENCES_ERROR',
  message,
});
