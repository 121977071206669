import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {removeFirmUser} from 'features/organisations/actionCreators';
import {removeUser} from '../actionCreators';

const useOnRemoveFirmUser = firmId => {
  const dispatch = useDispatch();

  return useCallback(userId => dispatch(removeFirmUser(firmId, userId)).then(() => dispatch(removeUser(userId))), [firmId, dispatch]);
};

export default useOnRemoveFirmUser;
