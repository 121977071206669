import {normalizeDiacritics} from 'normalize-text';
import {createSelector} from 'reselect';

const scope = state => state.practiceAreas;

/* eslint-disable fp/no-mutating-methods */
export const sortPracticeAreasByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortPracticeAreasByDefault = sortPracticeAreasByName;
/* eslint-enable fp/no-mutating-methods */

export const getPracticeAreasLoading = state => scope(state).loading;
export const getPracticeAreasError = state => scope(state).error;
export const getPracticeAreasLoaded = state => scope(state).loaded;
export const getPracticeAreas = state => Object.values(scope(state).practiceAreas);
export const getPracticeAreasSortByName = createSelector(getPracticeAreas, sortPracticeAreasByName);
export const getPracticeAreasSortByDefault = createSelector(getPracticeAreas, sortPracticeAreasByDefault);

const filteredPracticeAreasName = (list, name) =>
  list.filter(practiceArea => normalizeDiacritics(practiceArea.name.toLowerCase()).includes(normalizeDiacritics(name.toLowerCase())));

const filteredPracticeAreas = {
  name: filteredPracticeAreasName,
};

export const getPracticeAreasFilteredBy = (state, filters, sortPracticeAreas = sortPracticeAreasByName) => {
  const users = getPracticeAreas(state);

  return sortPracticeAreas(
    Object.keys(filters).reduce((nextPracticeAreas, filter) => {
      const filterByFunction = filteredPracticeAreas[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextPracticeAreas, filters[filter] ?? '');
      }

      return nextPracticeAreas;
    }, users)
  );
};
