import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveUserPassword} from '../actionCreators';

const useOnSaveUserPassword = () => {
  const dispatch = useDispatch();

  return useCallback(
    (userId, userPassword, userPasswordConf) => dispatch(saveUserPassword(userId, userPassword, userPasswordConf)),
    [dispatch]
  );
};

export default useOnSaveUserPassword;
