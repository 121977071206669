import {useRef, useEffect, useCallback, useState, isValidElement} from 'react';
import dayjs from 'dayjs';

export const useRefCallback = init => {
  const ref = useRef(init);
  const setRef = useCallback(
    nextRef => {
      ref.current = nextRef;
    },
    [ref]
  );
  return [ref, setRef];
};

export const snakeToTitle = str =>
  str.replace(/[-_]+/g, ' ').replace(/\w\S*/g, group => group.charAt(0).toUpperCase() + group.substr(1).toLowerCase());

export const snakeToCamel = str => str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));

export const pluralise = (count, val) => `${count} ${+count === 1 ? val : `${val}s`}`;

export const ucwords = str => str.replace(/^(.)|\s+(.)/g, w => w.toUpperCase());

export const arrayLabelise = arr => arr.map(val => ucwords(val.replace(/_/g, ' ')));

export const labelFormatter = label =>
  label
    .split(/[-_]/)
    .map(word => word[0].toUpperCase() + word.split('').slice(1).join(''))
    .join(' ');

export const arrayCommaAnd = arr => {
  const arrRest = [...arr];
  // eslint-disable-next-line fp/no-mutating-methods
  const arrLast = arrRest.pop();

  return arrRest.length > 0 ? `${arrRest.join(', ')} & ${arrLast}` : arrLast;
};

export const yearsMonthsFromDates = (timestampStart, timestampEnd) => {
  const dateStart = dayjs(timestampStart);
  const dateEnd = dayjs(timestampEnd);

  if (dateStart.isValid()) {
    const endD = !dateEnd.isValid() ? dayjs() : dateEnd;
    return yearsMonths(endD.diff(dateStart, 'months'));
  }

  return '';
};

export const yearsMonths = monthCount => {
  const getPlural = (number, word) => (number === 1 && word.one) || word.other;

  const months = {one: 'month', other: 'months'};
  const years = {one: 'year', other: 'years'};
  const m = monthCount % 12;
  const y = Math.floor(monthCount / 12);
  const result = [];

  /* eslint-disable fp/no-mutating-methods */
  y && result.push(`${y} ${getPlural(y, years)}`);
  m && result.push(`${m} ${getPlural(m, months)}`);
  /* eslint-enable fp/no-mutating-methods */

  return result.join(' ');
};

export const stripHtml = (html = '') => html.replace(/<(?:.|\n)*?>/gm, '').trim();

export const useTimeout = (callback, ms) => {
  const timeoutRef = useRef();
  const cancel = useCallback(() => {
    const timeout = timeoutRef.current;
    if (timeout) {
      timeoutRef.current = undefined;
      clearTimeout(timeout);
    }
  }, [timeoutRef]);

  useEffect(() => {
    timeoutRef.current = setTimeout(callback, ms);
    return cancel;
  }, [callback, ms, cancel]);

  return cancel;
};

export const hasFormChanged = (formState, defaultValues) => {
  if (!formState || !formState.touched) return false;

  const hasTouched = Object.keys(formState.touched).length > 0;

  if (!hasTouched) return false;

  return !!Object.keys(formState.values).find(field => {
    if (!(field in defaultValues)) {
      return formState.values[field];
    }

    return JSON.stringify(formState.values[field]) !== JSON.stringify(defaultValues[field]);
  });
};

export const useDelay = ms => {
  const [resolved, setResolved] = useState(false);
  const complete = useCallback(() => setResolved(true), [setResolved]);

  useTimeout(complete, ms);
  return resolved;
};

export const a11yOnClickKeyboardEventProps = handlerFn => ({
  role: 'button',
  onClick: handlerFn,
  onKeyDown: event => {
    // insert your preferred method for detecting the keypress
    if (event.keycode === 13) handlerFn(event);
  },
});

export const useSwitchStateCallbacks = (defaultState = false) => {
  const [state, setState] = useState(defaultState);

  const onToggle = useCallback(() => setState(!state), [state]);
  const onTrue = useCallback(() => setState(true), []);
  const onFalse = useCallback(() => setState(false), []);

  return [state, onToggle, onTrue, onFalse, setState];
};

export const processRenderProp = (render, fallback) => {
  if (isValidElement(render)) {
    return render;
  }

  return typeof render === 'function' ? render() : fallback;
};
