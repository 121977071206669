import React from 'react';
import Message from './Message';

export default class Wrapper extends React.Component {
  state = {showTooltip: false, hasFocus: false, isTyping: false};

  componentDidMount() {
    const {api} = this.props;
    if (api) api(this);
  }

  componentWillUnmount() {
    const {api} = this.props;
    if (api) api(undefined);
  }

  getLabelClass() {
    return this.props.classLabel ? `form__label ${this.props.classLabel}` : 'form__label';
  }

  getElementClass(type = 'input') {
    return this.props.classElement ? `form__${type} ${this.props.classElement}` : `form__${type}`;
  }

  getWrapperClass() {
    const {className, fieldApi} = this.props;
    const {hasFocus} = this.state;
    const {touched, error, warning, success} = fieldApi;

    let base_class = className ? `${className}` : 'form__row';

    if (hasFocus) {
      base_class = `${base_class} form__row--has-focus`;
    }

    if (touched) {
      if (error) {
        return `${base_class} validation__has-error`;
      }
      if (warning) {
        return `${base_class} validation__has-warning`;
      }
      if (success) {
        return `${base_class} validation__has-success`;
      }
    }
    return base_class;
  }

  getValue() {
    return this.props.fieldApi.value || '';
  }

  setValue(value) {
    const {fieldApi} = this.props;

    fieldApi.setValue(value);
  }

  stoppedTyping = () => {
    this.setState({isTyping: false});
  };

  showTooltip() {
    this.setState({showTooltip: true});
  }

  hideTooltip() {
    this.setState({showTooltip: false});
  }

  onKeyDown = e => {
    const {onKeyDown} = this.props;

    if (this.isTypingTimeout) {
      clearTimeout(this.isTypingTimeout);
    } else {
      this.setState({isTyping: true});
    }

    this.isTypingTimeout = setTimeout(this.stoppedTyping, 400);

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  onChange = e => {
    const {onChange, fieldApi} = this.props;

    const {target} = e;
    const value = target.type === 'checkbox' ? (target.checked ? target.value : false) : target.value;

    fieldApi.setValue(value);

    if (onChange) {
      onChange(e);
    }
  };

  onFocus = e => {
    const {onFocus} = this.props;

    this.showTooltip();
    this.setState({hasFocus: true});

    if (onFocus) {
      onFocus(e);
    }
  };

  onBlur = e => {
    const {onBlur, fieldApi} = this.props;

    fieldApi.setTouched();
    this.hideTooltip();
    this.setState({hasFocus: false});

    if (onBlur) {
      onBlur(e);
    }
  };

  renderLabel({...labelProps} = {}) {
    const {id, label, required} = this.props;

    if (!label) {
      return null;
    }

    return (
      <label htmlFor={id} className={this.getLabelClass()} {...labelProps}>
        {label}&nbsp;
        {required && <span className="highlight">*</span>}
      </label>
    );
  }

  renderMessage({...messageProps} = {}) {
    const {fieldApi} = this.props;
    const {showTooltip} = this.state;
    const {touched, error, warning, success} = fieldApi;

    if (touched && error) {
      return <Message type="error" show={showTooltip} message={error} {...messageProps} />;
    }
    if (touched && !error && warning) {
      return <Message type="warning" show={showTooltip} message={warning} {...messageProps} />;
    }
    if (touched && !error && !warning && success) {
      return <Message type="success" show={showTooltip} message={success} {...messageProps} />;
    }
    return null;
  }
}
