import {createSelector} from 'reselect';

const scope = state => state.termsAndConditions;

/* eslint-disable fp/no-mutating-methods */
export const sortTermsAndConditionsByCountryName = list => list.slice(0).sort((a, b) => a.country_name.localeCompare(b.country_name));
export const sortTermsAndConditionsByDefault = sortTermsAndConditionsByCountryName;
/* eslint-enable fp/no-mutating-methods */

export const getTermsAndConditionsLoading = state => scope(state).loading;
export const getTermsAndConditionsError = state => scope(state).error;
export const getTermsAndConditionsLoaded = state => scope(state).loaded;
export const getTermsAndConditions = state => Object.values(scope(state).termsAndConditions);
export const getTermsAndConditionsSortByCountryName = createSelector(getTermsAndConditions, sortTermsAndConditionsByCountryName);
export const getTermsAndConditionsSortByDefault = createSelector(getTermsAndConditions, sortTermsAndConditionsByDefault);

const filterTermsAndConditionsByCountryName = (list, countryName) =>
  list.filter(termsAndConditions => termsAndConditions.country_name.toLowerCase().includes(countryName.toLowerCase()));

const filterTermsAndConditionsBy = {
  countryName: filterTermsAndConditionsByCountryName,
};

export const getTermsAndConditionsFilteredBy = (state, filters, sortTermsAndConditions = sortTermsAndConditionsByCountryName) => {
  const users = getTermsAndConditions(state);

  return sortTermsAndConditions(
    Object.keys(filters).reduce((nextTermsAndConditions, filter) => {
      const filterByFunction = filterTermsAndConditionsBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextTermsAndConditions, filters[filter] ?? '');
      }

      return nextTermsAndConditions;
    }, users)
  );
};
