/* eslint-disable camelcase */
import {combineReducers} from 'redux';

const candidatesById = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CANDIDATES':
      return action.candidates.reduce((nextCandidates, candidate) => {
        // eslint-disable-next-line no-param-reassign, fp/no-mutation
        nextCandidates[candidate.id] = {
          ...state[candidate.id],
          ...candidate,
        };
        return nextCandidates;
      }, state);

    case 'SET_APPROACH_CANDIDATES':
      return action.candidateRoles.reduce(
        (nextCandidates, {id}) => {
          // eslint-disable-next-line no-param-reassign, fp/no-mutation
          nextCandidates[id] = {id, ...state[id]};
          return nextCandidates;
        },
        {...state}
      );

    case 'SET_LONGLIST_CANDIDATES':
    case 'SET_UNSUITED_CANDIDATES':
      return action.ids.reduce(
        (nextCandidates, id) => {
          // eslint-disable-next-line no-param-reassign, fp/no-mutation
          nextCandidates[id] = {id, ...state[id]};
          return nextCandidates;
        },
        {...state}
      );

    case 'UPDATE_CANDIDATES':
    case 'UPDATE_CANDIDATES_LIST':
      return {
        ...state,
        ...action.candidates.reduce((nextState, candidate) => {
          // eslint-disable-next-line no-param-reassign, fp/no-mutation
          nextState[candidate.id] = {
            ...state[candidate.id],
            ...candidate,
          };
          return nextState;
        }, {}),
      };

    case 'REMOVE_CANDIDATES':
      return action.ids.reduce(
        (nextCandidates, id) => {
          if (id in nextCandidates) {
            // eslint-disable-next-line fp/no-delete, no-param-reassign
            delete nextCandidates[id];
          }
          return nextCandidates;
        },
        {...state}
      );

    case 'REMOVE_CANDIDATE_ADMISSION':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          admissions: state[action.id].admissions?.filter(admission => admission.id !== action.admissionId),
        },
      };

    case 'REMOVE_CANDIDATE_POSITION':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          positions: state[action.id].positions?.filter(position => position.id !== action.positionId),
        },
      };

    default:
      return state;
  }
};

const searchCandidateHash = (state = null, action) => {
  switch (action.type) {
    case 'SET_CANDIDATE_SEARCH_HASH':
      return action.hash;
    case 'RESET_CANDIDATE_RESULTS':
    case 'CLEAR_CANDIDATE_SEARCH_RESULTS':
      return null;

    default:
      return state;
  }
};

const searchCandidateIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_CANDIDATES':
      return action.candidates.reduce((nextState, candidate) => {
        // eslint-disable-next-line fp/no-mutating-methods
        nextState.push(candidate.id);
        return nextState;
      }, []);

    case 'UPDATE_CANDIDATES_LIST':
      return action.candidates.reduce((nextState, candidate) => {
        const idx = nextState.indexOf(candidate.id);

        if (idx > -1 && candidate.list && candidate.list !== 'research') {
          return [...nextState.slice(0, idx), ...nextState.slice(idx + 1)];
        }
        if (idx < 0 && (!candidate.list || candidate.list === 'research')) {
          return [...state, candidate.id];
        }
        return nextState;
      }, state);

    case 'REMOVE_CANDIDATES':
      return action.ids.reduce((nextState, id) => {
        const idx = nextState.indexOf(id);
        if (idx > -1) {
          return [...nextState.slice(0, idx), ...nextState.slice(idx + 1)];
        }
        return nextState;
      }, state);

    case 'RESET_CANDIDATE_RESULTS':
    case 'CLEAR_CANDIDATE_SEARCH_RESULTS':
      return [];

    default:
      return state;
  }
};

const longlistCandidateIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_LONGLIST_CANDIDATES':
      return action.ids;

    case 'UPDATE_CANDIDATES_LIST':
      return action.candidates.reduce((nextState, candidate) => {
        if (candidate.list !== 'longlist' && candidate.list !== 'shortlist' && candidate.list !== 'interested') {
          const idx = nextState.findIndex(id => id === candidate.id);

          if (idx >= 0) {
            return [...nextState.slice(0, idx), ...nextState.slice(idx + 1)];
          }
        }

        return nextState;
      }, state);

    case 'RESET_CANDIDATE_RESULTS':
    case 'CLEAR_CANDIDATE_LONGLIST_RESULTS':
      return [];

    default:
      return state;
  }
};

const unsuitedCandidateIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_UNSUITED_CANDIDATES':
      return action.ids;

    case 'UPDATE_CANDIDATES_LIST':
      return action.candidates.reduce((nextState, candidate) => {
        if (candidate.list !== 'unsuited') {
          const idx = nextState.findIndex(id => id === candidate.id);

          if (idx >= 0) {
            return [...nextState.slice(0, idx), ...nextState.slice(idx + 1)];
          }
        }

        return nextState;
      }, state);

    case 'RESET_CANDIDATE_RESULTS':
    case 'CLEAR_CANDIDATE_UNSUITED_RESULTS':
      return [];

    default:
      return state;
  }
};

const approachCandidateRoles = (state = [], action) => {
  switch (action.type) {
    case 'SET_APPROACH_CANDIDATES':
      return action.candidateRoles;

    case 'UPDATE_CANDIDATES_LIST':
      return action.candidates.reduce((nextState, candidate) => {
        const idx = nextState.findIndex(({id}) => id === candidate.id);
        if (idx < 0) {
          return nextState;
        }

        if (candidate.list !== 'longlist' && candidate.list !== 'shortlist' && candidate.list !== 'interested') {
          return [...nextState.slice(0, idx), ...nextState.slice(idx + 1)];
        }

        return [
          ...nextState.slice(0, idx),
          {
            ...nextState[idx],
            list: candidate.list,
          },
          ...nextState.slice(idx + 1),
        ];
      }, state);

    case 'RESET_CANDIDATE_RESULTS':
    case 'CLEAR_CANDIDATE_APPROACH_RESULTS':
      return [];

    default:
      return state;
  }
};

const candidatesLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_CANDIDATES_LOADING':
      return action.cancel;
    case 'SET_CANDIDATES':
    case 'SET_CANDIDATES_ERROR':
      return false;
    default:
      return state;
  }
};

const longlistLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_LONGLIST_LOADING':
      return action.cancel;
    case 'SET_LONGLIST_CANDIDATES':
    case 'SET_LONGLIST_ERROR':
      return false;
    default:
      return state;
  }
};

const unsuitedLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_UNSUITED_LOADING':
      return action.cancel;
    case 'SET_UNSUITED_CANDIDATES':
    case 'SET_UNSUITED_ERROR':
      return false;
    default:
      return state;
  }
};

const approachLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_APPROACH_LOADING':
      return action.cancel;
    case 'SET_APPROACH_CANDIDATES':
    case 'SET_APPROACH_ERROR':
      return false;
    default:
      return state;
  }
};

const candidatesError = (state = false, action) => {
  switch (action.type) {
    case 'SET_CANDIDATES_ERROR':
      return action.message;
    case 'SET_CANDIDATES_LOADING':
    case 'SET_CANDIDATES':
      return false;
    default:
      return state;
  }
};
const unsuitedError = (state = false, action) => {
  switch (action.type) {
    case 'SET_UNSUITED_ERROR':
      return action.message;
    case 'SET_UNSUITED_LOADING':
    case 'SET_UNSUITED_CANDIDATES':
      return false;
    default:
      return state;
  }
};
const longlistError = (state = false, action) => {
  switch (action.type) {
    case 'SET_LONGLIST_ERROR':
      return action.message;
    case 'SET_LONGLIST_LOADING':
    case 'SET_LONGLIST_CANDIDATES':
      return false;
    default:
      return state;
  }
};
const approachError = (state = false, action) => {
  switch (action.type) {
    case 'SET_APPROACH_ERROR':
      return action.message;
    case 'SET_APPROACH_LOADING':
    case 'SET_APPROACH_CANDIDATES':
      return false;
    default:
      return state;
  }
};

const initialState = {
  selectedItems: [],
};

const searchSelectFilter = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_SELECTED_ITEM':
      return {
        ...state,
        selectedItems: [
          ...state.selectedItems,
          {
            id: action.payload.id,
            type: action.payload.type,
          },
        ],
      };
    case 'REMOVE_SELECTED_ITEM':
      return {
        ...state,
        selectedItems: state.selectedItems.filter(item => item.id !== action.payload.id),
      };
    case 'CLEAR_SELECTED_ITEMS':
      return {
        ...state,
        selectedItems: state.selectedItems.filter(item => item.type !== action.payload),
      };
    case 'CLEAR_ALL_SELECTED_ITEMS':
      return {...state, selectedItems: []};
    default:
      return state;
  }
};

export default combineReducers({
  candidatesById,
  candidatesError,
  candidatesLoading,
  searchCandidateIds,
  unsuitedLoading,
  unsuitedCandidateIds,
  unsuitedError,
  longlistLoading,
  longlistCandidateIds,
  longlistError,
  approachLoading,
  approachCandidateRoles,
  approachError,
  searchCandidateHash,
  searchSelectFilter,
});
