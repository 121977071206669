const filters = {
  candidates: import(/* webpackChunkName: "FiltersCandidate" */ './candidates.json'),
  review: import(/* webpackChunkName: "FiltersReview" */ './review.json'),
  recruit: import(/* webpackChunkName: "FiltersRecruit" */ './recruit.json'),
};

export const orders = {
  candidates: import('./sorting-candidates.json'),
  review: import('./sorting-review.json'),
  recruit: import('./sorting-recruit.json'),
};

export default filters;
