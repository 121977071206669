import Service, {parseParams} from 'api';

export const groupNames = {
  researcher: 'Researcher',
  admin: 'Admin',
  staff: 'Staff',
  owner: 'Subscriber Admin',
  operator: 'Subscriber User',
  portal: 'Portal Viewer',
  viewer: 'Subscriber Viewer',
  developer: 'Developer',
  recruiteruser: 'Recruiter User',
  recruiterviewer: 'Recruiter Viewer',
};

export const getRoleName = (role = '') => groupNames[String(role).toLowerCase()] ?? 'User';

export const getPrimaryRole = (roles = []) => {
  if (!Array.isArray(roles)) return 'Unknown User';

  if (roles.indexOf('Admin') >= 0) return groupNames.admin;
  if (roles.indexOf('Staff') >= 0) return groupNames.staff;
  if (roles.indexOf('Researcher') >= 0) return groupNames.researcher;
  if (roles.indexOf('Owner') >= 0) return groupNames.owner;
  if (roles.indexOf('Operator') >= 0) return groupNames.operator;
  if (roles.indexOf('Portal') >= 0) return groupNames.portal;
  if (roles.indexOf('Viewer') >= 0) return groupNames.viewer;
  if (roles.indexOf('Developer') >= 0) return groupNames.developer;
  if (roles.indexOf('RecruiterUser') >= 0) return groupNames.recruiteruser;
  if (roles.indexOf('RecruiterViewer') >= 0) return groupNames.recruiterviewer;

  return 'User';
};

export const getRecruiters = () => Service.get(`recruiters`);

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    sort: 'name',
    addfields: 'roles',
    limit: 0,
    ...params,
  });

  return Service.get(`users?${parsedParams}`, config);
};

export const getDetail = (params = {}, config = {}) => {
  const parsedParams = parseParams({
    addfields: 'roles',
    ...params,
  });

  return Service.get(`users?${parsedParams}`, config);
};

export const post = (data = {}, config = {}) => Service.post('users?sync[authorities]=true&sync[roles]=true', data, config);
export const put = (id, data = {}, config = {}) => Service.put(`users/${id}?sync[authorities]=true&sync[roles]=true`, data, config);
export const disable = (id, config = {}) => Service.delete(`users/${id}`, config);
export const resendActivation = (id, data = {}, config) => Service.post(`auth/verify/resend/${id}`, data, config);

// class UsersModel extends FetchApi {
//   // eslint-disable-next-line camelcase
//   api_url = `${process.env.REACT_APP_LEGACY_ENDPOINT}/api/v1/accounts/users`;

//   // BLEUGH... we should load groups via the database.
//   static groups = {
//     staff: '5',
//     clients: '3',
//     jlradmin: '7',
//     review: '8',
//     subscriber: '9',
//     subscriberadmin: '10',
//     trial: '11',
//     user: '6',
//   };

//   getGroupSearchSelect = groups =>
//     groups.map(group => ({id: UsersModel.groups[group], search: groupNames[group].toLowerCase(), text: groupNames[group]}));

//   /**
//    * Parses group IDs from internal slugs.
//    * Ultimately this should be fetched from the server.
//    *
//    * @author Sam Sehnert <sam@customd.com>
//    *
//    * @param  {Array}  groups     Array with string values representing groups.
//    * @return {Array} An array of group IDs.
//    */
//   getGroupId = group => UsersModel.groups[group];

//   getGroupName = group => groupNames[group];

//   getGroupIds = groups => groups.map(group => UsersModel.groups[group]);

//   getGroupNames = groups => groups.map(group => groupNames[group]);

//   post = async data => {
//     const response = await fetchPost(this.api_url, data);

//     if (
//       response &&
//       typeof response === 'object' &&
//       response.result === 'validation_error' &&
//       typeof response.errors === 'object' &&
//       response.errors.username
//     ) {
//       return {
//         ...response,
//         errors: {
//           ...response.errors,
//           email: response.errors.username.replace(/Username/, 'Email'),
//         },
//       };
//     }

//     return response;
//   };

//   getOrganisationUser = userId =>
//     fetchGet(`${process.env.REACT_APP_LEGACY_ENDPOINT}/api/v1/firms/contact?${fetchParams({with: 'roles', user_id: userId})}`);

//   // eslint-disable-next-line camelcase
//   checkUsername = (username, user_id) =>
//     fetchGet(`${process.env.REACT_APP_LEGACY_ENDPOINT}/api/v1/accounts/username?${fetchParams({username, user_id})}`);

// const Users = new UsersModel();

// export default Users;
