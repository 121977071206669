import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Intent} from '@blueprintjs/core';

import {MainToaster} from 'components/Shared';

import {doAcceptTerms} from '../actionCreators';

const useAcceptTermsAction = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const onAcceptTerms = useCallback(async () => {
    setSaving(true);
    setError(false);
    try {
      await dispatch(doAcceptTerms());
    } catch (e) {
      setError(e.message);
      MainToaster.show({message: 'An error occurred while accepting terms. Please try again.', intent: Intent.DANGER});
    }
    setSaving(false);
  }, [dispatch]);

  return [onAcceptTerms, saving, error];
};

export default useAcceptTermsAction;
