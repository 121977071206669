import {useState, useEffect} from 'react';

import Service from 'api';

const useFilterGetOptions = options => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // get available firms based on search hash!!!
    const fetchList = async () => {
      if (typeof options === 'object') {
        setLoading(false);
        setError(false);
        setItems(Object.entries(options).map(([id, name]) => ({id, name})));
        return;
      }

      setLoading(true);
      setError(false);

      try {
        const result = await Service.get(options);

        setItems(result.data);

        // todo, setItems into redux for future requests. We may need to render selected items which have no matches based on other filters.
      } catch (e) {
        setError(e.message);
      }

      setLoading(false);
    };
    fetchList();
    return () => null;
  }, [options]); // Only re-run the effect if count changes

  return [items, loading, error];
};

export default useFilterGetOptions;
