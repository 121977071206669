import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getSessionUserId} from 'features/session';

import {savePreference} from '../actionCreators';

const usePreferenceActions = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getSessionUserId);

  const onPreferencesChange = useCallback(
    (type, value) =>
      dispatch(
        savePreference({
          user_id: userId,
          type,
          value,
        })
      ),

    [dispatch, userId]
  );

  return {onPreferencesChange};
};

export default usePreferenceActions;
