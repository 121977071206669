import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getWorkmixFilteredByAreaOfExpertiseId,
  getWorkmixSortByDefault,
  getWorkmixLoading,
  getWorkmixError,
  getWorkmixLoaded,
} from '../selectors';
import {fetchWorkmix} from '../actionCreators';

const filterWorkmixByAreaOfExpertiseId = areaOfExpertiseId => state => getWorkmixFilteredByAreaOfExpertiseId(state, areaOfExpertiseId);

const useWorkmix = (areaOfExpertiseId, forceReload = false) => {
  const workmix = useSelector(areaOfExpertiseId ? filterWorkmixByAreaOfExpertiseId(areaOfExpertiseId) : getWorkmixSortByDefault);
  const loaded = useSelector(getWorkmixLoaded);
  const loading = useSelector(getWorkmixLoading);
  const error = useSelector(getWorkmixError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchWorkmix());
    return () => null;
  }, [dispatch, loaded, forceReload]);

  return [workmix, loading, error];
};

export default useWorkmix;
