import React from 'react';
import PropTypes from 'prop-types';
import PropTypesReactRouter from 'react-router-prop-types';
import {Route, Redirect} from 'react-router-dom';

import {Loading, LoadingLayout} from 'components/Loading';
import {Header} from 'components/Header';
import {Analytics} from 'components/Analytics';

import {useSessionPermissions, useSession} from 'features/session';

const StaffLayout = ({component: Component, location, ...rest}) => {
  const [loading, isLoggedIn, userdata, shouldLoad] = useSession();

  const {hasStaffRole} = useSessionPermissions();

  if (loading && shouldLoad) return <Loading />;
  if (loading) return <LoadingLayout />;

  if (isLoggedIn && !hasStaffRole) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <React.Fragment>
            <Header />
            <Analytics debug id={process.env.REACT_APP_GOOGLE_ANALYTICS_ID} userId={userdata.id} trackPathnameOnly />
            <Component {...rest} />
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};

StaffLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  location: PropTypesReactRouter.location.isRequired,
};

export default StaffLayout;
