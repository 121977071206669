import useCandidateUpdate from './useCandidateUpdate';

const useCandidatePrivateComments = candidateId => {
  const [candidate, loading, error] = useCandidateUpdate(candidateId, 'private_comments');

  // eslint-disable-next-line camelcase
  const notes = candidate?.private_comments ?? [];

  return [notes, loading, error];
};

export default useCandidatePrivateComments;
