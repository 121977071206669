/* eslint-disable camelcase */
import {useEffect} from 'react';

import {useIntercom} from 'react-use-intercom';

import {useSessionPermissions, useSessionUserdata, useSessionUserId} from 'features/session';
import {getPrimaryRole} from 'features/users/api';

const Intercom = () => {
  const {hasSubscriberRole, hasSubscriberAdminRole, hasSubscriberViewerRole, hasReviewRole, hasPortalRole, hasRecruiterRole, hasStaffRole} =
    useSessionPermissions();
  const [userId] = useSessionUserId();
  const {email, fullname, roles, firm_ids: [firm_id] = [], firm_names: [firm_name] = [], avatar, loading, user_hash} = useSessionUserdata();
  const {boot, update, shutdown} = useIntercom();

  useEffect(() => {
    // only subsribers / owners should get this.
    if (
      !loading &&
      (hasSubscriberRole ||
        hasSubscriberAdminRole ||
        hasSubscriberViewerRole ||
        hasReviewRole ||
        hasPortalRole ||
        hasRecruiterRole ||
        hasStaffRole)
    ) {
      const data = {
        email,
        userId,
        name: fullname,
        permissions: getPrimaryRole(roles),
        companyId: firm_id ?? '',
        companyName: firm_name ?? '',
        avatar,
      };

      boot(data);
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(
    () => {
      if (
        !loading &&
        (hasSubscriberRole ||
          hasSubscriberAdminRole ||
          hasSubscriberViewerRole ||
          hasReviewRole ||
          hasPortalRole ||
          hasRecruiterRole ||
          hasStaffRole)
      ) {
        update({
          email,
          userId,
          name: fullname,
          avatar,
          company: {
            name: firm_name ?? '',
            companyId: firm_id ?? '',
          },
          customAttributes: {
            Permissions: getPrimaryRole(roles),
          },
          userHash: user_hash,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, email, fullname, firm_id, firm_name, avatar]
  );

  return null;
};

export default Intercom;
