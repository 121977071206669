import {useMemo} from 'react';

const usePracticeAreaSearchSelect = areas =>
  useMemo(
    () =>
      areas.map(({id, name}) => ({
        id,
        search: name.toLowerCase(),
        text: name,
      })),
    [areas]
  );

export default usePracticeAreaSearchSelect;
