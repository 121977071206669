/* eslint-disable camelcase */
import {useMemo} from 'react';

const useTermsAndConditionsDefaultValues = (termsAndConditions = {}) => {
  const {id, terms, country_id} = termsAndConditions || {};

  return useMemo(() => {
    if (!termsAndConditions || !Object.keys(termsAndConditions).length) {
      return {};
    }

    return {
      id,
      terms,
      country_id,
    };
  }, [termsAndConditions, id, terms, country_id]);
};

export default useTermsAndConditionsDefaultValues;
