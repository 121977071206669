import {combineReducers} from 'redux';

const areasOfExpertise = (state = [], action) => {
  switch (action.type) {
    case 'SET_AREA_OF_EXPERTISE':
      return action.areasOfExpertise.reduce(
        (nextState, areaOfExpertise) => ({
          ...nextState,
          [areaOfExpertise.id]: areaOfExpertise,
        }),
        state
      );
    case 'REMOVE_AREA_OF_EXPERTISE':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextAreasOfExpertise} = nextState;
        return nextAreasOfExpertise;
      }, state);
    case 'SET_AREA_OF_EXPERTISE_DETACH_PRACTICE_AREA': {
      const {[action.id]: nextAreaOfExpertise, ...nextState} = state;
      if (Array.isArray(nextAreaOfExpertise.practice_area_ids)) {
        // eslint-disable-next-line fp/no-mutation
        nextAreaOfExpertise.practice_area_ids = nextAreaOfExpertise.practice_area_ids.filter(
          practiceAreaId => practiceAreaId !== action.practiceAreaId
        );
      }
      if (Array.isArray(nextAreaOfExpertise.practice_areas)) {
        // eslint-disable-next-line fp/no-mutation
        nextAreaOfExpertise.practice_areas = nextAreaOfExpertise.practice_areas.filter(
          checkAreaOfExpertise => checkAreaOfExpertise.practice_area_id !== action.practiceAreaId
        );
      }
      return {[action.id]: nextAreaOfExpertise, ...nextState};
    }
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_AREA_OF_EXPERTISE_LOADING':
      return true;
    case 'SET_AREA_OF_EXPERTISE':
    case 'SET_AREA_OF_EXPERTISE_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_AREA_OF_EXPERTISE':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_AREA_OF_EXPERTISE_ERROR':
      return action.message;
    case 'SET_AREA_OF_EXPERTISE_LOADING':
    case 'SET_AREA_OF_EXPERTISE':
      return false;
    default:
      return state;
  }
};

export default combineReducers({areasOfExpertise, loading, loaded, error});
