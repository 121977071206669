import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getSessionFirmIds} from 'features/session';
import {sortUsersByRoleThenFullname, getUsersFilteredBy, getUsersLoading, getUsersError} from '../selectors';
import {fetchOrganisationUsers} from '../actionCreators';

const filterUsersBy = (fullname, firmId, role) => state =>
  getUsersFilteredBy(
    state,
    {
      role,
      firmId,
      fullname,
    },
    sortUsersByRoleThenFullname
  );

const defaultRoles = ['Owner', 'Operator', 'Portal', 'Viewer', 'RecruiterUser', 'RecruiterViewer'];

const useTeam = (filterKeyword = '', firmId, roles = defaultRoles) => {
  const sessionFirmIds = useSelector(getSessionFirmIds);
  const firmIds = useMemo(() => {
    if (firmId) {
      return Array.isArray(firmId) ? firmId : [firmId];
    }

    return sessionFirmIds;
  }, [firmId, sessionFirmIds]);

  const team = useSelector(filterUsersBy(filterKeyword, firmIds, roles));
  const loading = useSelector(getUsersLoading);
  const error = useSelector(getUsersError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!firmIds || firmIds.length < 1) return;
    dispatch(fetchOrganisationUsers(firmIds));
  }, [dispatch, firmIds]);

  return [team, loading, error];
};

export default useTeam;
