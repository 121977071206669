import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {rejectCandidateSuggestion, approveCandidateSuggestion} from '../actionCreators';

const useCandidateSuggestionActions = (candidateId, suggestionId) => {
  const dispatch = useDispatch();

  const onAccept = useCallback(
    e => {
      e.stopPropagation();
      dispatch(approveCandidateSuggestion(candidateId, suggestionId));
    },
    [candidateId, dispatch, suggestionId]
  );

  const onDecline = useCallback(
    e => {
      e.stopPropagation();
      dispatch(rejectCandidateSuggestion(candidateId, suggestionId));
    },
    [candidateId, dispatch, suggestionId]
  );

  return {
    onAccept,
    onDecline,
  };
};

export default useCandidateSuggestionActions;
