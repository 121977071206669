/* eslint-disable camelcase */

const useOrganisationDefaultValues = (firm = {}) => {
  const {
    id,
    name,
    aka,
    allowed_user_domains,
    type,
    website,
    phone,
    fax,
    email,
    notes,
    allowed_recruit_for_firms,
    allowed_create_user_permissions,
    export_to_excel_limit,
    allowed_export_to_excel,
    allowed_select_all,
    allowed_export_to_pdf,
    enabled_export_connections_to_pdf,
    enabled_talent_pool,
    select_all_limit,
    profiles_cap_enabled,
    profiles_cap_limit,
  } = firm || {};

  if (!firm || !Object.keys(firm).length) {
    return {};
  }

  return {
    id,
    name,
    aka,
    allowed_user_domains,
    type,
    website,
    phone,
    fax,
    email,
    notes,
    allowed_recruit_for_firms,
    allowed_create_user_permissions,
    export_to_excel_limit,
    allowed_export_to_excel,
    allowed_export_to_pdf,
    enabled_export_connections_to_pdf,
    enabled_talent_pool,
    allowed_select_all,
    select_all_limit,
    profiles_cap_enabled,
    profiles_cap_limit,
  };
};

export default useOrganisationDefaultValues;
