/* eslint-disable camelcase */
import dayjs from 'dayjs';

import {ValidationError} from 'components/Legacy/CdForms';
import {ServiceForbidden, ServiceAbort, ServiceNotFound} from 'api';

import Candidates from 'features/legacy/api/Candidates';

import {SearchCandidatesApi, SearchApproachApi} from 'features/search';
import * as ApproachApi from 'features/approach/api';
import * as CandidatesApi from './api';

import {
  getCandidatesLoading,
  getCandidatesCancel,
  getLonglistLoading,
  getUnsuitedLoading,
  getApproachLoading,
  getLonglistCancel,
  getUnsuitedCancel,
  getApproachCancel,
} from './selectors';

export const fetchLonglist =
  (roleId, filters = {}, sort) =>
  async (dispatch, getState) => {
    if (getLonglistLoading(getState())) {
      getLonglistCancel(getState())();
    }

    const setLonglistCancel = cancel => dispatch(setLonglistLoading(cancel));

    try {
      const result = await SearchCandidatesApi.search(
        {
          ...filters,
          longlistRoleId: {values: roleId},
        },
        sort,
        setLonglistCancel
      );

      if (result.headers['x-search-key']) {
        dispatch(setCandiateSearchHash(result.headers['x-search-key']));
      }

      const {data: response} = result;

      dispatch(setLonglistCandidates(response.data.map(candidate => candidate.id)));

      return [result.headers['x-search-key'], response.data];
    } catch (e) {
      if (e instanceof ServiceForbidden) {
        throw e;
      }
      if (e instanceof ServiceAbort) {
        // Don't do anything... it's aborted because
        // we've started a followup request.
        return null;
      }
      if (e instanceof ServiceNotFound) {
        // Handle 404 responses within this catch handler.
        dispatch(setLonglistCandidates([]));

        return null;
      }
      dispatch(setLonglistError(e.message));
    }

    return null;
  };

export const fetchUnsuited =
  (roleId, filters = {}, sort) =>
  async (dispatch, getState) => {
    if (getUnsuitedLoading(getState())) {
      getUnsuitedCancel(getState())();
    }

    const setUnsuitedCancel = cancel => dispatch(setUnsuitedLoading(cancel));

    try {
      const result = await SearchCandidatesApi.search(
        {
          ...filters,
          unsuitedRoleId: {values: roleId},
        },
        sort,
        setUnsuitedCancel
      );

      if (result.headers['x-search-key']) {
        dispatch(setCandiateSearchHash(result.headers['x-search-key']));
      }

      const {data: response} = result;

      dispatch(setUnsuitedCandidates(response.data.map(candidate => candidate.id)));

      return [result.headers['x-search-key'], response.data];
    } catch (e) {
      if (e instanceof ServiceForbidden) {
        throw e;
      }
      if (e instanceof ServiceAbort) {
        // Don't do anything... it's aborted because
        // we've started a followup request.
        return null;
      }
      if (e instanceof ServiceNotFound) {
        // Handle 404 responses within this catch handler.
        dispatch(setUnsuitedCandidates([]));

        return null;
      }
      console.error(e);

      dispatch(setUnsuitedError(e.message));
    }

    return null;
  };

export const fetchCandidatesApproach = (filters, sort) => async (dispatch, getState) => {
  if (getApproachLoading(getState())) {
    getApproachCancel(getState())();
  }

  const setApproachCancel = cancel => dispatch(setApproachLoading(cancel));

  try {
    const result = await SearchApproachApi.search(filters, sort, setApproachCancel);

    if (result.headers['x-search-key']) {
      dispatch(setCandiateSearchHash(result.headers['x-search-key']));
    }

    const {data: response} = result;

    const candidateRoles = response.data.map(candidate => ({
      id: candidate.candidate_id,
      list: candidate.type,
      role_id: candidate.role_id,
      role_brief_firm_name: candidate.role_brief_firm_name,
    }));

    dispatch(setApproachCandidates(candidateRoles));

    return [result.headers['x-search-key'], response.data];
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceAbort) {
      // Don't do anything... it's aborted because
      // we've started a followup request.
      return null;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setApproachCandidates([]));

      return null;
    }
    console.error(e);

    dispatch(setApproachError(e.message));
  }

  return null;
};

export const fetchCandidates = (filters, sort) => async (dispatch, getState) => {
  if (getCandidatesLoading(getState())) {
    getCandidatesCancel(getState())();
  }

  // Set cancel method and mark as loading
  const setCancelMethod = cancel => dispatch(setCandidatesLoading(cancel));

  try {
    const result = await SearchCandidatesApi.search(filters, sort, setCancelMethod);

    if (result.headers['x-search-key']) {
      dispatch(setCandiateSearchHash(result.headers['x-search-key']));
    }

    const {data: response} = result;

    dispatch(setCandidates(response.data));

    return [result.headers['x-search-key'], response.data, response?.total];
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceAbort) {
      // Don't do anything... it's aborted because
      // we've started a followup request.
      return null;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setCandidates([]));

      return null;
    }
    console.error(e);

    dispatch(setCandidatesError(e.message));
  }

  return null;
};

const getConnectedWorkplace = getState => {
  const {selectedItems} = getState().candidates.searchSelectFilter;
  if (!selectedItems) return [];
  const connectedWorkplace = selectedItems.filter(item => item.type === 'workplace').map(item => item.id);

  return connectedWorkplace;
};

const getConnectedEducation = getState => {
  const {selectedItems} = getState().candidates.searchSelectFilter;
  if (!selectedItems) return [];
  const connectedEducation = selectedItems.filter(item => item.type === 'education').map(item => item.id);

  return connectedEducation;
};

// Gets a specific candidates detail.
// This should be used when you want to update an individual candidate with specific related data.
export const fetchCandidateWith =
  (id, include = '') =>
  async (dispatch, getState) => {
    if (!id) {
      // dispatch(setCandidatesError('No candidate was selected'));
      throw new Error('No candidate was selected');
    }

    const connectedWorkplace = getConnectedWorkplace(getState);
    const connectedEducation = getConnectedEducation(getState);

    try {
      const response = await CandidatesApi.get(id, {
        include,
        withConnectedWorkplace: `${connectedWorkplace.join('||')}`,
        withConnectedEducation: `${connectedEducation.join('||')}`,
        referer: window.location.pathname,
      });

      dispatch(updateCandidates([response.data]));
    } catch (e) {
      if (e instanceof ServiceForbidden) {
        throw e;
      }
      if (e instanceof ServiceAbort) {
        // Don't do anything... it's aborted because
        // we've started a followup request.
        return;
      }
      if (e instanceof ServiceNotFound) {
        // Handle 404 responses within this catch handler.
        dispatch(updateCandidates([]));

        return;
      }

      console.error(e);
      dispatch(setCandidatesError());
    }
  };

export const makeCandidateSuggestion = (candidateId, suggestion) => async (dispatch, getState) => {
  if (!candidateId) {
    // dispatch(setCandidatesError('No candidate was selected'));
    return Promise.reject(new Error('No candidate was selected'));
  }

  const response = await CandidatesApi.makeSuggestion(candidateId, {
    scout_candidates_id: candidateId,
    field: 'user_suggestion',
    status: 'suggested',
    ...suggestion,
  });

  dispatch(fetchCandidateWith(candidateId, 'pending_suggestions'));

  return response;
};

export const approveCandidateSuggestion = (candidateId, suggestionId) => async (dispatch, getState) => {
  if (!candidateId) {
    // dispatch(setCandidatesError('No candidate was selected'));
    return Promise.reject(new Error('No candidate was selected'));
  }

  const response = await CandidatesApi.acceptSuggestion(candidateId, suggestionId);

  dispatch(
    fetchCandidateWith(
      candidateId,
      'firm_filters,current_address,current_address_id,positions,education,admissions,workmix,sectors,pending_suggestions'
    )
  );

  return response;
};

export const rejectCandidateSuggestion = (candidateId, suggestionId) => async (dispatch, getState) => {
  if (!candidateId) {
    // dispatch(setCandidatesError('No candidate was selected'));
    return Promise.reject(new Error('No candidate was selected'));
  }

  const response = await CandidatesApi.rejectSuggestion(candidateId, suggestionId);

  dispatch(
    fetchCandidateWith(
      candidateId,
      'firm_filters,current_address,current_address_id,positions,education,admissions,workmix,sectors,pending_suggestions'
    )
  );

  return response.data;
};

export const fetchCandidateDetail =
  (
    candidateIds = [],
    where = {},
    // eslint-disable-next-line max-len
    withRelated = 'firm_filters,current_address,current_address_id,positions,practice_areas,education,lists,admissions,workmix,sectors,attachments,approaches,subscriber_data,insights'
  ) =>
  (dispatch, getState) => {
    const candidateIdChunks = [];
    const chunkSize = 200;

    // In order to avoid abortive requests due to URLs being too long,
    // we chunk ID requests up to only fetch 500 max per request.
    // Note this trigger multiple requests, but it's unlikely that we'll
    // saturate the server if we're sensible about what we're requesting.
    // eslint-disable-next-line fp/no-mutation
    for (let i = 0, len = candidateIds.length; i < len; i += chunkSize) {
      // eslint-disable-next-line fp/no-mutating-methods
      candidateIdChunks.push(candidateIds.slice(i, i + chunkSize));
    }

    const connectedWorkplace = getConnectedWorkplace(getState);
    const connectedEducation = getConnectedEducation(getState);

    return Promise.all(
      candidateIdChunks.map(async chunkIds => {
        try {
          const response = await CandidatesApi.list({
            limit: '0',
            'filter[id]': chunkIds ? chunkIds.join('||') : null,
            include: `registrations,${withRelated}`,
            withConnectedWorkplace: `${connectedWorkplace.join('||')}`,
            withConnectedEducation: `${connectedEducation.join('||')}`,
            referer: window.location.pathname,
          });

          dispatch(updateCandidates(response.data));
        } catch (e) {
          if (e instanceof ServiceForbidden) {
            throw e;
          }
          if (e instanceof ServiceAbort) {
            // Don't do anything... it's aborted because
            // we've started a followup request.
            return;
          }
          if (e instanceof ServiceNotFound) {
            // Handle 404 responses within this catch handler.
            dispatch(updateCandidates([]));

            return;
          }

          console.error(e);
          dispatch(setCandidatesError());
        }
      })
    );
  };

export const addCandidateProfile = profile => async (dispatch, getstate) =>
  Candidates.post(profile)
    .then(response => {
      if (response.result === 'validation_error') throw new ValidationError(response.errors);
      if (response.result !== 'success') throw new Error(response.error);

      dispatch(
        updateCandidates([
          {
            id: response.id,
            ...profile,
          },
        ])
      );

      return response.id;
    })
    .catch(e => {
      if (e instanceof ValidationError) {
        dispatch(updateCandidates([]));
        throw e;
      }

      // Handle 404 responses within this catch handler.
      // API code 2013 represents a "not found" error as we don't have access to the actual response.
      if ('error_code' in e && Number(e.error_code) === 2013) {
        dispatch(updateCandidates([]));
      } else {
        console.error(e);
        dispatch(setCandidatesError());
      }
    });

export const setCandidateDeleted = id => async (dispatch, getState) => {
  const response = await CandidatesApi.destroy(id);

  dispatch(removeCandidate(id));

  return response;
};

/**
 * Update and refetch the candidate from the api on success.
 * @param {object} profile object of the candidate to update
 * @return {promise} Promise of the fetch
 */
export const updateCandidateAdmissionLegacy = profile => async (dispatch, getState) => {
  try {
    const response = await Candidates.updateAdmission(profile);

    if (response.result !== 'success') throw new Error(response.error || response.errors);

    dispatch(fetchCandidateWith(response.id, 'admissions'));

    return response;
  } catch (e) {
    if ('errors' in e) throw new Error(e.errors.join('\n'));
    if ('error' in e) throw new Error(e.error);
    throw e;
  }
};

/**
 * Update and refetch the candidate from the api on success.
 * @param {object} profile object of the candidate to update
 * @return {promise} Promise of the fetch
 */
export const updateCandidateRegistrationLegacy = profile => async (dispatch, getState) => {
  try {
    const response = await Candidates.updateRegistration(profile);

    if (response.result !== 'success') throw new Error(response.error || response.errors);

    dispatch(fetchCandidateWith(response.id, 'registrations'));

    return response;
  } catch (e) {
    if ('errors' in e) throw new Error(e.errors.join('\n'));
    if ('error' in e) throw new Error(e.error);
    throw e;
  }
};

/**
 * Update and refetch the candidate from the api on success.
 * @param {object} profile object of the candidate to update
 * @return {promise} Promise of the fetch
 */
export const updateCandidateProfileLegacy = profile => async (dispatch, getState) => {
  try {
    const response = await Candidates.post(profile);

    if (response.result !== 'success') throw new Error(response.error || response.errors);

    dispatch(fetchCandidateWith(response.id));

    return response;
  } catch (e) {
    if ('errors' in e) throw new Error(e.errors.join('\n'));
    if ('error' in e) throw new Error(e.error);
    throw e;
  }
};

/**
 * Update the candidate and save updated results from the api on success.
 * @param {object} profile object of the candidate to update
 * @return {promise} Promise of the fetch
 */
export const updateCandidateProfile = (id, data) => async (dispatch, getState) => {
  const response = await CandidatesApi.put(id, data);

  dispatch(updateCandidates([response.data]));

  return response.data;
};

export const updateCandidateInsights = (id, insights) => async (dispatch, getState) => {
  const response = await CandidatesApi.putInsights(id, insights);

  const candidateData = {
    id,
    insights: response.data,
  };

  dispatch(updateCandidates([candidateData]));

  return candidateData;
};

export const updateCandidateSubscriberData = (id, firmId, data) => async (dispatch, getState) => {
  const response = await CandidatesApi.putSubscriberData(id, firmId, data);

  const candidateData = {
    id,
    subscriber_data: response.data,
  };

  dispatch(updateCandidates([candidateData]));

  return candidateData;
};

export const updateCandidatePosition = (candidateId, data) => async (dispatch, getState) => {
  const result = await CandidatesApi.put(candidateId, {positions: [data]});

  // dispatch(updateCandidates([result.data]));
  dispatch(fetchCandidateWith(candidateId, 'positions'));

  return result.data;
};

export const fetchCandidateNotes = candidateId => (dispatch, getState) =>
  CandidatesApi.listNotes(candidateId).then(response => response.data);

export const fetchCandidateRoleNotes = (candidateId, roleId) => (dispatch, getState) =>
  CandidatesApi.listRoleNotes(candidateId, roleId).then(response => response.data);

export const createCandidateNote = (candidateId, data) => (dispatch, getState) =>
  CandidatesApi.postNotes(candidateId, data).then(response => response.data);

export const destroyCandidateNote = (candidateId, noteId) => (dispatch, getState) =>
  CandidatesApi.destroyNote(candidateId, noteId).then(result => true);

export const destroyCandidateAdmission = (candidateId, admissionId) => async (dispatch, getState) => {
  await CandidatesApi.destroyAdmission(candidateId, admissionId);
  dispatch(removeCandidateAdmission(candidateId, admissionId));
};

export const updateCandidateEducation = data => (dispatch, getState) =>
  Candidates.setEducation(data).then(result => dispatch(fetchCandidateWith(data.scout_candidates_id, 'education')));

export const deleteCandidateEducationItem = (id, candidates_id) => async (dispatch, getState) => {
  await CandidatesApi.destroyEducation(candidates_id, id);
  dispatch(fetchCandidateWith(candidates_id, 'education'));
};

export const deleteCandidatePosition = (candidateId, positionId) => async (dispatch, getState) => {
  await CandidatesApi.destroyPosition(candidateId, positionId);
  // dispatch(removeCandidatePosition(candidateId, positionId));
  dispatch(fetchCandidateWith(candidateId, 'positions'));
};

// Convenience methods for saveCandidateToList specific lists.
export const saveCandidateToLongList = (id, roleId) => saveCandidateToList(id, roleId, 'longlist');
export const saveCandidateToShortList = (id, roleId) => saveCandidateToList(id, roleId, 'shortlist');
export const saveCandidateToInterestedList = (id, roleId) => saveCandidateToList(id, roleId, 'interested');
export const saveCandidateToUnsuitedList = (id, roleId) => saveCandidateToList(id, roleId, 'unsuited');
export const saveCandidateToHoldList = (id, roleId) => saveCandidateToList(id, roleId, 'hold');
export const deleteCandidate = (id, roleId) => removeCandidateToList(id, roleId, 'delete');

/**
 * Saves a candidate record to a particular list.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {[type]}  scout_candidates_id  [description]
 * @param  {[type]}  scout_role_briefs_id [description]
 * @param  {[type]}  type                 [description]
 *
 * @return {Promise} The API request promise
 */
export const saveCandidateToList = (candidateId, roleBriefId, type) => async (dispatch, getState) => {
  try {
    const response = await CandidatesApi.putList(candidateId, roleBriefId, {type});

    dispatch(updateCandidateList(candidateId, type));
    dispatch(updateCandidates([response.data]));

    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const removeCandidateToList = (candidateId, roleBriefId, type) => async (dispatch, getState) => {
  try {
    const response = await CandidatesApi.putList(candidateId, roleBriefId, {type});

    dispatch(updateCandidateList(candidateId, type));
    dispatch(updateCandidates([response.data]));

    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setCandidateReviewed = candidateId => async (dispatch, getState) => {
  try {
    const response = await CandidatesApi.put(candidateId, {
      status: 'active',
      last_reviewed: dayjs().toISOString(),
    });

    dispatch(updateCandidates([response.data]));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createCandidateApproach = (candidateId, data) => async (dispatch, getState) => {
  try {
    await ApproachApi.post({...data, scout_candidates_id: candidateId});

    return dispatch(fetchCandidateWith(candidateId, 'approaches,lists,private_comments'));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const saveCandidateApproach = (candidateId, data) => async (dispatch, getState) => {
  try {
    await ApproachApi.put(data.id, {...data, scout_candidates_id: candidateId});

    return dispatch(fetchCandidateWith(candidateId, 'approaches,lists,private_comments'));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setLonglistCandidates = ids => ({
  type: 'SET_LONGLIST_CANDIDATES',
  ids,
});

export const setUnsuitedCandidates = ids => ({
  type: 'SET_UNSUITED_CANDIDATES',
  ids,
});

export const setApproachCandidates = candidateRoles => ({
  type: 'SET_APPROACH_CANDIDATES',
  candidateRoles,
});

export const setCandidatesLoading = cancel => ({
  type: 'SET_CANDIDATES_LOADING',
  cancel,
});

export const setLonglistLoading = cancel => ({
  type: 'SET_LONGLIST_LOADING',
  cancel,
});

export const setUnsuitedLoading = cancel => ({
  type: 'SET_UNSUITED_LOADING',
  cancel,
});

export const setApproachLoading = cancel => ({
  type: 'SET_APPROACH_LOADING',
  cancel,
});

export const setCandidatesError = (message = 'An error occured with this search. The displayed results are probably incorrect.') => ({
  type: 'SET_CANDIDATES_ERROR',
  message,
});
export const setApproachError = (message = 'An error occured with this search. The displayed results are probably incorrect.') => ({
  type: 'SET_APPROACH_ERROR',
  message,
});
export const setUnsuitedError = (message = 'An error occured with this search. The displayed results are probably incorrect.') => ({
  type: 'SET_UNSUITED_ERROR',
  message,
});
export const setLonglistError = (message = 'An error occured with this search. The displayed results are probably incorrect.') => ({
  type: 'SET_LONGLIST_ERROR',
  message,
});

export const setCandidates = candidates => ({
  type: 'SET_CANDIDATES',
  candidates,
});

export const updateCandidateList = (id, list) => ({
  type: 'UPDATE_CANDIDATES_LIST',
  candidates: [
    {
      id,
      list,
    },
  ],
});

export const updateCandidates = candidates => ({
  type: 'UPDATE_CANDIDATES',
  candidates,
});

export const clearCandidateSearchResults = () => ({
  type: 'CLEAR_CANDIDATE_SEARCH_RESULTS',
});

export const clearCandidateLonglistResults = () => ({
  type: 'CLEAR_CANDIDATE_LONGLIST_RESULTS',
});

export const clearCandidateUnsuitedResults = () => ({
  type: 'CLEAR_CANDIDATE_UNSUITED_RESULTS',
});

export const resetCandidateResults = () => ({
  type: 'RESET_CANDIDATE_RESULTS',
});

export const removeCandidate = id => removeCandidates([id]);

export const removeCandidates = ids => ({
  type: 'REMOVE_CANDIDATES',
  ids,
});

export const removeCandidateAdmission = (id, admissionId) => ({
  type: 'REMOVE_CANDIDATE_ADMISSION',
  id,
  admissionId,
});

export const removeCandidatePosition = (id, positionId) => ({
  type: 'REMOVE_CANDIDATE_POSITION',
  id,
  positionId,
});

export const setCandiateSearchHash = hash => ({
  type: 'SET_CANDIDATE_SEARCH_HASH',
  hash,
});

export const addSelectedItem = (id, type) => ({
  type: 'ADD_SELECTED_ITEM',
  payload: {id, type},
});

export const removeSelectedItem = (id, type) => ({
  type: 'REMOVE_SELECTED_ITEM',
  payload: {id, type},
});

export const clearSelectedItems = type => ({
  type: 'CLEAR_SELECTED_ITEMS',
  payload: type,
});

export const clearAllSelectedItems = () => ({
  type: 'CLEAR_ALL_SELECTED_ITEMS',
});
