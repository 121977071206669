import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {omit} from './deepMerge';
import Wrapper from './Wrapper';
import {Popover, PopoverInteractionKind} from '@blueprintjs/core';
import PopoverInterior from './PopoverInterior';
import './PopoverArchive.scss'

export default class PopoverArchive extends Wrapper {
  static propTypes = {
    open: PropTypes.bool,
    type: PropTypes.string,
    valueOfInput: PropTypes.string,
    handleReason: PropTypes.func.isRequired,
    handleChannel: PropTypes.func.isRequired,
    handleType: PropTypes.func.isRequired,
  }
  static defaultProps = {
    open: false,
    type: "reason",
    valueOfInput: "",
  }

  constructor(props) {
    super(props);

    const {open, valueOfInput} = props;

    this.state = {
      open,
      valueOfInput,
      messageOne: "",
      messageTwo: "",
      messageThree: "",
      messageFour: "",
      messageFive: "",
      buttonOne: false,
      buttonTwo: false,
      buttonThree: false,
      buttonFour: false,
      buttonFive: false,
    };
  }

  getActiveButton = (isReason, isChannel, isType) => {
    const {messageOne, messageTwo, messageThree, messageFour, messageFive, buttonOne, buttonTwo, buttonThree, buttonFour, buttonFive} = this.state
    if (isReason) {
      if (!!buttonOne) return ["Filled", {reason: "Filled"}]
      if (!!buttonTwo) return ["Cancelled", {reason: "Cancelled"}]
      if (!!buttonThree) return ["On Hold", {reason: "On Hold"}]
    }
    if (isChannel) {
      if (!!buttonOne) return ["Filled via Insource", {channel: "Insource"}]
      if (!!buttonTwo) return [`Advertising: ${messageTwo}`, {channel: "Advertising", input: messageTwo}]
      if (!!buttonThree) return [`Recruitment Agency: ${messageThree}`, {channel: "Agency", input: messageThree}]
      if (!!buttonFour) return [`Internal Referral: ${messageFour}`, {channel: "Referral", input: messageFour}]
      if (!!buttonFive) return [`Other source: ${messageFive}`, {channel: "Other", input: messageFive}]
    }
    if (isType) {
      if (!!buttonOne) return [`Alumni: ${messageOne}`, {hire_type: "Alumni", input: messageOne}]
      if (!!buttonTwo) return ["Local Hire", {reason: "Local"}]
      if (!!buttonThree) return [`National Hire: ${messageThree}`, {hire_type: "National", input: messageThree}]
      if (!!buttonFour) return [`International Hire: ${messageFour}`, {hire_type: "International", input: messageFour}]
    }
    return ["",{}]
  }

  onClose = () => {
    const {popoverType, handleReason, handleChannel, handleType} = this.props;
    const isReason = popoverType === 'reason';
    const isChannel = popoverType === 'channel';
    const isType = popoverType === 'type';
    const buttonText = this.getActiveButton(isReason, isChannel, isType)

    if (isReason) {this.setValueOfInput(buttonText[0]); handleReason(buttonText[1]);}
    if (isChannel) {this.setValueOfInput(buttonText[0]); handleChannel(buttonText[1]);}
    if (isType) {this.setValueOfInput(buttonText[0]); handleType(buttonText[1]);}
    this.setState({open: false})
  }

  onOpen = () => {this.setState({open: true})}
  setValueOfInput = value => {this.setState({valueOfInput: value})}

  setMessageOne = message => {this.setState({messageOne: message})}
  setMessageTwo = message => {this.setState({messageTwo: message})}
  setMessageThree = message => {this.setState({messageThree: message})}
  setMessageFour = message => {this.setState({messageFour: message})}
  setMessageFive = message => {this.setState({messageFive: message})}

  setButtonOne = boolean => {this.setState({buttonOne: boolean})}
  setButtonTwo = boolean => {this.setState({buttonTwo: boolean})}
  setButtonThree = boolean => {this.setState({buttonThree: boolean})}
  setButtonFour = boolean => {this.setState({buttonFour: boolean})}
  setButtonFive = boolean => {this.setState({buttonFive: boolean})}

  handleButtonOneChange = () => {this.setButtonOne(true); this.setButtonTwo(false); this.setButtonThree(false); this.setButtonFour(false); this.setButtonFive(false);};
  handleButtonTwoChange = () => {this.setButtonOne(false); this.setButtonTwo(true); this.setButtonThree(false); this.setButtonFour(false); this.setButtonFive(false);};
  handleButtonThreeChange = () => {this.setButtonOne(false); this.setButtonTwo(false); this.setButtonThree(true); this.setButtonFour(false); this.setButtonFive(false);};
  handleButtonFourChange = () => {this.setButtonOne(false); this.setButtonTwo(false); this.setButtonThree(false); this.setButtonFour(true); this.setButtonFive(false);};
  handleButtonFiveChange = () => {this.setButtonOne(false); this.setButtonTwo(false); this.setButtonThree(false); this.setButtonFour(false); this.setButtonFive(true);};
  
  handleChangeInputOne = event => this.setMessageOne(event.target.value);
  handleChangeInputTwo = event => this.setMessageTwo(event.target.value);
  handleChangeInputThree = event => this.setMessageThree(event.target.value);
  handleChangeInputFour = event => this.setMessageFour(event.target.value);
  handleChangeInputFive = event => this.setMessageFive(event.target.value);

  render() {
    const {classElement, rules, required, fieldApi, label, className, value, children, popoverType, ...rest} = this.props;
    const {open, valueOfInput, messageOne, messageTwo, messageThree, messageFour, messageFive, buttonOne, buttonTwo, buttonThree, buttonFour, buttonFive} = this.state

    const isReason = popoverType === 'reason';
    const isChannel = popoverType === 'channel';
    const isType = popoverType === 'type';

    const checkboxLabelOne = (isReason, isChannel, isType) => {if (isReason) {return "Filled"} else if (isChannel) {return "Insource"} else if (isType) {return "Alumni"}};
    const checkboxLabelTwo = (isReason, isChannel, isType) => {if (isReason) {return "Cancelled"} else if (isChannel) {return "Advertising"} else if (isType) {return "Local Hire"}};
    const checkboxLabelThree = (isReason, isChannel, isType) => {if (isReason) {return "On Hold"} else if (isChannel) {return "Recruitment Agency"} else if (isType) {return "National Hire"}};
    const checkboxLabelFour = (isChannel, isType) => {if (isChannel) {return "Internal Referral"} else if (isType) {return "International Hire"}};
    const checkboxLabelFive = "Other";

    const inputPlaceholderOne = "Alumni sourced from?";
    const inputPlaceholderTwo = "Seek, TradeMe, Firm website?";
    const inputPlaceholderThree = (isChannel, isType) => {if (isChannel) {return "Name of Agency?"} else if (isType) {return "City or region relocated from?"}};
    const inputPlaceholderFour = (isChannel, isType) => {if (isChannel) {return "Referred by?"} else if (isType) {return "Country relocated from?"}};
    const inputPlaceholderFive = "Other sources i.e., Alumni";

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        <Popover
          lazy
          usePortal
          isOpen={open}
          boundary="viewport"
          openOnTargetFocus={false}
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={5000}
          interactionKind={PopoverInteractionKind.CLICK}
          onInteraction={this.onOpen}
          onClose={this.onClose}
          position='right'
          content={<PopoverInterior 
            fieldApi={fieldApi} 
            popoverType={popoverType}
            messageOne={messageOne}
            messageTwo={messageTwo}
            messageThree={messageThree}
            messageFour={messageFour}
            messageFive={messageFive}
            buttonOne={buttonOne}
            buttonTwo={buttonTwo}
            buttonThree={buttonThree}
            buttonFour={buttonFour}
            buttonFive={buttonFive}
            handleButtonOneChange={this.handleButtonOneChange}
            handleButtonTwoChange={this.handleButtonTwoChange}
            handleButtonThreeChange={this.handleButtonThreeChange}
            handleButtonFourChange={this.handleButtonFourChange}
            handleButtonFiveChange={this.handleButtonFiveChange}
            handleChangeInputOne={this.handleChangeInputOne}
            handleChangeInputTwo={this.handleChangeInputTwo}
            handleChangeInputThree={this.handleChangeInputThree}
            handleChangeInputFour={this.handleChangeInputFour}
            handleChangeInputFive={this.handleChangeInputFive}
            isReason={isReason}
            isChannel={isChannel}
            isType={isType}
            checkboxLabelOne={checkboxLabelOne}
            checkboxLabelTwo={checkboxLabelTwo}
            checkboxLabelThree={checkboxLabelThree}
            checkboxLabelFour={checkboxLabelFour}
            checkboxLabelFive={checkboxLabelFive}
            inputPlaceholderOne={inputPlaceholderOne}
            inputPlaceholderTwo={inputPlaceholderTwo}
            inputPlaceholderThree={inputPlaceholderThree}
            inputPlaceholderFour={inputPlaceholderFour}
            inputPlaceholderFive={inputPlaceholderFive}
            ></PopoverInterior>}
          {...rest}>
          <input
            type="text"
            className="largeInput"
            placeholder="Click here"
            value={valueOfInput}
          />
        </Popover>
        {this.renderMessage()}
      </div>
    )
  };
}