/* eslint-disable camelcase */
import {useMemo} from 'react';

const useSectorDefaultValues = (sector = {}) => {
  const {id, name, iso639} = sector || {};

  return useMemo(() => {
    if (!sector || !Object.keys(sector).length) {
      return {};
    }

    return {
      id,
      name,
      iso639,
    };
  }, [sector, id, name, iso639]);
};

export default useSectorDefaultValues;
