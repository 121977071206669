import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getQualificationsFilteredBy,
  getQualificationsError,
  getQualificationsLoaded,
  getQualificationsLoading,
  sortQualificationsByDefault,
} from '../selectors';
import {fetchQualifications} from '../actionCreators';

const filterQualificationsByName = name => state => getQualificationsFilteredBy(state, {name}, sortQualificationsByDefault);

const useQualifications = (nameFilter, params, forceReload = false) => {
  const qualifications = useSelector(filterQualificationsByName(nameFilter));
  const loaded = useSelector(getQualificationsLoaded);
  const loading = useSelector(getQualificationsLoading);
  const error = useSelector(getQualificationsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchQualifications(params));
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forceReload, params]);

  return [qualifications, loading, error];
};

export default useQualifications;
