class ServiceError extends Error {
  constructor(error, message = 'An unexpected error has occurred.') {
    super(message);
    this.name = this.constructor.name;
    this.error = error;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  getError = () => this.error;
}

export default ServiceError;
