import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getLiveRolesLoading, getLiveRolesLoaded, getLiveRolesFilteredBy, getLiveRolesError, sortLiveRolesByDefault} from '../selectors';
import {fetchLiveRoles, fetchRecruiterLiveRoles} from '../actionCreators';

const filterLiveRolesBy = filters => state => getLiveRolesFilteredBy(state, filters, sortLiveRolesByDefault);

const defaultFilters = {status: ['active', 'approach']};
const defaultParams = {filter: {status: 'active||approach'}};

const useLiveRoles = (filters = defaultFilters, params = defaultParams) => {
  const liveRoles = useSelector(filterLiveRolesBy(filters));
  const loaded = useSelector(getLiveRolesLoaded);
  const loading = useSelector(getLiveRolesLoading);
  const error = useSelector(getLiveRolesError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLiveRoles(params));
    return () => null;
  }, [dispatch, params]);

  return [liveRoles, loading, error, loaded];
};

export const useLiveRecruiterRoles = (filters = defaultFilters, params = defaultParams) => {
  const [roles, setRoles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const result = await fetchRecruiterLiveRoles(params);
        setRoles(result);
      } catch (e) {
        setError(e);
      } finally {
        setLoaded(true);
      }
    };
    fetchRoles();

    return () => null;
  }, [params]);

  return [roles, error, loaded];
};

export default useLiveRoles;
