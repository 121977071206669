import React from 'react';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';
import Wrapper from './Wrapper';

import './Address.scss';

export default class FormAddress extends Wrapper {
  static propTypes = {
    placeholder: PropTypes.string,
    initialValue: PropTypes.string, // WE ignore this ---
    value: PropTypes.string, // fallback legacy - remapped to initialValue
    className: PropTypes.string, // falls back to the wrapper methods!!!
    style: PropTypes.object, // custom styles to pass onto the element
    inputClassName: PropTypes.string, // fallback - will be added to the wrapper input class generated label
    disabled: PropTypes.bool,
    location: PropTypes.object, // Type: google.maps.LatLng Default: null, o get localized suggestions, define a location to bias the suggests.
    radius: PropTypes.number, // The radius in meters defines the area around the location to use for biasing the suggests. It must be accompanied by a location parameter.
    bounds: PropTypes.object, // Type: LatLngBounds Default: null, The bounds to use for biasing the suggests. If this is set, location and radius are ignored.
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // Restricts predictions to the specified country (ISO 3166-1 Alpha-2 country code, case insensitive). E.g., us, br, au. You can provide a single one, or an array of up to 5 country code strings.
    types: PropTypes.array, // The types of predictions to be returned. Four types are supported: establishment for businesses, geocode for addresses, (regions) for administrative regions and (cities) for localities. If nothing is specified, all types are returned. Consult the Google Docs for up to date types.
    fixtures: PropTypes.array, // An array with fixtures (defaults). Each fixture has to be an object with a label key in it. Optionally provide a location, but the Geosuggest will geocode the label if no location is provided. You can also add a className key to a fixture. This class will be applied to the fixture item.
    maxFixtures: PropTypes.number, // Maximum number of fixtures to render.
    googleMaps: PropTypes.object, // In case you want to provide your own Google Maps object, pass it in as googleMaps. The default is the global google maps object.
    ignoreTab: PropTypes.bool, // When the tab key is pressed, the onSelect handler is invoked. Set to true to not invoke onSelect on tab press.
    ignoreEnter: PropTypes.bool, // When the enter key is pressed, the onSelect handler is invoked. Set to true to not invoke onSelect on enter press.
    queryDelay: PropTypes.number, // Sets the delay in milliseconds after typing before a request will be sent to find suggestions. Specify 0 if you wish to fetch suggestions after every keystroke.
    minLength: PropTypes.number, // Sets a minimum length of characters before a request will be sent to find suggestions.
    highlightMatch: PropTypes.bool, // Highlights matched text.
    onFocus: PropTypes.func, // Handled by the wrapper library as per normal.
    onBlur: PropTypes.func, // Handled by the wrapper library as per normal.
    onChange: PropTypes.func, // Handled by the wrapper library as per normal.
    onKeyDown: PropTypes.func, // Gets triggered when input field has a key pressed down. This event is triggered before onKeyPress.
    onKeyPress: PropTypes.func, // Gets triggered when input field gets key press.
    onSuggestSelect:
      PropTypes.func /** Gets triggered when a suggest got selected. Only parameter is an object with data of the selected suggest. This data is available:
											• label – Type String – The label name
											• placeId – Type String – If it is a preset, equals the label. Else it is the Google Maps placeID
											• location – Type Object – The location containing lat and lng
											• gmaps – Type Object – Optional! The complete response when there was a Google Maps geocode necessary (e.g. no location provided for presets). Check the Google Maps Reference for more information on it’s structure.
											*/,
    onUpdateSuggests: PropTypes.func, // Gets triggered when the suggest list changes. Arguments include the suggest list and the current activeSuggest. Useful if you want to render the list of suggests outside of react-geosuggest.
    onActivateSuggest:
      PropTypes.func /** Gets triggered when a suggest is activated in the list. Only parameter is an object with data of the selected suggest. This data is available:
												• label – Type String – The label name
												• placeId – Type String – If it is a preset, equals the label. Else it is the Google Maps placeID
											 */,
    onSuggestNoResults: PropTypes.func, // Gets triggered when there are no suggest results found
    getSuggestLabel: PropTypes.func, // Used to generate a custom label for a suggest. Only parameter is a suggest (google.maps.places.AutocompletePrediction). Check the Google Maps Reference for more information on it’s structure
    renderSuggestItem: PropTypes.func, // Used to customize the inner html of SuggestItem and allows for controlling what properties of the suggest object you want to render. Also a convenient way to add additional styling to different rendered elements within SuggestItem. The function is passed both the suggestion and the user input.
    skipSuggest: PropTypes.func, // If the function returns true then the suggest will not be included in the displayed results. Only parameter is an object with data of the selected suggest. (See above)
    autoActivateFirstSuggest: PropTypes.bool, // Automatically activate the first suggestion as you type. If false, the exact term(s) in the input will be used when searching and may return a result not in the list of suggestions.
    label: PropTypes.string, // We override this and use our own wrapper version
    suggestsClassName: PropTypes.string, // we will generate our own, however this will be appended
    suggestsHiddenClassName: PropTypes.string, // we will generate our own, however this will be appended
    suggestItemClassName: PropTypes.string, // we will generate our own, however this will be appended
    suggestItemActiveClassName: PropTypes.string, /// /we will generate our own, however this will be appended
    autoComplete: PropTypes.string, // Autocomplete input attribute.
  };

  static defaultProps = {
    types: ['(cities)'],
  };

  /**
   * Focus the input
   * @returns {void}
   */
  focus() {
    this._input.focus();
  }

  /**
   * Blur the input
   * @returns {void}
   */
  blur() {
    this._input.blur();
  }

  /**
   * Update the value of the user input
   * @param {String} userInput the new value of the user input
   * @returns {void}
   */
  update(userInput) {
    this._input.update(userInput);
  }

  /*
   * Clear the input and close the suggestion pane
   * @returns {void}
   */
  clear() {
    this._input.clear();
  }

  getRef = ref => {
    this._input = ref;
    if (this.props.ref) {
      this.props.getRef(ref);
    }
  };

  getinputClassName() {
    const elementClass = this.getElementClass('input');

    return this.props.inputClassName ? `${elementClass} ${this.props.inputClassName}` : `${elementClass}`;
  }

  onSuggestSelect = selected => {
    const {onChange, fieldApi, onSuggestSelect} = this.props;
    const description = selected && selected.description ? selected.description : '';

    fieldApi.setValue(description);

    if (onChange) {
      onChange(description);
    }

    if (onSuggestSelect) {
      onSuggestSelect(selected);
    }
  };

  render() {
    const {
      inputClassName,
      suggestsClassName,
      suggestsHiddenClassName,
      suggestItemClassName,
      suggestItemActiveClassName,
      classElement,
      required,
      fieldApi,
      type,
      label,
      className,
      initialValue,
      value,
      children,
      types,
      ...rest
    } = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        {/* TODO : add renderLabel() method from latest CD-Forms, meantime using .children props */}
        {this.props.children}

        <Geosuggest
          ref={this.getRef}
          initialValue={value ?? this.getValue()}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={this.getElementClass('container')}
          inputClassName={`${this.getElementClass('input')} ${inputClassName}`}
          suggestsClassName={`${this.getElementClass('suggests')} ${suggestsClassName}`}
          suggestsHiddenClassName={`${this.getElementClass('suggests-hidden')} ${suggestsHiddenClassName}`}
          suggestItemClassName={`${this.getElementClass('suggest-item')} ${suggestItemClassName}`}
          suggestItemActiveClassName={`${this.getElementClass('suggest-item-active')} ${suggestItemActiveClassName}`}
          onSuggestSelect={this.onSuggestSelect}
          placeDetailFields={[]}
          types={types}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
