/* eslint-disable camelcase */
import {createSelector} from 'reselect';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';

import {yearsMonths, snakeToTitle} from 'helpers';

dayjs.extend(dayjsDuration);

export const getCandidateLocation = (location = {}) => {
  const address = [];
  if (location.city) {
    // eslint-disable-next-line fp/no-mutating-methods
    address.push(location.city.replace(/\s/, '\u00a0'));
  }
  if (location.state && location.state !== location.city) {
    // eslint-disable-next-line fp/no-mutating-methods
    address.push(location.state.replace(/\s/, '\u00a0'));
  }
  if (location.country) {
    // eslint-disable-next-line fp/no-mutating-methods
    address.push(location.country.replace(/\s/, '\u00a0'));
  }
  return address.join(', \n');
};

// candidate selector (for use with an individual candidate, not state)
export const getCandidateFullname = ({first_name = '', middle_name = '', last_name = ''} = {}) =>
  [first_name, middle_name, last_name].join(' ').replace(/\s+/g, ' ');

export const getCandidateCurrentLocation = candidate => getCandidateLatestFirmLocation(candidate);
// candidate && candidate.current_address ? getCandidateLocation(candidate.current_address) : null;

export const getCandidateEmploymentDuration = employment => {
  const dateStart = dayjs(employment.date_start);
  const dateEnd = dayjs(employment.date_end);
  const diff = (dateEnd.isValid() ? dateEnd : dayjs()).diff(dateStart, 'months');

  if (!diff) return '';

  return yearsMonths(diff);
};

export const getCandidateLatestFirm = (candidate = {}) =>
  Array.isArray(candidate?.positions) && candidate.positions.find(position => position.is_current);

export const getCandidateLatestFirmName = (candidate = {}) => {
  const employment = getCandidateLatestFirm(candidate);
  return employment?.firm_name ?? '';
};

export const getCandidateLatestPosition = (candidate = {}) => {
  const employment = getCandidateLatestFirm(candidate);
  return employment?.position ?? '';
};

export const getCandidateLatestFirmLocation = (candidate = {}) => {
  const employment = getCandidateLatestFirm(candidate);
  return employment
    ? getCandidateLocation({
        city: employment.city_name,
        state: employment.zone_name,
        country: employment.country_name,
      })
    : '';
};

export const getCandidateLatestFirmDuration = (candidate = {}) => {
  const employment = getCandidateLatestFirm(candidate);
  return employment ? getCandidateEmploymentDuration(employment) : '';
};

export const getCandidatePreviousFirm = (candidate = {}) => {
  // eslint-disable-next-line guard-for-in
  for (const i in candidate.positions) {
    const pos = candidate.positions[i];
    if (!pos.is_current_firm) {
      return pos;
    }
  }
  return [];
};

export const getCandidateWorkplaceConnections = (candidate = {}) => candidate?.workplaceConnections ?? '';
export const getCandidateEducationConnections = (candidate = {}) => candidate?.educationConnections ?? '';

export const getCandidatePreviousFirmName = (candidate = {}) => {
  const employment = getCandidatePreviousFirm(candidate);
  return employment?.firm_name ?? '';
};

export const getCandidatePreviousFirmDuration = (candidate = {}) => {
  const employment = getCandidatePreviousFirm(candidate);
  return employment ? getCandidateEmploymentDuration(employment) : '';
};

export const getCandidatePreviousPosition = (candidate = {}) => {
  const employment = getCandidatePreviousFirm(candidate);
  return employment ? employment.position : '';
};

export const getCandidateSuggestionLabel = field => {
  switch (field) {
    case 'salutation':
      return 'Salutation';
    case 'first_name':
      return 'First Name';
    case 'middle_name':
      return 'Middle Name';
    case 'last_name':
      return 'Last Name';
    case 'preferred_name':
      return 'Preferred Name';
    case 'email':
      return 'Email';
    case 'work_phone':
      return 'Work Phone';
    case 'ddi_phone':
      return 'DDI Phone';
    case 'profile_url':
      return 'Firm Link';
    case 'linkedin_profile_url':
      return 'LinkedIn Profile';
    case 'career_entry_create':
      return 'Workplace';
    case 'career_end_date_update':
      return 'Work End Date';
    case 'career_start_date_update':
      return 'Work Start Date';
    case 'career_position':
      return 'Position';
    case 'career_addresses_id':
      return 'Current Address';
    case 'admission':
      return 'Admission Details';
    case 'practicing_certificate':
      return 'Practicing Certificate';
    case 'user_suggestion':
      return 'User Suggestion';
    default:
      return snakeToTitle(field);
  }
};

export const getCandidateSuggestionTab = field => {
  switch (field) {
    case 'career_entry_create':
    case 'career_start_date_update':
    case 'career_end_date_update':
    case 'career_position':
    case 'career_addresses_id':
      return 'history';
    default:
      return 'details';
  }
};

export const getCandidatePracticeAreasCount = candidate => candidate?.practice_areas?.length ?? 0;

export const getCandidateEducationCount = candidate => candidate?.education?.length ?? 0;

const filterCandidatesByName = (candidates, filter) =>
  candidates.filter(
    candidate =>
      getCandidateFullname(candidate).toLowerCase().includes(filter.toLowerCase()) ||
      (typeof candidate.preferred_name === 'string' && candidate.preferred_name.toLowerCase().includes(filter.toLowerCase()))
  );

export const getCandidates = createSelector(
  state => state.candidates.candidatesById,
  state => Object.values(state)
);
export const getCandidateCount = state => getCandidates(state).length;
export const getCandidateById = (state, id) => state.candidates.candidatesById[id];
export const getCandidatesLoading = state => !!state.candidates.candidatesLoading;
export const getCandidatesCancel = state => !!state.candidates.candidatesLoading && state.candidates.candidatesLoading;
export const getCandidatesError = state => state.candidates.candidatesError;
export const getLonglistLoading = state => !!state.candidates.longlistLoading;
export const getLonglistCancel = state => !!state.candidates.longlistLoading && state.candidates.longlistLoading;
export const getLonglistError = state => state.candidates.longlistError;
export const getUnsuitedLoading = state => !!state.candidates.unsuitedLoading;
export const getUnsuitedCancel = state => !!state.candidates.unsuitedLoading && state.candidates.unsuitedLoading;
export const getUnsuitedError = state => state.candidates.unsuitedError;

export const getCandidatesById = (state, ids = false) => {
  if (!ids) return state.candidates.candidatesById;

  const selectedCandidates = {};
  ids.forEach(id => {
    // eslint-disable-next-line fp/no-mutation
    selectedCandidates[id] = state.candidates.candidatesById[id];
  });

  return selectedCandidates;
};

export const getCandidatesFilteredById = (state, ids) => ids.map(id => getCandidateById(state, id));

export const getSearchCandidateIds = state => state.candidates.searchCandidateIds;
export const getSearchCandidates = state => getCandidatesFilteredById(state, getSearchCandidateIds(state));
export const getCandidatesSearchHash = state => state.candidates.searchCandidateHash;

export const getLonglistCandidateIds = (state, filter) => {
  if (typeof filter !== 'string' || filter === '') {
    return state.candidates.longlistCandidateIds;
  }

  const candidates = Object.values(getCandidatesById(state, state.candidates.longlistCandidateIds));
  const candidateIds = new Set(filterCandidatesByName(candidates, filter).map(candidate => candidate.id));

  return state.candidates.longlistCandidateIds.reduce((nextCandidateIds, id) => {
    if (candidateIds.has(id)) {
      return [...nextCandidateIds, id];
    }

    return nextCandidateIds;
  }, []);
};

export const getUnsuitedCandidateIds = (state, filter) => {
  const candidates = getCandidatesFilteredById(state, state.candidates.unsuitedCandidateIds);

  if (typeof filter !== 'string' || filter === '') {
    return candidates.map(candidate => candidate.id);
  }

  return filterCandidatesByName(candidates, filter).map(candidate => candidate.id);
};

export const getApproachLoading = state => !!state.candidates.approachLoading;
export const getApproachCancel = state => !!state.candidates.approachLoading && state.candidates.approachLoading;
export const getApproachCandidates = state => getCandidatesFilteredById(state, getApproachCandidateIds(state));
export const getApproachError = state => state.candidates.approachError;

export const getApproachCandidateIds = createSelector(
  state => state.candidates.approachCandidateRoles,
  approachCandidates => approachCandidates.map(candidate => candidate.id)
);

export const getApproachCandidateRoles = createSelector(
  state => state.candidates.approachCandidateRoles,
  approachCandidates => approachCandidates.map(candidate => candidate.role_id)
);

export const getApproachCandidateLists = createSelector(
  state => state.candidates.approachCandidateRoles,
  approachCandidates => approachCandidates.map(candidate => candidate.list)
);

export const getApproachCandidateFirms = createSelector(
  state => state.candidates.approachCandidateRoles,
  approachCandidates => approachCandidates.map(candidate => candidate.role_brief_firm_name)
);

export const getCandidateSuggestions = (state, candidateId, fields) => {
  const candidate = getCandidateById(state, candidateId);

  return (fields ? candidate?.pending_suggestions?.filter(({field}) => fields.includes(field)) : candidate?.pending_suggestions) ?? [];
};
