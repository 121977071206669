import React from 'react';

import {FullLayout} from 'components/Layout';

import ErrorBoundary from './ErrorBoundary';
import ErrorMessage from './ErrorMessage';

const NotFoundLazy = React.lazy(() => import(/* webpackChunkName: "NotFound" */ './NotFound'));

export const NotFound = props => <FullLayout component={NotFoundLazy} {...props} />;
export const ErrorLayout = props => <FullLayout component={ErrorMessage} fullScreen {...props} />;

export {ErrorBoundary, ErrorMessage};
