import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {IntercomProvider} from 'react-use-intercom';
import {FlagsProvider} from 'react-unleash-flags';
// import {MatomoProvider, createInstance} from '@datapunt/matomo-tracker-react';

import App from './components/App';
import withReduxFeatures from './withReduxFeatures';
import * as serviceWorker from './serviceWorker';

import 'styles/index.scss';

const IntercomApp = () => (
  <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
    <App />
  </IntercomProvider>
);

const UnleashedApp = () => (
  <FlagsProvider>
    <IntercomApp />
  </FlagsProvider>
);

// const instance = createInstance({
//   urlBase: process.env.REACT_APP_MATOMO_URL,
//   siteId: process.env.REACT_APP_MATOMO_SITE_ID,
// });

/** Wrap App component with store providers */
const WrappedApp = withReduxFeatures(UnleashedApp);

// ReactDOM.render(
//   <MatomoProvider value={instance}>
//     <WrappedApp />
//   </MatomoProvider>,
//   document.getElementById('root')
// );

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

/**
 * If you want your app to work offline and load faster,
 * you can change unregister() to register() below.
 * Note this comes with some pitfalls.
 * @see https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
