import validatejs from 'validate.js';

/**
 * Determines whether a field is required.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {String|Object}  message Either a string to use as the message, otherwise an object to use as the validation rule.
 *
 * @return {Object} The validator object to use
 */
export const required = (message = 'This field is required') => {
  if (message && typeof message === 'object') return message;

  return {
    error: {
      presence: {
        allowEmpty: false,
        message,
      },
    },
  };
};

export const minVal = (val, message = 'must be equal or greater than %{count}') => ({
  error: {
    numericality: {
      greaterThanOrEqualTo: val,
      notGreaterThanOrEqualTo: message,
    },
  },
});

export const maxVal = (val, message = 'must be equal or less than %{count}') => ({
  error: {
    numericality: {
      lessThanOrEqualTo: val,
      notLessThanOrEqualTo: message,
    },
  },
});

export const minlen = (len = 2, message = 'needs to have %{count} chars or more') => {
  if (message && typeof message === 'object') return message;

  return {
    error: {
      length: {
        minimum: len,
        tooShort: message,
      },
    },
  };
};

export const maxlen = (len = 10, message = 'needs to have %{count} chars or less') => {
  if (message && typeof message === 'object') return message;

  return {
    error: {
      length: {
        maximum: len,
        tooLong: message,
      },
    },
  };
};

/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
const recursiveReset = obj => {
  Object.keys(obj).forEach(key => {
    // Test if it's an Object
    if (obj[key] === Object(obj[key])) {
      recursiveReset(obj[key]);
      return;
    }
    if (obj[key] instanceof Array) obj[key] = [];
    else obj[key] = null;
  });
};
/* eslint-enable no-param-reassign */
/* eslint-enable fp/no-mutation */

export function Validate(values, formValidationRules, options) {
  const validationStatus = validatejs(values, formValidationRules, options);

  const reset = JSON.parse(JSON.stringify({...values}));
  recursiveReset({reset});
  const Final = {...reset, ...validationStatus};
  return Final;
}

export const validateAsync = (values, formValidationRules, options) => validatejs.async(values, formValidationRules, options);
