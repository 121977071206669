import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
  });

  return Service.get(`areas_of_expertise?${parsedParams}`, config);
};

export const post = (data = {}, config = {}) => Service.post('areas_of_expertise', data, config);
export const put = (id, data = {}, config = {}) => Service.put(`areas_of_expertise/${id}`, data, config);
export const destroy = (id, config) => Service.delete(`areas_of_expertise/${id}`, config);
export const attach = (id, practiceAreaId, config) => Service.post(`practice_areas/${practiceAreaId}/areas_of_expertise/${id}`, {}, config);
export const detach = (id, practiceAreaId, config) => Service.delete(`practice_areas/${practiceAreaId}/areas_of_expertise/${id}`, config);
