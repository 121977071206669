const defaultMapErrors = (field, error) => [field, error];
class ValidationError extends Error {
  constructor(errors, message = 'A validation error occurred') {
    super(message);
    this.name = this.constructor.name;
    this.errors = errors;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  setErrors = (formApi, mapErrors = null) =>
    Object.keys(this.errors).forEach((field, index) => {
      formApi.setError(...(mapErrors ?? defaultMapErrors)(field, this.errors[field]));
    });

  getErrors = () => this.errors;
}

export default ValidationError;
