import {combineReducers} from 'redux';

const savedSearch = (state = [], action) => {
  switch (action.type) {
    case 'SET_SAVED_SEARCH':
      return action.search.reduce(
        (nextState, sector) => ({
          ...nextState,
          [sector.id]: sector,
        }),
        state
      );
    case 'REMOVE_SAVED_SEARCH':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextSavedSearches} = nextState;
        return nextSavedSearches;
      }, state);

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_SAVED_SEARCH_LOADING':
      return true;
    case 'SET_SAVED_SEARCH':
    case 'SET_SAVED_SEARCH_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_SAVED_SEARCH':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_SAVED_SEARCH_ERROR':
      return action.message;
    case 'SET_SAVED_SEARCH_LOADING':
    case 'SET_SAVED_SEARCH':
      return false;
    default:
      return state;
  }
};

export default combineReducers({savedSearch, loading, loaded, error});
