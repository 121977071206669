import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getPracticeAreasFilteredBy,
  getPracticeAreasLoading,
  getPracticeAreasError,
  getPracticeAreasLoaded,
  sortPracticeAreasByDefault,
} from '../selectors';
import {fetchPracticeAreas} from '../actionCreators';

const filterPracticeAreasByName = name => state => getPracticeAreasFilteredBy(state, {name}, sortPracticeAreasByDefault);

const usePracticeAreas = (nameFilter, params, forceReload = false) => {
  const practiceAreas = useSelector(filterPracticeAreasByName(nameFilter));
  const loaded = useSelector(getPracticeAreasLoaded);
  const loading = useSelector(getPracticeAreasLoading);
  const error = useSelector(getPracticeAreasError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchPracticeAreas(params));
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, forceReload]);

  return [practiceAreas, loading, error];
};

export default usePracticeAreas;
