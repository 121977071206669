import * as AreaOfExpertiseApi from '../api';
import setAreaOfExpertiseDetachPracticeArea from './setAreaOfExpertiseDetachPracticeArea';

const detachAreaOfExpertise = (id, practiceAreaId) => async (dispatch, getState) => {
  const response = await AreaOfExpertiseApi.detach(id, practiceAreaId);

  dispatch(setAreaOfExpertiseDetachPracticeArea(id, practiceAreaId));

  return response.data;
};

export default detachAreaOfExpertise;
