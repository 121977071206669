import {useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {snakeToCamel} from 'helpers';
import {updateCandidateProfile} from '../actionCreators';

const useCandidateSaveFields = (candidateId, fields, formApi = false) => {
  const dispatch = useDispatch();

  // console.log(fields);

  return useMemo(
    () =>
      fields.reduce((nextEvents, field) => {
        // console.log(`useCandidateSaveFields.js on_save_${field}`, nextEvents);

        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        nextEvents[snakeToCamel(`on_save_${field}`)] = value => {
          // console.log(`useCandidateSaveFields.js`, field, value);
          dispatch(
            updateCandidateProfile(candidateId, {
              [field]: formApi ? formApi.getValue(field) : value,
            })
          );
        };

        return nextEvents;
      }, {}),
    [candidateId, dispatch, fields, formApi]
  );
};

export default useCandidateSaveFields;
