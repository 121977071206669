import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveTermsAndConditions, createTermsAndConditions, destroyTermsAndConditions} from '../actionCreators';

const useTermsAndConditionsActions = termsAndConditionsId => {
  const dispatch = useDispatch();

  const onSaveTermsAndConditions = useCallback(
    async values => {
      if (termsAndConditionsId) {
        await dispatch(saveTermsAndConditions(termsAndConditionsId, values));
      } else {
        await dispatch(createTermsAndConditions(values));
      }
    },
    [termsAndConditionsId, dispatch]
  );
  const onDestroyTermsAndConditions = useCallback(
    () => (termsAndConditionsId ? dispatch(destroyTermsAndConditions(termsAndConditionsId)) : null),
    [termsAndConditionsId, dispatch]
  );

  return {
    onSaveTermsAndConditions,
    onDestroyTermsAndConditions,
  };
};

export default useTermsAndConditionsActions;
