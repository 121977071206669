import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Popover, Position, Menu, MenuItem} from '@blueprintjs/core';

import {MdArrowDropDown} from 'react-icons/md';

import 'styles/components/dropdowns/HeadingDropdown.scss';

const HeadingDropdown = ({onChange, options, selected, className}) => {
  const selectedOption = useMemo(() => options.find(opt => String(opt.value) === String(selected)), [options, selected]);
  const selectionOptions = useMemo(
    () =>
      options.map(option => ({
        ...option,
        onClick: () => onChange(option.value),
      })),
    [onChange, options]
  );

  return (
    <Popover
      lazy
      usePortal
      position={Position.BOTTOM}
      content={
        <Menu>
          {selectionOptions.map(({value, label, icon, onClick}) => (
            <MenuItem key={value} text={label} icon={icon} onClick={onClick} />
          ))}
        </Menu>
      }>
      <h2 className="fancy-dropdown__heading">
        {selectedOption.heading ?? selectedOption.label} <MdArrowDropDown />
      </h2>
    </Popover>
  );
};

HeadingDropdown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HeadingDropdown.defaultProps = {
  onChange: () => {},
  options: [],
  className: 'fancy-dropdown',
};

export default HeadingDropdown;
