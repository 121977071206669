import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidatePosition, deleteCandidatePosition} from '../actionCreators';

const useCandidateWorkHistoryActions = (candidateId, position) => {
  const dispatch = useDispatch();

  const positionId = position ? position.id : undefined;

  const onSaveWorkHistory = useCallback(
    ({scoutHistoryDateRange, ...values}) =>
      dispatch(
        updateCandidatePosition(candidateId, {
          id: positionId,
          date_start: scoutHistoryDateRange?.startDate,
          date_end: scoutHistoryDateRange?.endDate,
          ...values,
          is_current: values.is_current ? 1 : 0,
        })
      ),
    [candidateId, positionId, dispatch]
  );
  const onDeleteWorkHistory = useCallback(
    () => (positionId ? dispatch(deleteCandidatePosition(candidateId, positionId)) : null),
    [candidateId, positionId, dispatch]
  );

  const onMapSaveValidationError = useCallback(
    (field, errors) => [field.replace('positions.0.', ''), errors.map(message => message.replace('positions.0.', ''))],
    []
  );

  return {
    onSaveWorkHistory,
    onDeleteWorkHistory,
    onMapSaveValidationError,
  };
};

export default useCandidateWorkHistoryActions;
