import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPost} from '@customd/cd-fetch-model';

import {ServiceForbidden} from 'api';
import * as Session from './api';
import {
  getSessionLoading,
  getSessionUserdata,
  getIsLoggedIn,
  getSessionShouldLoad,
  getIsAccountVerified,
  getSessionSubmitting,
} from './selectors';

export const fetchSession = () => async (dispatch, getState) => {
  if (!getSessionShouldLoad(getState())) {
    return Promise.resolve();
  }
  dispatch(setSessionLoading());
  try {
    const response = await Session.get();
    dispatch(setSessionUserdata(response.data));
    return response.data;
  } catch (error) {
    if (error instanceof ServiceForbidden) {
      dispatch(setSessionExpired());
      return null;
    }
    dispatch(setSessionError(error.message));
    return null;
  }
};

export const doActivate = (userId, hash, data) => async (dispatch, getState) => {
  if (getIsAccountVerified(getState())) {
    return Promise.reject(new Error('This account is already verified.'));
  }

  try {
    const response = await Session.activate(userId, hash, data);

    // dispatch(setSessionUserdata(response.data));

    return response.data;
  } catch (error) {
    if (error.message !== 'Already verified') {
      dispatch(setSessionError(error.message));
    }
    throw error;
  }
};

export const doLoginLegacy = (username, password) => doLogin(username, password, true);

export const doLogin =
  (username, password, legacyAuth = false) =>
  async (dispatch, getState) => {
    if (getIsLoggedIn(getState()) || getSessionSubmitting(getState())) {
      return Promise.resolve();
    }

    dispatch(setSessionSubmitting());

    try {
      if (legacyAuth) {
        const url = `${process.env.REACT_APP_LEGACY_ENDPOINT}/api/v1/auth/login`;
        await fetchPost(url, {username, password});
      } else {
        const responseAuth = await Session.login(username, password);
        if (responseAuth.user_2fa_challenge_required) {
          dispatch(setSessionError('Need 2FA Challenge to proceed...'));
          return responseAuth;
        }
      }
      const response = await Session.get();
      dispatch(setSessionUserdata(response.data));

      return response.data;
    } catch (error) {
      if (Array.isArray(error.errors)) {
        dispatch(setSessionError(error.errors.join('\n')));
        throw error;
      }
      dispatch(setSessionError(error.message ?? "Sorry, we couldn't log you in right now."));
      throw error;
    }
  };

export const doLogout = () => (dispatch, getState) => Session.logout().finally(res => dispatch({type: 'CLEAR_SESSION_DATA'}));

export const doForgotPassword = username => async (dispatch, getState) => {
  const response = await Session.forgot(username);
  dispatch(setUserAuthMessage(response.message ?? 'A password reset link has been sent'));
  return response.message;
};

export const doForgotPasswordInternal = async username => {
  await Session.forgot(username);
};

export const doResetPassword = values => async (dispatch, getState) => {
  const response = await Session.reset(values);
  // dispatch(setSessionUserdata(response.data));
  return response.data;
};

/**
 * Mark the user as accepting the terms and conditions.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @return {Promise}               A promise.
 */
export const doAcceptTerms = () => async (dispatch, getState) => {
  const response = await Session.acceptTerms();
  dispatch(setSessionUserdata(response.data));
  return response.data;
};

export const setUserAuthMessage = message => ({
  type: 'SET_SESSION_AUTH_MESSAGE',
  message,
});

export const setSessionLoading = () => ({
  type: 'SET_SESSION_LOADING',
});

export const setSessionSubmitting = () => ({
  type: 'SET_SESSION_SUBMITTING',
});

export const setSessionUserdata = userdata => ({
  type: 'SET_SESSION_DATA',
  userdata,
});

export const setSessionError = message => ({
  type: 'SET_SESSION_ERROR',
  message,
});

export const setSessionExpires = sessionExpiresIn => ({
  type: 'SET_SESSION_EXPIRES',
  sessionExpiresIn,
});

export const setSessionExpired = () => ({
  type: 'SET_SESSION_EXPIRED',
});

export const useLogout = () => {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(doLogout()), [dispatch]);
};

export const useSession = () => {
  const loading = useSelector(getSessionLoading);
  const shouldLoad = useSelector(getSessionShouldLoad);
  const userdata = useSelector(getSessionUserdata);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldLoad) {
      return;
    }

    dispatch(fetchSession());
  }, [dispatch, isLoggedIn, shouldLoad]);

  return [loading, isLoggedIn, userdata, shouldLoad];
};
