import React from 'react';
import {Form} from 'react-form';
import {ValidationError} from './validate';

class WrappedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {formApi: null};
  }

  getApi = formApi => {
    const {getApi} = this.props;

    this.setState({formApi});

    if (getApi) {
      getApi(formApi);
    }
  };

  onSubmit = async (...props) => {
    try {
      await this.props.onSubmit(...props);
    } catch (e) {
      if (e instanceof ValidationError) {
        e.setErrors(this.state.formApi);
      } else {
        throw e;
      }
    }
  };

  render() {
    const {children, ...rest} = this.props;

    return (
      <Form {...rest} getApi={this.getApi} onSubmit={this.onSubmit}>
        {children}
      </Form>
    );
  }
}

export default WrappedForm;
