/* eslint-disable fp/no-mutating-methods */
export const sortRolesByCreated = state => state;
export const sortRolesByName = state => state.slice(0).sort((a, b) => a.role_title.localeCompare(b.role_title));
export const sortRolesByDefault = sortRolesByCreated;
/* eslint-enable fp/no-mutating-methods */

export const getRoles = state => state.roles.ids.map(id => state.roles.byId[id]);
export const getRoleById = (state, id) => getRolesById(state)[id];
export const getRolesLoaded = state => state.roles.loaded;
export const getRolesLoading = state => state.roles.loading;
export const getRolesError = state => state.roles.error;
export const getRolesSaving = state => state.roles.saving;
export const getRolesFirmId = (state, id) => {
  const role = getRoleById(state, id);

  if (role && 'for_firm_id' in role && role.for_firm_id) {
    return role.for_firm_id;
  }

  if (role && 'scout_firms_id' in role) {
    return role.scout_firms_id;
  }

  return null;
};

export const getRolesById = (state, roleIds = false) => {
  if (!roleIds) return state.roles.byId;

  return roleIds.reduce(
    (nextRoles, id) => ({
      ...nextRoles,
      [id]: state.roles.byId[id],
    }),
    {}
  );
};

const filterRolesByActive = list => list.filter(role => role.status !== 'inactive');
const filterRolesByName = (list, name) =>
  list.filter(role =>
    [role.firm_name, role.role_team, role.role_title, role.role_location]
      .filter(val => val)
      .join(' ')
      .toLowerCase()
      .includes(name)
  );
const filterRolesByStatus = (list, status) => {
  const statuses = Array.isArray(status) ? status : [status];

  return list.filter(role => statuses.includes(role.status));
};
const filterRolesByTeamUser = (roles, teamUsers) => roles.filter(role => role.team?.filter(user => teamUsers.includes(user.id)).length > 0);

export const filterRolesBy = {
  active: filterRolesByActive,
  status: filterRolesByStatus,
  name: filterRolesByName,
  team: filterRolesByTeamUser,
};

export const getRolesFilteredBy = (state, filters, sortRoles = sortRolesByDefault) => {
  const users = getRoles(state);

  return sortRoles(
    Object.keys(filters).reduce((nextRoles, filter) => {
      const filterByFunction = filterRolesBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextRoles, filters[filter] ?? '');
      }

      return nextRoles;
    }, users)
  );
};
