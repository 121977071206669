/* eslint-disable camelcase */
import {useMemo} from 'react';

const useQualificationDefaultValues = (qualification = {}) => {
  const {id, name, description, search, order, has_honours} = qualification || {};

  return useMemo(() => {
    if (!qualification || !Object.keys(qualification).length) {
      return {};
    }

    return {
      id,
      name,
      description,
      search,
      order,
      has_honours,
    };
  }, [qualification, id, name, description, search, order, has_honours]);
};

export default useQualificationDefaultValues;
