/* eslint-disable camelcase */
import {useMemo} from 'react';

const useUniversityDefaultValues = (university = {}) => {
  const {id, name, short_name, search, scout_countries_id, university_aka, is_health_sector, is_legal_sector} = university || {};

  return useMemo(() => {
    if (!university || !Object.keys(university).length) {
      return {};
    }

    return {
      id,
      name,
      short_name,
      search,
      scout_countries_id,
      university_aka,
      is_health_sector,
      is_legal_sector,
    };
  }, [university, id, name, short_name, search, scout_countries_id, university_aka, is_health_sector, is_legal_sector]);
};

export default useUniversityDefaultValues;
