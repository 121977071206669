import {combineReducers} from 'redux';

const clientFilter = (state = '', action) => {
  switch (action.type) {
    case 'UI_SET_CLIENT_FILTER':
      return action.filter;
    case 'UI_CLEAR_CLIENT_FILTER':
      return action.filter;
    default:
      return state;
  }
};

export default combineReducers({
  clientFilter,
});

export const getClientFilter = state => state.clientFilter;
