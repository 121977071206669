import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {createRole, saveRole} from '../actionCreators';

const useRoleActions = roleId => {
  const dispatch = useDispatch();

  const onSaveRole = useCallback(
    role => {
      if (!roleId) {
        dispatch(createRole(role));
      } else {
        dispatch(saveRole(roleId, role));
      }
    },
    [roleId, dispatch]
  );

  return {
    onSaveRole,
  };
};

export default useRoleActions;
