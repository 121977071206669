import {useState, useEffect} from 'react';
import {useSessionAuthorities} from 'features/session';

import * as SearchFiltersApi from '../api/SearchFiltersApi';
import * as SearchOrdersApi from '../api/SearchOrdersApi';

const useSearchFilters = (type = 'candidates', exclude = []) => {
  const [authorities] = useSessionAuthorities();
  const [filters, setFilters] = useState([]);
  const [orderFields, setOrderFields] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const doLoadFilters = async () => {
      setLoading(true);
      setError(false);

      try {
        const filtersReq = SearchFiltersApi.get(type);
        const searchOrderReq = SearchOrdersApi.get(type);

        const [filtersResponse, orderFieldsResponse] = await Promise.all([filtersReq, searchOrderReq]);

        const validateFunction = filter =>
          (authorities.length > 1 || filter.field !== 'withAuthorities') && !exclude.includes(filter.field);
        setFilters(filtersResponse.data.filter(validateFunction));
        setOrderFields(orderFieldsResponse.data.filter(validateFunction));
        // setFilters(response.data);
      } catch (e) {
        setError(e.message);
      }

      setLoading(false);
    };

    doLoadFilters();

    return () => null;
  }, [authorities.length, exclude, type]);

  return [filters, orderFields, loading, error];
};

export default useSearchFilters;
