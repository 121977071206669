import {ServiceNotFound, ServiceForbidden} from 'api';

import {hideLiveRole} from 'features/liveroles';
import {getRolesLoading, getRolesSaving} from './selectors';
import * as RolesApi from './api';

export const createRole = data => async (dispatch, getState) => {
  if (getRolesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setRolesSaving());

  const response = await RolesApi.post(data);

  dispatch(updateRoles([response.data]));

  return response.data;
};

export const saveRole = (id, data) => async (dispatch, getState) => {
  if (getRolesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setRolesSaving());

  const response = await RolesApi.put(id, data);

  dispatch(updateRoles([response.data]));

  return response.data;
};

export const moveRoleToPipelines = (id, data) => async (dispatch, getState) => {
  if (getRolesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setRolesSaving());

  const response = await RolesApi.put(id, data);

  dispatch(hideLiveRole(response.data.id));

  return response.data;
};

export const fetchRoleById = (id, params) => async (dispatch, getState) => {
  dispatch(setRolesLoading());

  try {
    const response = await RolesApi.get(id, params);

    dispatch(updateRoles([response.data]));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(updateRoles([]));
      return null;
    }
    dispatch(setRolesError(e.message));
  }
  return null;
};

export const fetchRoles = params => async (dispatch, getState) => {
  if (getRolesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setRolesLoading());

  try {
    const response = await RolesApi.list(params);

    dispatch(updateRoles(response.data));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      return null;
    }
    dispatch(setRolesError(e.message));
  }
  return null;
};

export const setRolesLoading = () => ({
  type: 'SET_ROLES_LOADING',
});

export const setRolesSaving = () => ({
  type: 'SET_ROLES_SAVING',
});

export const setRolesError = (message = 'Sorry, an error occurred') => ({
  type: 'SET_ROLES_ERROR',
  message,
});

export const setRoles = roles => ({
  type: 'SET_ROLES',
  roles,
});

export const addRole = role => ({
  type: 'ADD_ROLE',
  role,
});

export const updateRoles = roles => ({
  type: 'UPDATE_ROLES',
  roles,
});

export const removeRoles = roles => ({
  type: 'REMOVE_ROLES',
  roles,
});
