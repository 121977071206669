import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveAreaOfExpertise, createAreaOfExpertise, destroyAreaOfExpertise, detachAreaOfExpertise} from '../actionCreators';

const useAreaOfExpertiseActions = id => {
  const dispatch = useDispatch();

  const onSaveAreaOfExpertise = useCallback(
    // This must be async so the form submit knows to wait.
    async values => {
      if (id) {
        await dispatch(saveAreaOfExpertise(id, values));
      } else {
        await dispatch(createAreaOfExpertise(values));
      }
    },
    [id, dispatch]
  );
  const onDestroyAreaOfExpertise = useCallback(() => (id ? dispatch(destroyAreaOfExpertise(id)) : null), [id, dispatch]);
  const onDetachAreaOfExpertise = useCallback(
    practiceAreaId => (id ? dispatch(detachAreaOfExpertise(id, practiceAreaId)) : null),
    [id, dispatch]
  );

  return {
    onSaveAreaOfExpertise,
    onDestroyAreaOfExpertise,
    onDetachAreaOfExpertise,
  };
};

export default useAreaOfExpertiseActions;
