import {setWorkmix} from 'features/workmix';
import {setPracticeAreas} from 'features/practiceAreas';

import * as AreaOfExpertiseApi from '../api';
import setAreasOfExpertise from './setAreasOfExpertise';

const createAreaOfExpertise = areaOfExpertise => async (dispatch, getState) => {
  const response = await AreaOfExpertiseApi.post(areaOfExpertise);

  dispatch(setAreasOfExpertise([response.data]));

  if (response.data.workmix) {
    dispatch(setWorkmix(response.data.workmix));
  }
  if (response.data.practice_areas) {
    dispatch(setPracticeAreas(response.data.practice_areas));
  }

  return response.data;
};

export default createAreaOfExpertise;
