import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

const DefaultLayout = ({component: Component, ...rest}) => <Route {...rest} render={matchProps => <Component {...matchProps} />} />;

DefaultLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
};

export default DefaultLayout;
