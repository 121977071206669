import {combineReducers} from 'redux';

const countries = (state = [], action) => {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return action.countries.reduce(
        (nextState, country) => ({
          ...nextState,
          [country.id]: country,
        }),
        state
      );
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_COUNTRIES_LOADING':
      return true;
    case 'SET_COUNTRIES':
    case 'SET_COUNTRIES_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_COUNTRIES_ERROR':
      return action.message;
    case 'SET_COUNTRIES_LOADING':
    case 'SET_COUNTRIES':
      return false;
    default:
      return state;
  }
};

export default combineReducers({countries, loading, loaded, error});
