import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveLanguage, createLanguage, destroyLanguage} from '../actionCreators';

const useLanguageActions = languageId => {
  const dispatch = useDispatch();

  const onSaveLanguage = useCallback(
    // This needs to be async even though it looks like we're not doing anything
    // so that the modal form components do their job.
    async values => {
      if (languageId) {
        await dispatch(saveLanguage(languageId, values));
      } else {
        await dispatch(createLanguage(values));
      }
    },
    [languageId, dispatch]
  );
  const onDestroyLanguage = useCallback(() => (languageId ? dispatch(destroyLanguage(languageId)) : null), [languageId, dispatch]);

  return {
    onSaveLanguage,
    onDestroyLanguage,
  };
};

export default useLanguageActions;
