import {useMemo} from 'react';

import {useSessionFirmId, useSessionPermissions} from 'features/session';

const useUserDefaultValues = (user, roleOptions) => {
  const {hasStaffRole} = useSessionPermissions();
  const firmId = useSessionFirmId();

  return useMemo(() => {
    if (!user || !Object.keys(user).length) {
      if (roleOptions.length === 1) {
        return {
          roles: roleOptions ?? [],
        };
      }

      return {};
    }

    const defaults = {
      fullname: user.fullname,
      email: user.email,
      country_id: user.country_id,
    };

    if (roleOptions.length > 0) {
      // eslint-disable-next-line fp/no-mutation
      defaults.roles = user.roles ?? roleOptions ?? [];
    }
    if (hasStaffRole) {
      // eslint-disable-next-line fp/no-mutation
      defaults.authorities = user.authorities;
    } else {
      // eslint-disable-next-line fp/no-mutation
      defaults.firms = user.firms ?? [firmId];
    }

    return defaults;
  }, [firmId, hasStaffRole, roleOptions, user]);
};
export default useUserDefaultValues;
