import React from 'react';
import {AuthedLayout} from 'components/Layout';

const LiveRolesDashboardLazy = React.lazy(() => import(/* webpackChunkName: "LiveRolesDashboard" */ './LiveRolesDashboard'));
export const LiveRolesDashboard = props => <AuthedLayout component={LiveRolesDashboardLazy} {...props} />;

const LiveRolesRecruiterDashboardLazy = React.lazy(() =>
  import(/* webpackChunkName: "LiveRolesRecruiterDashboard" */ './LiveRolesDashboardRecruiter')
);
export const LiveRolesRecruiterDashboard = props => <AuthedLayout component={LiveRolesRecruiterDashboardLazy} {...props} />;

// Legacy
const RoleEditLazy = React.lazy(() => import(/* webpackChunkName: "LiveRolesEdit" */ './LiveRolesEdit'));
export const RoleEdit = props => <AuthedLayout component={RoleEditLazy} {...props} />;
