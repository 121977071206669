import {combineReducers} from 'redux';

const termsAndConditions = (state = [], action) => {
  switch (action.type) {
    case 'SET_TERMS_AND_CONDITIONS':
      return action.termsAndConditions.reduce(
        (nextState, terms) => ({
          ...nextState,
          [terms.id]: terms,
        }),
        state
      );
    case 'REMOVE_TERMS_AND_CONDITIONS':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextTermsAndConditions} = nextState;
        return nextTermsAndConditions;
      }, state);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_TERMS_AND_CONDITIONS_LOADING':
      return true;
    case 'SET_TERMS_AND_CONDITIONS':
    case 'SET_TERMS_AND_CONDITIONS_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_TERMS_AND_CONDITIONS':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_TERMS_AND_CONDITIONS_ERROR':
      return action.message;
    case 'SET_TERMS_AND_CONDITIONS_LOADING':
    case 'SET_TERMS_AND_CONDITIONS':
      return false;
    default:
      return state;
  }
};

export default combineReducers({termsAndConditions, loading, loaded, error});
