/* eslint-disable camelcase */
import {useMemo} from 'react';

const useRoleKeyMessageDefaults = (role = {}) => {
  const {keyMessages, additionalNotes} = role || {};

  return useMemo(() => {
    if (!role || !Object.keys(role).length) {
      return {};
    }

    return {
      role_key_messages: keyMessages,
      role_notes_additional: additionalNotes,
      ...role,
    };
  }, [role, keyMessages, additionalNotes]);
};

export default useRoleKeyMessageDefaults;
