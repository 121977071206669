import {createSelector} from 'reselect';

const scope = state => state.savedSearch;

/* eslint-disable fp/no-mutating-methods */
export const sortSavedSearchByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortSavedSearchByDefault = list =>
  list.slice(0).sort((a, b) => (a.order === b.order ? a.name.localeCompare(b.name) : b.order - a.order));
/* eslint-enable fp/no-mutating-methods */

export const getSavedSearchLoading = state => scope(state).loading;
export const getSavedSearchError = state => scope(state).error;
export const getSavedSearchLoaded = state => scope(state).loaded;
export const getSavedSearch = state => Object.values(scope(state).savedSearch);
export const getSavedSearchSortByName = createSelector(getSavedSearch, sortSavedSearchByName);
export const getSavedSearchSortByDefault = createSelector(getSavedSearch, sortSavedSearchByDefault);

const filterSavedSearchByName = (list, name) => list.filter(savedSearch => savedSearch.name.toLowerCase().includes(name.toLowerCase()));
const filterSavedSearchByType = (list, type) => {
  const types = Array.isArray(type) ? type : [type];

  return list.filter(savedSearch => types.includes(savedSearch.type));
};

const filterSavedSearchBy = {
  type: filterSavedSearchByType,
  name: filterSavedSearchByName,
};

export const getSavedSearchFilteredBy = (state, filters, sortSavedSearch = sortSavedSearchByName) => {
  const users = getSavedSearch(state);

  return sortSavedSearch(
    Object.keys(filters).reduce((nextSavedSearch, filter) => {
      const filterByFunction = filterSavedSearchBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextSavedSearch, filters[filter] ?? '');
      }

      return nextSavedSearch;
    }, users)
  );
};
