import {ServiceForbidden, ServiceNotFound} from 'api';

// We directly import this to avoid dependancy cycle errors.
import setAreasOfExpertise from 'features/areasOfExpertise/actionCreators/setAreasOfExpertise';

import * as PracticeAreasApi from './api';
import {getPracticeAreasLoading} from './selectors';

export const fetchPracticeAreas = params => async (dispatch, getState) => {
  if (getPracticeAreasLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setPracticeAreasLoading());

  try {
    const response = await PracticeAreasApi.list(params);

    dispatch(setPracticeAreas(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setPracticeAreas([]));

      return null;
    }
    dispatch(setPracticeAreasError(e.message));
  }
  return null;
};

export const createPracticeArea = practiceArea => async (dispatch, getState) => {
  const response = await PracticeAreasApi.post(practiceArea);

  dispatch(setPracticeAreas([response.data]));

  if (response.data.areas_of_expertise) {
    dispatch(setAreasOfExpertise(response.data.areas_of_expertise));
  }

  return response.data;
};

export const savePracticeArea = (id, practiceArea) => async (dispatch, getState) => {
  const response = await PracticeAreasApi.put(id, practiceArea);

  dispatch(setPracticeAreas([response.data]));

  if (response.data.areas_of_expertise) {
    dispatch(setAreasOfExpertise(response.data.areas_of_expertise));
  }

  return response.data;
};

export const destroyPracticeArea = id => async (dispatch, getState) => {
  const response = await PracticeAreasApi.destroy(id);

  dispatch(removePracticeAreas([id]));

  return response.data;
};

export const setPracticeAreasError = message => ({
  type: 'SET_PRACTICE_AREAS_ERROR',
  message,
});

export const setPracticeAreasLoading = () => ({
  type: 'SET_PRACTICE_AREAS_LOADING',
});

export const setPracticeAreas = practiceAreas => ({
  type: 'SET_PRACTICE_AREAS',
  practiceAreas,
});

export const removePracticeAreas = ids => ({
  type: 'REMOVE_PRACTICE_AREAS',
  ids,
});
