import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveSector, createSector, destroySector} from '../actionCreators';

const useSectorActions = sectorId => {
  const dispatch = useDispatch();

  const onSaveSector = useCallback(
    async values => {
      if (sectorId) {
        await dispatch(saveSector(sectorId, values));
      } else {
        await dispatch(createSector(values));
      }
    },
    [sectorId, dispatch]
  );
  const onDestroySector = useCallback(() => (sectorId ? dispatch(destroySector(sectorId)) : null), [sectorId, dispatch]);

  return {
    onSaveSector,
    onDestroySector,
  };
};

export default useSectorActions;
