/* eslint-disable prefer-const */
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export const getIsLoggedIn = state => !!state.session.userId;
export const getIsLoginRequired = state => !!state.session.isLoginRequired;
export const getIsAccountVerified = state => getIsLoggedIn(state) && state.session.userdata.active === '1';
export const getSessionLoading = state => state.session.loading;
export const getSessionLoaded = state => state.session.loaded;
export const getSessionSubmitting = state => state.session.submitting;
export const getSessionShouldLoad = state => !getSessionLoaded(state);
export const getSessionError = state => state.session.error;
export const getSessionUserId = state => state.session.userId;
export const getSessionUserdata = state => state.session.userdata;
export const getSessionRoles = state =>
  state.session.userdata && Array.isArray(state.session.userdata.roles) ? state.session.userdata.roles : [];
export const getSessionHasRole = (state, role) => getSessionRoles(state).includes(role) || getSessionRoles(state).includes('Developer');
export const getSessionHasAnyRole = (state, roles) => !!roles.find(role => getSessionHasRole(state, role));
export const getSessionFirmIds = state => getIsLoggedIn(state) && (state.session.userdata.firmIds ?? state.session.userdata.firm_ids ?? []);
export const getSessionIsHealth = state =>
  getIsLoggedIn(state) && (state.session.userdata.is_health_sector ?? state.session.userdata.is_health_sector ?? []);
export const getSessionAuthMessage = state => state.session.authMessage;
export const getSessionRedirect = state => (getIsLoggedIn(state) ? state.session.userdata.redirect || '/' : '/');
export const getSessionOnTrial = state => state.session.trial || false;
export const getSessionAuthorities = state => (getIsLoggedIn(state) && state.session.userdata.authorities) || [];
export const getSessionTerms = state => (getIsLoggedIn(state) && !!state.session.userdata.terms_accepted_at) || false;
export const getSessionRecruiterTerms = state =>
  (getIsLoggedIn(state) && !!state.session.userdata?.firm_recruiters_tc_agreed?.some(x => x)) || false;
export const getSessionCountryId = state => (getIsLoggedIn(state) && state.session.userdata.country_id) || null;

export const getSessionHasStaffRole = state => getSessionHasRole(state, 'Staff');
export const getSessionHasRecruiterViewerRole = state => getSessionRoles(state).includes('RecruiterUser');
export const getSessionHasRecruiterUserRole = state => getSessionRoles(state).includes('RecruiterViewer');

export const getSessionHasReviewRole = state => getSessionHasRole(state, 'Researcher');
export const getSessionHasSubscriberRole = state => getSessionHasRole(state, 'Operator');
export const getSessionHasSubscriberAdminRole = state => getSessionHasRole(state, 'Owner');
export const getSessionHasAdminRole = state => getSessionHasRole(state, 'Admin');
export const getSessionHasPortalRole = state => getSessionHasRole(state, 'Portal');
export const getSessionHasViewerRole = state => getSessionHasRole(state, 'Viewer');
export const getSessionHasDeveloperRole = state => getSessionHasRole(state, 'Developer');

export const getSessionUserAllowedCreateUserPermissions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {hasStaffRole, hasAdminRole} = useSessionPermissions();

  // Convert the values
  // Owner = Subscriber Admin
  // Operator = Subscriber User
  // Viewer = Subscriber Viewer

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userdata = useSelector(getSessionUserdata);
  // eslint-disable-next-line prefer-const
  let arr = [];
  // eslint-disable-next-line guard-for-in
  if (userdata.firm_allowed_create_user_permissions) {
    userdata.firm_allowed_create_user_permissions.forEach(names => {
      if (names) {
        names.split(/\r?\n/).forEach(name => {
          // eslint-disable-next-line fp/no-mutating-methods
          if (name === 'Owner') arr.push('Subscriber Admin');
          // eslint-disable-next-line fp/no-mutating-methods
          else if (name === 'Operator') arr.push('Subscriber User');
          // eslint-disable-next-line fp/no-mutating-methods
          else if (name === 'Viewer') arr.push('Subscriber Viewer');
          // eslint-disable-next-line fp/no-mutating-methods
          else if (name === 'Portal') arr.push('Portal Viewer');
          // eslint-disable-next-line fp/no-mutating-methods
          else if (name === 'RecruiterUser') arr.push('Recruiter User');
          // eslint-disable-next-line fp/no-mutating-methods
          else if (name === 'RecruiterViewer') arr.push('Recruiter Viewer');
          // eslint-disable-next-line fp/no-mutating-methods
          else arr.push(name);
        });
      }
    });
  }

  // if no filter (e.g.: normal existing Subscriber Firm) allow all but Portal
  if (arr.length <= 0) {
    // eslint-disable-next-line fp/no-mutating-methods
    arr.push('Subscriber Admin');
    // eslint-disable-next-line fp/no-mutating-methods
    arr.push('Subscriber User');
    // eslint-disable-next-line fp/no-mutating-methods
    arr.push('Subscriber Viewer');

    if (hasStaffRole || hasAdminRole) {
      // eslint-disable-next-line fp/no-mutating-methods
      arr.push('Recruiter User');
      // eslint-disable-next-line fp/no-mutating-methods
      arr.push('Recruiter Viewer');
    }

    // eslint-disable-next-line fp/no-mutating-methods
    if (userdata.firm_names.includes('Insource')) arr.push('Portal Viewer');
  }
  return arr;
};

export const getSessionUserAllowedExportToExcel = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userdata = useSelector(getSessionUserdata);
  return userdata.firm_allowed_export_to_excel.find(x => x === 1);
};

export const getSessionUserAllowedRecruitForFirms = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userdata = useSelector(getSessionUserdata);
  return userdata.firm_allowed_recruit_for_firms;
};

export const useSessionUserAllowedSelectAll = () => {
  const userdata = useSelector(getSessionUserdata);

  return userdata.firm_allowed_select_all.find(x => x === 1);
};

export const useSessionUserSelectAllLimit = () => {
  const userdata = useSelector(getSessionUserdata);
  return (userdata.firm_select_all_limit || []).reduce((max, val) => (max > val ? max : val), 0);
};

export const useSessionPermissions = () => {
  const hasStaffRole = useSelector(getSessionHasStaffRole);
  const hasAdminRole = useSelector(getSessionHasAdminRole);
  const hasReviewRole = useSelector(getSessionHasReviewRole);
  const hasPortalRole = useSelector(getSessionHasPortalRole);
  const hasSubscriberRole = useSelector(getSessionHasSubscriberRole);
  const hasSubscriberAdminRole = useSelector(getSessionHasSubscriberAdminRole);
  const hasSubscriberViewerRole = useSelector(getSessionHasViewerRole);
  const hasDeveloperRole = useSelector(getSessionHasDeveloperRole);
  const hasRecruiterUserRole = useSelector(getSessionHasRecruiterUserRole);
  const hasRecruiterViewerRole = useSelector(getSessionHasRecruiterViewerRole);

  const hasRecruiterRole = hasRecruiterUserRole || hasRecruiterViewerRole;

  const isOnTrial = useSelector(getSessionOnTrial);
  const isStaff = hasStaffRole || hasAdminRole || hasReviewRole;
  const isSearch = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole;
  const isApproach = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole || hasRecruiterRole;
  const isSubscriber = hasSubscriberRole || hasSubscriberAdminRole || hasSubscriberViewerRole;
  const canCreateRoleBrief = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole;
  const canEditRoleBriefs = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole;
  const canRestoreRoleBriefs = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole;
  const canViewAllRoles = isStaff || hasSubscriberRole || hasSubscriberAdminRole;
  const canViewArchivedRoles = isStaff || hasSubscriberRole || hasSubscriberAdminRole;
  const canViewOtherRoles = hasStaffRole || hasAdminRole || hasSubscriberRole || hasSubscriberAdminRole;
  const canSetupCreate = hasStaffRole || hasAdminRole;
  let canRecruitForSpecificFirms = hasRecruiterRole;
  const userdata = useSelector(getSessionUserdata);

  if (userdata.firm_allowed_recruit_for_firms) {
    let i = null;
    // eslint-disable-next-line guard-for-in
    for (i in userdata.firm_allowed_recruit_for_firms) {
      // eslint-disable-next-line prefer-const
      let allowedFirmName = userdata.firm_allowed_recruit_for_firms[i];
      if (allowedFirmName && allowedFirmName.trim() !== '') {
        // eslint-disable-next-line fp/no-mutation
        canRecruitForSpecificFirms = true;
        break;
      }
    }
  }

  return {
    hasStaffRole,
    hasAdminRole,
    hasReviewRole,
    hasSubscriberRole,
    hasSubscriberAdminRole,
    hasSubscriberViewerRole,
    hasDeveloperRole,
    hasPortalRole,
    isStaff,
    isSearch,
    isApproach,
    isSubscriber,
    isOnTrial,
    canCreateRoleBrief,
    canEditRoleBriefs,
    canRestoreRoleBriefs,
    canViewAllRoles,
    canViewArchivedRoles,
    canViewOtherRoles,
    canSetupCreate,
    canRecruitForSpecificFirms,
    hasRecruiterRole,
  };
};

export const useSessionUserdata = () => {
  const userdata = useSelector(getSessionUserdata);
  const loading = useSelector(getSessionLoading);
  const error = useSelector(getSessionError);
  return useMemo(() => ({...userdata, error, loading}), [userdata, error, loading]);
};

export const useSessionUserId = () => {
  const id = useSelector(getSessionUserId);
  const loading = useSelector(getSessionLoading);
  const error = useSelector(getSessionError);
  return [id, loading, error];
};

export const useSessionAuthorities = () => {
  const authorities = useSelector(getSessionAuthorities);
  const loading = useSelector(getSessionLoading);
  const error = useSelector(getSessionError);
  return [authorities, loading, error];
};

export const useSessionFirmId = () => {
  const [firmId] = useSelector(getSessionFirmIds);
  return firmId;
};

export const useSessionFirmIds = () => {
  const [firmIds] = useSelector(getSessionFirmIds);
  return firmIds;
};

export const useIsHealth = () => {
  const isHealth = useSelector(getSessionIsHealth);
  return isHealth;
};

export const useSessionTermsAccepted = () => useSelector(getSessionTerms);
export const useSessionCountryId = () => useSelector(getSessionCountryId);

export const useSessionNeedsToAcceptTerms = () => {
  const loggedIn = useSelector(getIsLoggedIn);
  const termsAccepted = useSelector(getSessionTerms);
  const hasDeveloperRole = useSelector(getSessionHasDeveloperRole);
  const hasReviewRole = useSelector(getSessionHasReviewRole);
  const hasPortalRole = useSelector(getSessionHasPortalRole);
  const hasSubscriberRole = useSelector(getSessionHasSubscriberRole);
  const hasSubscriberAdminRole = useSelector(getSessionHasSubscriberAdminRole);
  const hasSubscriberViewerRole = useSelector(getSessionHasViewerRole);
  const hasRecruiterViewerRole = useSelector(getSessionHasRecruiterViewerRole);
  const hasRecruiterUserRole = useSelector(getSessionHasRecruiterUserRole);

  const isSubscriber = hasSubscriberRole || hasSubscriberAdminRole || hasSubscriberViewerRole;

  return (
    loggedIn &&
    !termsAccepted &&
    (isSubscriber || hasPortalRole || hasRecruiterViewerRole || hasRecruiterUserRole) &&
    !hasDeveloperRole &&
    !hasReviewRole
  );
};

export const useSessionNeedsToAcceptRecruiterTerms = () => {
  const loggedIn = useSelector(getIsLoggedIn);
  const termsAccepted = useSelector(getSessionRecruiterTerms);

  return loggedIn && !termsAccepted;
};
