import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getAuthorities, getAuthoritiesLoading, getAuthoritiesLoaded, getAuthoritiesError} from '../selectors';
import {fetchAuthorities} from '../actionCreators';

const useAuthorities = (forceReload = false) => {
  const authorities = useSelector(getAuthorities);
  const loaded = useSelector(getAuthoritiesLoaded);
  const loading = useSelector(getAuthoritiesLoading);
  const error = useSelector(getAuthoritiesError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchAuthorities());
    return () => null;
  }, [dispatch, loaded, forceReload]);

  return [authorities, loading, error];
};

export default useAuthorities;
