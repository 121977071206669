import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveUniversity, createUniversity, destroyUniversity} from '../actionCreators';

const useUniversityActions = universityId => {
  const dispatch = useDispatch();

  const onSaveUniversity = useCallback(
    async values => {
      if (universityId) {
        await dispatch(saveUniversity(universityId, values));
      } else {
        await dispatch(createUniversity(values));
      }
    },
    [universityId, dispatch]
  );
  const onDestroyUniversity = useCallback(
    () => (universityId ? dispatch(destroyUniversity(universityId)) : null),
    [universityId, dispatch]
  );

  return {
    onSaveUniversity,
    onDestroyUniversity,
  };
};

export default useUniversityActions;
