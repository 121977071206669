/* eslint-disable camelcase */
import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateEducationHistoryDefaultValues = (educationItem = {}) => {
  const {date_start, date_end} = educationItem || {};

  return useMemo(() => {
    if (!educationItem || !Object.keys(educationItem).length) {
      return {};
    }

    const startDate = dayjs.utc(date_start, 'YYYY-MM-DD');
    const endDate = dayjs.utc(date_end, 'YYYY-MM-DD');

    return {
      scout_education_date_range: {
        startDate: startDate.isValid() ? startDate.local() : '',
        endDate: endDate.isValid() ? endDate.local() : '',
      },
      masters: +educationItem.masters === 1,
      honours: +educationItem.honours === 1,
      note: educationItem.note ? educationItem.note.note : '',
      note_id: educationItem.note ? educationItem.note.id : '',
      scout_universities_id: educationItem.university ? educationItem.university.id : '', // educationItem.scout_universities_id,
      scout_qualifications_id: educationItem.qualification ? educationItem.qualification.id : '', // educationItem.scout_qualifications_id
    };
  }, [educationItem, date_start, date_end]);
};

export default useCandidateEducationHistoryDefaultValues;
