import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {
  saveCandidateToInterestedList,
  saveCandidateToLongList,
  saveCandidateToShortList,
  saveCandidateToHoldList,
  saveCandidateToUnsuitedList,
  deleteCandidate,
} from '../actionCreators';

const useCandidateListActions = (candidateId, roleId, openNextCandidate, roleType) => {
  const dispatch = useDispatch();

  const onLongList = useCallback(
    (options = {}) => {
      dispatch(saveCandidateToLongList(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  const onShortList = useCallback(
    (options = {}) => {
      dispatch(saveCandidateToShortList(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  const onInterestedList = useCallback(
    (options = {}) => {
      dispatch(saveCandidateToInterestedList(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  const onUnsuited = useCallback(
    (options = {}) => {
      dispatch(saveCandidateToUnsuitedList(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  const onHold = useCallback(
    (options = {}) => {
      dispatch(saveCandidateToHoldList(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  const onDelete = useCallback(
    (options = {}) => {
      dispatch(deleteCandidate(candidateId, roleId));
      if (!options?.undo) openNextCandidate(roleType);
    },
    [candidateId, dispatch, roleId, openNextCandidate, roleType]
  );

  return {
    onLongList,
    onShortList,
    onInterestedList,
    onUnsuited,
    onHold,
    onDelete,
  };
};

export default useCandidateListActions;
