import {combineReducers} from 'redux';

const workmix = (state = [], action) => {
  switch (action.type) {
    case 'SET_WORKMIX':
      return action.workmix.reduce(
        (nextState, workmixItem) => ({
          ...nextState,
          [workmixItem.id]: workmixItem,
        }),
        state
      );
    case 'REMOVE_WORKMIX':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextWorkmix} = nextState;
        return nextWorkmix;
      }, state);
    case 'SET_WORKMIX_DETACH_AREA_OF_EXPERTISE': {
      const {[action.id]: nextWorkmix, ...nextState} = state;
      if (Array.isArray(nextWorkmix.areas_of_expertise)) {
        // eslint-disable-next-line fp/no-mutation
        nextWorkmix.area_of_expertise_ids = nextWorkmix.area_of_expertise_ids.filter(
          areaOfExpertiseId => areaOfExpertiseId !== action.areaOfExpertiseId
        );
      }
      if (Array.isArray(nextWorkmix.areas_of_expertise)) {
        // eslint-disable-next-line fp/no-mutation
        nextWorkmix.areas_of_expertise = nextWorkmix.areas_of_expertise.filter(
          checkWorkmix => checkWorkmix.area_of_expertise_id !== action.areaOfExpertiseId
        );
      }
      return {[action.id]: nextWorkmix, ...nextState};
    }
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_WORKMIX_LOADING':
      return true;
    case 'SET_WORKMIX':
    case 'SET_WORKMIX_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_WORKMIX':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_WORKMIX_ERROR':
      return action.message;
    case 'SET_WORKMIX_LOADING':
    case 'SET_WORKMIX':
      return false;
    default:
      return state;
  }
};

export default combineReducers({workmix, loading, loaded, error});
