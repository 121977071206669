import {useMemo} from 'react';

const useAuthoritySearchSelect = (authorities = [], keyBy = 'code') =>
  useMemo(
    () =>
      authorities.map(authority => ({
        id: authority[keyBy],
        search: authority.name.toLowerCase(),
        text: authority.name,
      })),
    [authorities, keyBy]
  );

export default useAuthoritySearchSelect;
