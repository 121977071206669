import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveCandidateApproach, createCandidateApproach} from '../actionCreators';

const useCandidateApproachActions = (candidateId, roleId, approachId) => {
  const dispatch = useDispatch();

  const onSaveApproach = useCallback(
    approach => {
      if (!approachId) {
        dispatch(createCandidateApproach(candidateId, {...approach, scout_role_briefs_id: roleId}));
      } else {
        dispatch(saveCandidateApproach(candidateId, {...approach, id: approachId, scout_role_briefs_id: roleId}));
      }
    },
    [approachId, dispatch, candidateId, roleId]
  );

  return {
    onSaveApproach,
  };
};

export default useCandidateApproachActions;
