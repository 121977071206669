import {orders} from '../filters';

// Get filters from the search API.
// For now, these are a hard-coded JSON data structure.
// In the future, this will be a proper API endpoint.
export const get = async type => {
  if (orders[type]) {
    const module = await orders[type];
    return module.default;
  }

  throw new Error(`The requested filter [${type}] is not available`);
};
