import React from 'react';
import PropTypes from 'prop-types';

import './ErrorMessage.scss';

/**
 * A component used to display a full-screen error message.
 *
 * @usage;
 *
 *		import { ErrorMessage } from '../app'
 *
 *		...
 *		<ErrorMessage
 * 	 		message="Sorry, you need to log in"
 * 	 	/>
 *		...
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @type {Component}
 */
const ErrorMessage = ({fullScreen, title, message, children, onRetry}) => (
  <div className={`error__container ${fullScreen && 'error__container--fullscreen'}`}>
    <div className="error__content">
      <h1 className="error__title">{title}</h1>
      {message && <h2 className="error__message">{message}</h2>}
      {children}
      {onRetry && (
        <button type="button" className="error__retry" onClick={onRetry}>
          Retry
        </button>
      )}
    </div>
  </div>
);

ErrorMessage.propTypes = {
  /**
   * Should this be a fullscreen error message?
   */
  fullScreen: PropTypes.bool,

  /**
   * The main title of the error message.
   */
  title: PropTypes.string,

  /**
   * An optional sub-message to display.
   * Typically used to explain an error in more detail, e.g.,
   * next steps, what went wrong, how to resolve, or what to expect.
   */
  message: PropTypes.string,

  /**
   * Should be a node, or an array of nodes.
   * This is content which might help the user
   * resolve the error, or guide toward resolution
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /**
   * This should be a function to run on retry click.
   */
  onRetry: PropTypes.func,
};

ErrorMessage.defaultProps = {
  fullScreen: false,
  title: 'Sorry, an error occurred',
  children: null,
  message: null,
  onRetry: null,
};

export default ErrorMessage;
