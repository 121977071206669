import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getTermsAndConditionsFilteredBy,
  getTermsAndConditionsLoading,
  getTermsAndConditionsError,
  getTermsAndConditionsLoaded,
  sortTermsAndConditionsByDefault,
} from '../selectors';
import {fetchTermsAndConditions} from '../actionCreators';

const filterTermsAndConditionsByCountryName = countryName => state =>
  getTermsAndConditionsFilteredBy(state, {countryName}, sortTermsAndConditionsByDefault);

const useTermsAndConditions = (countryNameFilter, params, forceReload = false) => {
  const termsAndConditions = useSelector(filterTermsAndConditionsByCountryName(countryNameFilter));
  const loaded = useSelector(getTermsAndConditionsLoaded);
  const loading = useSelector(getTermsAndConditionsLoading);
  const error = useSelector(getTermsAndConditionsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchTermsAndConditions(params));
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, forceReload]);

  return [termsAndConditions, loading, error];
};

export default useTermsAndConditions;
