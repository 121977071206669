const collator = new Intl.Collator();

const scope = state => state.authorities;

// eslint-disable-next-line fp/no-mutating-methods
const sortAuthoritiesByName = authorities => authorities.slice(0).sort((a, b) => collator.compare(a.name, b.name));

export const getAuthorities = state => scope(state).ids.map(id => scope(state).byId[id]);
export const getAuthoritiesLoading = state => scope(state).loading;
export const getAuthoritiesLoaded = state => scope(state).loaded;
export const getAuthoritiesError = state => scope(state).error;

export const getAuthoritiesSortByName = state => sortAuthoritiesByName(getAuthorities(state));
