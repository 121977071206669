import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useSessionPermissions} from 'features/session';
import {createCandidateNote} from '../actionCreators';

const useCandidateSaveNote = candidateId => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const {hasRecruiterRole} = useSessionPermissions();

  const onSave = useCallback(
    async (note, firmId, roleId, visibility = 'public') => {
      setSaving(true);
      setError(false);

      try {
        let theRoleId = roleId;
        if (!theRoleId && hasRecruiterRole) {
          const parts = window.location.pathname.split('/');
          // eslint-disable-next-line fp/no-mutation, no-plusplus
          for (let i = 0; i < parts.length; i++) {
            if (i + 1 < parts.length && (parts[i] === 'liverole' || parts[i] === 'pipeline' || parts[i] === 'review')) {
              // eslint-disable-next-line fp/no-mutation
              theRoleId = parts[i + 1];
              break;
            }
          }
        }
        await dispatch(
          createCandidateNote(candidateId, {
            role_id: theRoleId,
            firm_id: firmId,
            visibility,
            note,
          })
        );
      } catch (e) {
        setError(e.message);
      }

      setSaving(false);
    },
    [candidateId, dispatch, hasRecruiterRole]
  );

  return [onSave, saving, error];
};

export default useCandidateSaveNote;
