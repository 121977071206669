/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import {useDelay} from 'helpers';

import 'styles/components/_loading.scss';

const Loading = ({size, fill, delay}) => {
  const show = useDelay(delay);

  return (
    <motion.svg
      key="loading"
      version="1.1"
      className="loader"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      animate={{opacity: show ? 1 : 0}}
      initial={{opacity: 0}}
      exit={{opacity: 0}}
      transition={{duration: 0.2}}
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 50 50"
      style={{enableBackground: 'new 0 0 50 50', marginTop: `-${size / 2}px`, marginLeft: `-${size / 2}px`}}
      xmlSpace="preserve">
      <path
        fill={fill}
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </motion.svg>
  );
};

Loading.propTypes = {
  delay: PropTypes.number,
  size: PropTypes.number,
  fill: PropTypes.string,
};

Loading.defaultProps = {
  delay: 0,
  size: 50,
  fill: '#000',
};

export default Loading;
