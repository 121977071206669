import * as AuthoritiesApi from './api';

import {getAuthoritiesLoading} from './selectors';

export const fetchAuthorities = () => async (dispatch, getState) => {
  if (getAuthoritiesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setAuthoritiesLoading());

  try {
    const response = await AuthoritiesApi.list();
    dispatch(setAuthorities(response.data));

    return response.data;
  } catch (e) {
    dispatch(setAuthoritiesError(e.message));
    return [];
  }
};

export const setAuthoritiesLoading = () => ({
  type: 'SET_AUTHORITIES_LOADING',
});

export const setAuthoritiesError = (message = 'Sorry, an error occurred') => ({
  type: 'SET_AUTHORITIES_ERROR',
  message,
});

export const setAuthorities = authorities => ({
  type: 'SET_AUTHORITIES',
  authorities,
});

export const updateAuthorities = authorities => ({
  type: 'UPDATE_AUTHORITIES',
  authorities,
});
