import {createSelector} from 'reselect';

const scope = state => state.qualifications;

/* eslint-disable fp/no-mutating-methods */
export const sortQualificationsByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortQualificationsByDefault = list =>
  list.slice(0).sort((a, b) => (a.order === b.order ? a.name.localeCompare(b.name) : b.order - a.order));
/* eslint-enable fp/no-mutating-methods */

export const getQualificationsLoading = state => scope(state).loading;
export const getQualificationsError = state => scope(state).error;
export const getQualificationsLoaded = state => scope(state).loaded;
export const getQualifications = state => Object.values(scope(state).qualifications);
export const getQualificationsSortByName = createSelector(getQualifications, sortQualificationsByName);
export const getQualificationsSortByDefault = createSelector(getQualifications, sortQualificationsByDefault);

const filterQualificationsByName = (list, name) =>
  list.filter(qualification => qualification.name.toLowerCase().includes(name.toLowerCase()));

const filterQualificationsBy = {
  name: filterQualificationsByName,
};

export const getQualificationsFilteredBy = (state, filters, sortQualifications = sortQualificationsByName) => {
  const users = getQualifications(state);

  return sortQualifications(
    Object.keys(filters).reduce((nextQualifications, filter) => {
      const filterByFunction = filterQualificationsBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextQualifications, filters[filter] ?? '');
      }

      return nextQualifications;
    }, users)
  );
};
