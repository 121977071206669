import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateEducation, deleteCandidateEducationItem} from '../actionCreators';

const useCandidateEducationHistoryActions = (candidateId, educationItem) => {
  const dispatch = useDispatch();

  const educationItemId = educationItem ? educationItem.id : undefined;

  const onSaveEducationHistory = useCallback(
    values => dispatch(updateCandidateEducation({id: educationItemId, scout_candidates_id: candidateId, ...values})),
    [candidateId, educationItemId, dispatch]
  );
  const onDeleteEducationHistory = useCallback(
    () => (educationItemId ? dispatch(deleteCandidateEducationItem(educationItemId, candidateId)) : null),
    [candidateId, educationItemId, dispatch]
  );

  return {
    onSaveEducationHistory,
    onDeleteEducationHistory,
  };
};

export default useCandidateEducationHistoryActions;
