import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Intent} from '@blueprintjs/core';

import {ServiceForbidden} from 'api';
import {setSessionExpired} from 'features/session';
import {MainToaster} from 'components/Shared';

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    if (error instanceof ServiceForbidden) {
      return {
        hasError: false,
      };
    }

    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (error instanceof ServiceForbidden) {
      MainToaster.show({message: 'Your login session has expired. Please log in and try again.', intent: Intent.DANGER});
      this.props.setSessionExpired();
    }
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fallback: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  setSessionExpired: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  fallback: <h1>Something went wrong.</h1>,
};

const mapDispatchToProps = {
  setSessionExpired,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
