const collator = new Intl.Collator();
const defaultRoleOrder = ['Staff', 'Admin', 'Researcher', 'Owner', 'Operator', 'Portal', 'Viewer', 'RecruiterUser', 'RecruiterViewer'];

// eslint-disable-next-line camelcase
const findRoleAccess =
  ({roles}) =>
  role =>
    roles.includes(role);

export const combineComparators =
  (...comparators) =>
  (a, b) => {
    for (const fn of comparators) {
      const order = fn(a, b);
      if (order !== 0) return order;
    }
    return 0;
  };

export const compareUsersByFullname = (a, b) => collator.compare(a.fullname, b.fullname);
export const compareUsersByRole = (a, b) => defaultRoleOrder.findIndex(findRoleAccess(b)) - defaultRoleOrder.findIndex(findRoleAccess(a));

/* eslint-disable fp/no-mutating-methods */
export const sortUsersByRoleThenFullname = users => users.slice(0).sort(combineComparators(compareUsersByRole, compareUsersByFullname));
export const sortUsersByFullname = users => users.slice(0).sort(compareUsersByFullname);
/* eslint-enable fp/no-mutating-methods */

export const filterUsersByRole = (users, role, isIncludeDev = false) => {
  const roles = Array.isArray(role) ? role : [role];

  return users.filter(user => roles.find(userRole => user.roles.includes(userRole) && (isIncludeDev || !user.roles.includes('Developer'))));
};

const filterUsersByFullname = (users, fullname) => users.filter(user => user.fullname.toLowerCase().includes(fullname.toLowerCase()));
const filterUsersByFirmId = (users, firmId) => {
  const firmIds = Array.isArray(firmId) ? firmId : [firmId];

  return users.filter(user => firmIds.find(userFirm => user.firm_ids.includes(userFirm)));
};

const filterUsersBy = {
  firmId: filterUsersByFirmId,
  role: filterUsersByRole,
  fullname: filterUsersByFullname,
};

export const getUsers = state => Object.values(state.users.usersById);

export const getUsersFilteredByFullname = (state, fullname, sortUsers = sortUsersByFullname) =>
  sortUsers(filterUsersByFullname(getUsers(state), fullname));
export const getUsersFilteredByRole = (state, role, sortUsers = sortUsersByFullname) => sortUsers(filterUsersByRole(getUsers(state), role));

/**
 * SELECTOR: Get users filterd by possibly multiple filter options.
 *
 * Filters should be an object with keys defining the filter to use (as per filterUsersBy above)
 * and values defining the value to pass to the filter method.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {Object}   state      The current redux state tree relative to this reducer
 * @param  {Object}   filters    The filters to apply to this state
 * @param  {Function} sortUsers  The filters to apply to this state
 * @return {Array}               The filtered users sorted by firstname.
 */
export const getUsersFilteredBy = (state, filters, sortUsers = sortUsersByFullname) => {
  const users = getUsers(state);

  return sortUsers(
    Object.keys(filters).reduce((nextUsers, filter) => {
      const filterByFunction = filterUsersBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextUsers, filters[filter] ?? '');
      }

      return nextUsers;
    }, users)
  );
};

export const getUsersStaffAndSubscribers = state =>
  getUsersFilteredByRole(state, ['Admin', 'Staff', 'Owner', 'Operator', 'Portal', 'Viewer']);
export const getUsersSubscribers = state => getUsersFilteredByRole(state, ['Owner', 'Operator', 'Portal']);
export const getUsersStaff = state => getUsersFilteredByRole(state, ['Admin', 'Staff', 'Researcher']);
export const getUsersTeam = state => getUsersFilteredByRole(state, ['Owner', 'Operator', 'Portal', 'Viewer']);

export const getUsersLoading = state => state.users.usersLoading;
export const getUsersSaving = state => state.users.usersSaving;
export const getUsersError = state => state.users.usersError;
