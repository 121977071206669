import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getAreasOfexpertiseFilteredByPracticeAreaId,
  getAreasOfExpertiseSortByDefault,
  getAreasOfExpertiseLoading,
  getAreasOfExpertiseError,
  getAreasOfExpertiseLoaded,
} from '../selectors';
import {fetchAreasOfExpertise} from '../actionCreators';

const filterAreasOfExpertiseByPracticeAreaId = practiceAreaId => state =>
  getAreasOfexpertiseFilteredByPracticeAreaId(state, practiceAreaId);

const useAreasOfExpertise = (practiceAreaId, forceReload = false) => {
  const areasOfExpertise = useSelector(
    practiceAreaId ? filterAreasOfExpertiseByPracticeAreaId(practiceAreaId) : getAreasOfExpertiseSortByDefault
  );
  const loaded = useSelector(getAreasOfExpertiseLoaded);
  const loading = useSelector(getAreasOfExpertiseLoading);
  const error = useSelector(getAreasOfExpertiseError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchAreasOfExpertise());
    return () => null;
  }, [dispatch, loaded, forceReload]);

  return [areasOfExpertise, loading, error];
};

export default useAreasOfExpertise;
