import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getRolesLoading, getRolesLoaded, getRolesFilteredBy, getRolesError, sortRolesByDefault} from '../selectors';
import {fetchRoles} from '../actionCreators';

const filterRolesBy = filters => state => getRolesFilteredBy(state, filters, sortRolesByDefault);

const defaultFilters = {status: ['active', 'approach']};
const defaultParams = {filter: {status: 'active||approach'}};

const useRoles = (filters = defaultFilters, params = defaultParams) => {
  const roles = useSelector(filterRolesBy(filters));
  const loaded = useSelector(getRolesLoaded);
  const loading = useSelector(getRolesLoading);
  const error = useSelector(getRolesError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles(params));
    return () => null;
  }, [dispatch, params]);

  return [roles, loading, error, loaded];
};

export default useRoles;
