import {ServiceForbidden, ServiceNotFound} from 'api';

import * as TermsAndConditionsApi from './api';

export const fetchTermsAndConditions = params => async (dispatch, getState) => {
  dispatch(setTermsAndConditionsLoading());

  try {
    const response = await TermsAndConditionsApi.list(params);

    dispatch(setTermsAndConditions(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setTermsAndConditions([]));

      return null;
    }
    dispatch(setTermsAndConditionsError(e.message));
  }
  return [];
};

export const createTermsAndConditions = sector => async (dispatch, getState) => {
  const response = await TermsAndConditionsApi.post(sector);

  dispatch(setTermsAndConditions([response.data]));

  return response.data;
};

export const saveTermsAndConditions = (id, sector) => async (dispatch, getState) => {
  const response = await TermsAndConditionsApi.put(id, sector);

  dispatch(setTermsAndConditions([response.data]));

  return response.data;
};

export const destroyTermsAndConditions = id => async (dispatch, getState) => {
  const response = await TermsAndConditionsApi.destroy(id);

  dispatch(removeTermsAndConditions([id]));

  return response.data;
};

export const setTermsAndConditionsError = message => ({
  type: 'SET_TERMS_AND_CONDITIONS_ERROR',
  message,
});

export const setTermsAndConditionsLoading = () => ({
  type: 'SET_TERMS_AND_CONDITIONS_LOADING',
});

export const setTermsAndConditions = termsAndConditions => ({
  type: 'SET_TERMS_AND_CONDITIONS',
  termsAndConditions,
});

export const removeTermsAndConditions = ids => ({
  type: 'REMOVE_TERMS_AND_CONDITIONS',
  ids,
});
