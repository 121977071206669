import {combineReducers} from 'redux';

/**
 * Reducer: Editable content data blocks.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {object}  action     The action to perform.
 * @return {object}             The new state.
 */
export const contentBySlug = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_CONTENT':
      return {...state, ...action.content};

    default:
      return state;
  }
};

/**
 * Reducer: State determining whether content is loading or not.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {object}  action     The action to perform.
 * @return {object}             The new state.
 */
export const loadingBySlug = (state = {}, action) => {
  switch (action.type) {
    // Tells us when we want to consider content as loading.
    case 'SET_CONTENT_LOADING':
      return action.loading.reduce((nextState, slug) => {
        if (!nextState.slug) {
          return {
            ...nextState,
            [slug]: true,
          };
        }

        return nextState;
      }, state);

    // Consider added content as no longer loading.
    case 'ADD_CONTENT':
      return Object.keys(action.content).reduce((nextState, slug) => {
        if (nextState.slug !== false) {
          return {
            ...nextState,
            [slug]: false,
          };
        }

        return nextState;
      }, state);

    // Consider errored content as no longer loading.
    case 'SET_CONTENT_ERRORS':
      return Object.keys(action.errors).reduce((nextState, slug) => {
        if (nextState.slug !== false) {
          return {
            ...nextState,
            [slug]: false,
          };
        }

        return nextState;
      }, state);

    default:
      return state;
  }
};

/**
 * Reducer: State determining whether content is saving or not.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {object}  action     The action to perform.
 * @return {object}             The new state.
 */
export const savingBySlug = (state = {}, action) => {
  switch (action.type) {
    // Tells us when we want to consider content as loading.
    case 'SET_CONTENT_SAVING':
      return action.saving.reduce((nextState, slug) => {
        if (!nextState.slug) {
          return {
            ...nextState,
            [slug]: true,
          };
        }

        return nextState;
      }, state);

    // Consider added content as no longer saving.
    case 'ADD_CONTENT':
      return Object.keys(action.content).reduce((nextState, slug) => {
        if (nextState.slug !== false) {
          return {
            ...nextState,
            [slug]: false,
          };
        }

        return nextState;
      }, state);

    // Consider errored content as no longer loading.
    case 'SET_CONTENT_ERRORS':
      return Object.keys(action.errors).reduce((nextState, slug) => {
        if (nextState.slug !== false) {
          return {
            ...nextState,
            [slug]: false,
          };
        }

        return nextState;
      }, state);

    default:
      return state;
  }
};

/**
 * Reducer: Setting error messages by URL slug.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {object}  action     The action to perform.
 * @return {object}             The new state.
 */
export const errorsBySlug = (state = {}, action) => {
  switch (action.type) {
    // Clear error messages when loading new content.
    case 'SET_CONTENT_LOADING':
      return action.loading.reduce((nextState, slug) => {
        if (slug in nextState) {
          // Using destructuring assigment to remove [slug] from the state immutably.
          const {[slug]: message, ...updatedState} = nextState;
          return updatedState;
        }

        return nextState;
      }, state);

    // Set error messages.
    case 'SET_CONTENT_ERRORS':
      return {...state, ...action.errors};

    default:
      return state;
  }
};

export default combineReducers({
  contentBySlug,
  loadingBySlug,
  savingBySlug,
  errorsBySlug,
});

const getDefaultSlugs = (state, slugs) => (Array.isArray(slugs) ? slugs : Object.keys(state.loadingBySlug));

/**
 * Selector: Gets content for a single given slug.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state.
 * @param  {string}  slug       The slug of the content we want to get.
 * @return {string}             The content.
 */
export const getContentBySlug = (state, slug) => state.contentBySlug[slug];

/**
 * Selector: Gets content for a set of slugs.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {array}   slugs      An array of content slugs we want to get.
 * @return {object}             slug => content mapping.
 */
export const getContentBySlugs = (state, slugs) =>
  slugs.reduce((nextContent, slug) => {
    const content = getContentBySlug(state, slug);

    if (content !== undefined) {
      return {
        ...nextContent,
        [slug]: content,
      };
    }

    return nextContent;
  }, {});

/**
 * Selector: Gets an error message for a single given slug.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state.
 * @param  {string}  slug       The slug of the error we want to get.
 * @return {string}             The error.
 */
export const getContentErrorBySlug = (state, slug) => state.errorsBySlug[slug];

/**
 * Selector: Gets an error message for a set of slugs.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state      The current state
 * @param  {array}   slugs      An array of slugs we want to get errors for.
 * @return {object}             slug => error mapping.
 */
export const getContentErrorBySlugs = (state, slugs) =>
  slugs.reduce((nextErrors, slug) => {
    const error = getContentErrorBySlug(state, slug);

    if (error !== undefined) {
      return {
        ...nextErrors,
        [slug]: error,
      };
    }

    return nextErrors;
  }, {});

/**
 * Selector: Gets content loading for a set of slugs (or if ANY content is loading)
 * Use when you want to determine whether even one of the content blocks are loading.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state        The current state
 * @param  {array}   slugs        An array of content slugs we want to get loading state for.
 * @return {bool}                 Whether any one of the given set of slugs is loading.
 */
export const getContentLoading = (state, slugs = null) => Boolean(getDefaultSlugs(state, slugs).find(slug => state.loadingBySlug[slug]));

/**
 * Selector: Gets content loading for a set of slugs, telling you which slugs
 * are, which aren't, and which have never started loading.
 *
 * Keys can be set to:
 * - `true`  currently loading
 * - `false` loaded or errored
 * - `null`  never requested
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state        The current state
 * @param  {array}   slugs        An array of content slugs we want to get loading state for.
 * @return {object}               Whether the given set of slugs is loading.
 */
export const getContentLoadingBySlugs = (state, slugs = null) =>
  getDefaultSlugs(state, slugs).reduce((nextLoading, slug) => {
    if (slug in state.loadingBySlug) {
      return {
        ...nextLoading,
        [slug]: state.loadingBySlug[slug] || null,
      };
    }

    return nextLoading;
  }, {});

/**
 * Selector: Gets content saving for a set of slugs (or if ANY content is saving)
 * Use when you want to determine whether even one of the content blocks are saving.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state        The current state
 * @param  {array}   slugs        An array of content slugs we want to get saving state for.
 * @return {bool}                 Whether any one of the given set of slugs is saving.
 */
export const getContentSaving = (state, slugs = null) => Boolean(getDefaultSlugs(state, slugs).find(slug => state.savingBySlug[slug]));

/**
 * Selector: Gets content saving for a set of slugs, telling you which slugs
 * are, which aren't, and which have never started saving.
 *
 * Keys can be set to:
 * - `true`  currently saving
 * - `false` saved or errored
 * - `null`  never saved
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {object}  state        The current state
 * @param  {array}   slugs        An array of content slugs we want to get saving state for.
 * @return {object}               Whether the given set of slugs is saving.
 */
export const getContentSavingBySlugs = (state, slugs = null) =>
  getDefaultSlugs(state, slugs).reduce((nextSaving, slug) => {
    if (slug in state.savingBySlug) {
      return {
        ...nextSaving,
        [slug]: state.savingBySlug[slug] || null,
      };
    }

    return nextSaving;
  }, {});
