import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    sort: 'order DESC,name',
    limit: 0,
    ...params,
  });

  return Service.get(`countries?${parsedParams}`, config);
};
