import React from 'react';
import {TextArea} from '@blueprintjs/core';
import Wrapper from './Wrapper';

export default class FormTextarea extends Wrapper {
  render() {
    const {classElement, fieldApi, label, className, value, ...rest} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        <TextArea
          fill
          growVertically
          className={this.getElementClass()}
          {...rest}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.getValue()}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
