import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    limit: 0,
    ...params,
  });

  return Service.get(`search?${parsedParams}`, config);
};

export const post = (data = {}, config = {}) => Service.post('search', data, config);

export const remove = (id, config = {}) => Service.delete(`search/${id}`, config);
