import {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useFlag} from 'react-unleash-flags';
import {ValidationError} from 'components/Legacy/CdForms';
import {getIsLoggedIn, getSessionAuthMessage, getSessionSubmitting, getSessionError, getSessionUserdata} from '../selectors';
import {doLogin, doLoginLegacy} from '../actionCreators';

const useSessionLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(getIsLoggedIn);
  const error = useSelector(getSessionError);
  const loading = useSelector(getSessionSubmitting);
  const userdata = useSelector(getSessionUserdata);
  const message = useSelector(getSessionAuthMessage);
  const [redirectTo, setRedirectTo] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [otpToken, setOtpToken] = useState('');
  const flag = useFlag('laravel_auth');

  const doLoginSubmit = useCallback(
    async ({username, password}, event, formApi) => {
      try {
        if (!flag?.enabled) {
          return dispatch(doLoginLegacy(username, password));
        }
        const authResponse = await dispatch(doLogin(username, password));
        if (authResponse.user_2fa_challenge_required) {
          setOtpRequired(authResponse.user_2fa_challenge_required);
          setOtpToken(authResponse.user_2fa_token);
        }
      } catch (e) {
        if (e instanceof ValidationError) {
          e.setErrors(formApi);
        }
      }
      return null;
    },
    [flag, dispatch]
  );

  useEffect(() => {
    if (loggedIn) {
      setRedirectTo(location?.state?.from ?? userdata?.redirect ?? '/');
    }
  }, [loggedIn, location, userdata]);

  return {
    loggedIn,
    error,
    loading,
    userdata,
    message,
    redirectTo,
    doLoginSubmit,
    otpRequired,
    otpToken,
  };
};

export default useSessionLogin;
