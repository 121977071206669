import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateSubscriberData} from '../actionCreators';

const useCandidateSaveSubscriberData = (candidateId, firmId) => {
  const dispatch = useDispatch();

  return useCallback(data => dispatch(updateCandidateSubscriberData(candidateId, firmId, data)), [dispatch, candidateId, firmId]);
};

export default useCandidateSaveSubscriberData;
