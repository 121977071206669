import {ServiceForbidden, ServiceNotFound} from 'api';

import * as QualificationsApi from './api';

export const fetchQualifications = params => async (dispatch, getState) => {
  dispatch(setQualificationsLoading());

  try {
    const response = await QualificationsApi.list(params);

    dispatch(setQualifications(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setQualifications([]));

      return null;
    }
    dispatch(setQualificationsError(e.message));
  }
  return [];
};

export const createQualification = qualification => async (dispatch, getState) => {
  const response = await QualificationsApi.post(qualification);

  dispatch(setQualifications([response.data]));

  return response.data;
};

export const saveQualification = (id, qualification) => async (dispatch, getState) => {
  const response = await QualificationsApi.put(id, qualification);

  dispatch(setQualifications([response.data]));

  return response.data;
};

export const destroyQualification = id => async (dispatch, getState) => {
  const response = await QualificationsApi.destroy(id);

  dispatch(removeQualifications([id]));

  return response.data;
};

export const setQualificationsError = message => ({
  type: 'SET_QUALIFICATIONS_ERROR',
  message,
});

export const setQualificationsLoading = () => ({
  type: 'SET_QUALIFICATIONS_LOADING',
});

export const setQualifications = qualifications => ({
  type: 'SET_QUALIFICATIONS',
  qualifications,
});

export const removeQualifications = ids => ({
  type: 'REMOVE_QUALIFICATIONS',
  ids,
});
