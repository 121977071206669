import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getOrganisations, getOrganisationsLoading, getOrganisationsError, getOrganisationsLoaded} from '../selectors';
import {fetchOrganisations} from '../actionCreators';

const useOrganisations = (forceReload = false) => {
  const organisations = useSelector(getOrganisations);
  const loaded = useSelector(getOrganisationsLoaded);
  const loading = useSelector(getOrganisationsLoading);
  const error = useSelector(getOrganisationsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchOrganisations());
    return () => null;
  }, [dispatch, loaded, forceReload]);

  return [organisations, loading, error];
};

export default useOrganisations;
