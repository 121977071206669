import * as UniversitiesApi from './api';

export const fetchUniversities = params => async (dispatch, getState) => {
  dispatch(setUniversitiesLoading());

  try {
    const response = await UniversitiesApi.list(params);

    dispatch(setUniversities(response.data));
    return response.data;
  } catch (e) {
    dispatch(setUniversitiesError(e.message));
    throw e;
  }
};

export const createUniversity = university => async (dispatch, getState) => {
  const response = await UniversitiesApi.post(university);

  dispatch(setUniversities([response.data]));

  return response.data;
};

export const saveUniversity = (id, university) => async (dispatch, getState) => {
  const response = await UniversitiesApi.put(id, university);

  dispatch(setUniversities([response.data]));

  return response.data;
};

export const destroyUniversity = id => async (dispatch, getState) => {
  const response = await UniversitiesApi.destroy(id);

  dispatch(removeUniversities([id]));

  return response.data;
};

export const setUniversitiesError = (message = '') => ({
  type: 'SET_UNIVERSITIES_ERROR',
  message,
});

export const setUniversitiesLoading = () => ({
  type: 'SET_UNIVERSITIES_LOADING',
});

export const setUniversities = universities => ({
  type: 'SET_UNIVERSITIES',
  universities,
});

export const removeUniversities = ids => ({
  type: 'REMOVE_UNIVERSITIES',
  ids,
});
