import React from 'react';
import Wrapper from './Wrapper';

export default class FormInput extends Wrapper {
  render() {
    const {classElement, required, fieldApi, label, className, id, value, checked, ...rest} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        <label htmlFor={id} className={this.getLabelClass()}>
          <input
            type="checkbox"
            className={this.getElementClass('checkbox')}
            value={value || true}
            id={id}
            {...rest}
            checked={!!this.getValue()}
            onClick={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          &nbsp;
          {label}
        </label>
        {this.renderMessage()}
      </div>
    );
  }
}
