import {ServiceForbidden, ServiceNotFound} from 'api';

import * as LanguagesApi from './api';
import {getLanguagesLoading} from './selectors';

export const fetchLanguages = params => async (dispatch, getState) => {
  if (getLanguagesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setLanguagesLoading());

  try {
    const response = await LanguagesApi.list(params);

    dispatch(setLanguages(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setLanguages([]));

      return null;
    }
    dispatch(setLanguagesError(e.message));
  }
  return null;
};

export const createLanguage = langauge => async (dispatch, getState) => {
  const response = await LanguagesApi.post(langauge);

  dispatch(setLanguages([response.data]));

  return response.data;
};

export const saveLanguage = (id, langauge) => async (dispatch, getState) => {
  const response = await LanguagesApi.put(id, langauge);

  dispatch(setLanguages([response.data]));

  return response.data;
};

export const destroyLanguage = id => async (dispatch, getState) => {
  const response = await LanguagesApi.destroy(id);

  dispatch(removeLanguages([id]));

  return response.data;
};

export const setLanguagesError = message => ({
  type: 'SET_LANGUAGES_ERROR',
  message,
});

export const setLanguagesLoading = () => ({
  type: 'SET_LANGUAGES_LOADING',
});

export const setLanguages = languages => ({
  type: 'SET_LANGUAGES',
  languages,
});

export const removeLanguages = ids => ({
  type: 'REMOVE_LANGUAGES',
  ids,
});
