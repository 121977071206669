import React from 'react';
import Wrapper from './Wrapper';

export default class FormInput extends Wrapper {
  render() {
    const {classElement, rules, required, fieldApi, label, className, value, children, ...rest} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        {/* TODO : add renderLabel() method from latest CD-Forms, meantime using .children props */}
        {children}
        <input
          className={this.getElementClass()}
          value={value ?? this.getValue()}
          {...rest}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
