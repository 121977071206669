import {useMemo} from 'react';

import {UsersApi} from 'features/users';

const usePermissionsSearchSelect = role =>
  useMemo(
    () =>
      role.map(name => ({
        id: name,
        search: UsersApi.getRoleName(name).toLowerCase(),
        text: UsersApi.getRoleName(name),
      })),
    [role]
  );

export default usePermissionsSearchSelect;
