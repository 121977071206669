import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateApproachCalendarDefaultValues = (approach = {}, candidateName, approachStatuses) => {
  const {due} = approach || {};

  return useMemo(() => {
    if (!approach || !Object.keys(approach).length) {
      return {};
    }

    const {role_brief: {role_team: roleTeam, role_title: roleTitle, role_location: roleLocation} = {}} = approach;
    const startsAt = dayjs(due).startOf('day').set('hour', 9);
    const approachStatus = approachStatuses?.find(status => status.id === approach.candidate_status);

    // seems to be legacy code so have no changed below to /liverole or /pipeline. Both will headings will select
    // eslint-disable-next-line max-len
    const link = `${window.location.origin}/role/${approach.scout_role_briefs_id}/recruit/recruit-list/${approach.scout_candidates_id}/details`;

    return {
      uid: `approach${approach.id}@insource.nz`,
      startsAt,
      endsAt: startsAt.set('minutes', 15),
      name: candidateName + (approachStatus?.name ? ` - ${approachStatus.name}` : ''),
      details: [roleTeam, roleTitle, roleLocation].filter(val => val).join(', '),
      link,
    };
  }, [approach, approachStatuses, candidateName, due]);
};

export default useCandidateApproachCalendarDefaultValues;
