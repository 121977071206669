import {combineReducers} from 'redux';

const universities = (state = [], action) => {
  switch (action.type) {
    case 'SET_UNIVERSITIES':
      if (action.universities) {
        return action.universities.reduce(
          (nextState, university) => ({
            ...nextState,
            [university.id]: university,
          }),
          state
        );
      }
      return state;
    case 'REMOVE_UNIVERSITIES':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextUniversities} = nextState;
        return nextUniversities;
      }, state);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_UNIVERSITIES_LOADING':
      return true;
    case 'SET_UNIVERSITIES':
    case 'SET_UNIVERSITIES_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_UNIVERSITIES':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_UNIVERSITIES_ERROR':
      return action.message;
    case 'SET_UNIVERSITIES_LOADING':
    case 'SET_UNIVERSITIES':
      return false;
    default:
      return state;
  }
};

export default combineReducers({universities, loading, loaded, error});
