import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateProfile} from '../actionCreators';

const useCandidateMarkDuplicate = (candidateId, duplicateOfId) => {
  const dispatch = useDispatch();

  return useCallback(
    data => dispatch(updateCandidateProfile(candidateId, {status: 'duplicate', duplicate_of: duplicateOfId})),
    [dispatch, candidateId, duplicateOfId]
  );
};

export default useCandidateMarkDuplicate;
