import * as UsersApi from './api';
import {getUsersLoading} from './selectors';

export const fetchUser = userId => async (dispatch, getState) => {
  if (getUsersLoading(getState())) return null;

  dispatch(setUserLoading());

  try {
    const response = await UsersApi.getDetail(userId);

    const [user] = response.data;

    // Loop the data, and add a user for each response.
    dispatch(setUser(user));

    return user;
  } catch (e) {
    console.error(e);
    dispatch(setUserError(e));
    return {};
  }
};

export const fetchUsers = params => async (dispatch, getState) => {
  if (getUsersLoading(getState())) return null;

  dispatch(setUserLoading());

  try {
    const response = await UsersApi.list(params);

    dispatch(setUsers(response.data));

    return response.data;
  } catch (e) {
    console.error(e, e.error);
    dispatch(setUserError(e));
    return [];
  }
};

export const fetchStaff = () => fetchUsers({role: 'Admin||Staff||Researcher'});

export const fetchOrganisationUsers = firmIds => async (dispatch, getState) => {
  if (getUsersLoading(getState())) return null;

  dispatch(setUserLoading());

  try {
    const response = await UsersApi.list({filter: {'firms.id': firmIds}});

    dispatch(setUsers(response.data));

    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(setUserError(e));
    return [];
  }
};

export const sendActivationEmail = userId => async (dispatch, getState) => {
  const response = await UsersApi.resendActivation(userId);

  return response.data;
};

export const createUser = userdata => async (dispatch, getState) => {
  dispatch(setUserSaving());

  const response = await UsersApi.post(userdata);

  await dispatch(setUser(response.data));

  return response.data;
};

export const saveUser = (id, userdata) => async (dispatch, getState) => {
  dispatch(setUserSaving());

  const response = await UsersApi.put(id, userdata);

  dispatch(setUser(response.data));

  return response.data;
};

/**
 * Intended for use by admin users to reset a lower users password.
 * The API will reject any instances where this can't be done.
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @param  {String}  id                    The user ID to use when setting the password
 * @param  {String}  password              The password to set.
 * @param  {String}  password_confirmation The confirmed password.
 * @return {Promise}                       A promise.
 */
// eslint-disable-next-line camelcase
export const saveUserPassword = (id, password, password_confirmation) => async (dispatch, getState) => {
  const response = await UsersApi.put(id, {
    password,
    password_confirmation,
  });

  return response.data;
};

// eslint-disable-next-line camelcase
export const disableUser = id => async (dispatch, getState) => {
  dispatch(setUserSaving());

  const response = await UsersApi.disable(id);

  dispatch(removeUser(id));

  return response.data;
};

export const setUsers = users => ({
  type: 'SET_USERS',
  users,
});

export const removeUsers = ids => ({
  type: 'REMOVE_USERS',
  ids,
});

export const setUser = user => setUsers([user]);

export const removeUser = id => removeUsers([id]);

export const setUserError = e => ({
  type: 'SET_USER_ERROR',
  exception: e.constructor ? e.constructor.name : null,
  message: e.message,
});

export const setUserLoading = () => ({
  type: 'SET_USER_LOADING',
});

export const setUserSaving = () => ({
  type: 'SET_USER_SAVING',
});
