import content, * as fromContent from './content';
import ui, * as fromUi from './ui';

const reducers = {
  content,
  ui,
};

export default reducers;

// UI selectors
export const UI = {
  getClientFilter: state => fromUi.getClientFilter(state.ui),
};

// Content blocks
export const getContentBySlug = (state, slug) => fromContent.getContentBySlug(state.content, slug);
export const getContentBySlugs = (state, slugs) => fromContent.getContentBySlugs(state.content, slugs);
export const getContentErrorBySlug = (state, slug) => fromContent.getContentErrorBySlug(state.content, slug);
export const getContentErrorBySlugs = (state, slugs) => fromContent.getContentErrorBySlugs(state.content, slugs);
export const getContentLoading = (state, slugs) => fromContent.getContentLoading(state.content, slugs);
export const getContentLoadingBySlugs = (state, slugs) => fromContent.getContentLoadingBySlugs(state.content, slugs);
export const getContentSaving = (state, slugs) => fromContent.getContentSaving(state.content, slugs);
export const getContentSavingBySlugs = (state, slugs) => fromContent.getContentSavingBySlugs(state.content, slugs);
