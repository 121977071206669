import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

import {useSession} from 'features/session';
import {Loading, LoadingLayout} from 'components/Loading';
import {Header} from 'components/Header';
import {Analytics} from 'components/Analytics';

const FullLayout = ({component: Component, ...rest}) => {
  const [loading, isLoggedIn, userdata, shouldLoad] = useSession();

  if (loading && shouldLoad) return <Loading />;
  if (loading) return <LoadingLayout />;

  return (
    <Route
      {...rest}
      render={props => (
        <React.Fragment>
          {isLoggedIn && <Header />}
          <Analytics debug id={process.env.REACT_APP_GOOGLE_ANALYTICS_ID} userId={userdata.id} trackPathnameOnly />
          <Component {...rest} />
        </React.Fragment>
      )}
    />
  );
};

FullLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
};

export default FullLayout;
