import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveWorkmix, createWorkmix, destroyWorkmix, detachWorkmix} from '../actionCreators';

const useWorkmixActions = id => {
  const dispatch = useDispatch();

  const onSaveWorkmix = useCallback(
    // This must be async so the form submit knows to wait.
    async values => {
      if (id) {
        await dispatch(saveWorkmix(id, values));
      } else {
        await dispatch(createWorkmix(values));
      }
    },
    [id, dispatch]
  );
  const onDestroyWorkmix = useCallback(() => (id ? dispatch(destroyWorkmix(id)) : null), [id, dispatch]);
  const onDetachWorkmix = useCallback(areaOfExpertiseId => (id ? dispatch(detachWorkmix(id, areaOfExpertiseId)) : null), [id, dispatch]);

  return {
    onSaveWorkmix,
    onDestroyWorkmix,
    onDetachWorkmix,
  };
};

export default useWorkmixActions;
