import * as SearchSavedApi from './api/SearchSavedApi';

export const fetchSavedSearch = (type, roleId, list) => async (dispatch, getState) => {
  dispatch(setSavedSearchLoading());

  try {
    const response = await SearchSavedApi.list({filter: {type, role_id: roleId, list}});
    dispatch(setSavedSearch(response.data));
    return response.data;
  } catch (e) {
    dispatch(setSavedSearchError(e.message));
    throw e;
  }
};

export const addSavedSearch = search => async (dispatch, getState) => {
  const response = await SearchSavedApi.post(search);

  dispatch(setSavedSearch([response.data]));
  return response.data;
};

export const setSavedSearchDeleted = id => async (dispatch, getState) => {
  await SearchSavedApi.remove(id);

  dispatch(removeSavedSearch(id));
};

export const setSavedSearchError = message => ({
  type: 'SET_SAVED_SEARCH_ERROR',
  message,
});

export const removeSavedSearch = id => removeSavedSearches([id]);

export const removeSavedSearches = ids => ({
  type: 'REMOVE_SAVED_SEARCH',
  ids,
});

export const setSavedSearchLoading = () => ({
  type: 'SET_SAVED_SEARCH_LOADING',
});

export const setSavedSearch = search => ({
  type: 'SET_SAVED_SEARCH',
  search,
});
