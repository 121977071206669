import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getSessionFirmIds} from 'features/session/selectors';
import {createNewFirmUser} from 'features/organisations/actionCreators';

import {createUser} from '../actionCreators';

const useOnCreateUser = () => {
  const [firmId] = useSelector(getSessionFirmIds);
  const dispatch = useDispatch();

  return useCallback(
    ({roles, ...values}) => {
      const userdata = {
        ...values,
        roles,
      };

      if (!firmId || (roles?.includes('Researcher') && roles.length === 1)) {
        return dispatch(createUser(userdata));
      }
      return dispatch(createNewFirmUser(firmId, userdata));
    },
    [dispatch, firmId]
  );
};

export default useOnCreateUser;
