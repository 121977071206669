/* eslint-disable camelcase */
import {useMemo} from 'react';

const useWorkmixDefaultValues = (workmix = {}) => {
  const {id, name, description, search, areas_of_expertise} = workmix || {};

  return useMemo(() => {
    if (!workmix || !Object.keys(workmix).length) {
      return {};
    }

    return {
      id,
      name,
      description,
      search,
      areas_of_expertise,
    };
  }, [workmix, id, name, description, search, areas_of_expertise]);
};

export default useWorkmixDefaultValues;
