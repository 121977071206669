import Service, {parseParams} from 'api';

export const get = (id, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`candidates/${id}?${parsedParams}`, config);
};

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`candidates?${parsedParams}`, config);
};

export const post = (data = {}, config) => Service.post(`candidates`, data, config);
export const put = (id, data = {}, config) => Service.put(`candidates/${id}`, data, config);
export const destroy = (id, config) => Service.delete(`candidates/${id}`, config);

export const putInsights = (id, data = {}, config) => Service.put(`candidates/${id}/insights`, data, config);
export const putSubscriberData = (id, firmId, data = {}, config) => Service.put(`candidates/${id}/subscriber_data/${firmId}`, data, config);
export const putList = (id, roleId, data = {}, config) => Service.put(`candidates/${id}/lists/${roleId}`, data, config);

export const listNotes = (candidateId, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`candidates/${candidateId}/notes?${parsedParams}`, config);
};

export const listRoleNotes = (candidateId, roleId, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`candidates/${candidateId}/lists/${roleId}/notes?${parsedParams}`, config);
};

export const postNotes = (candidateId, data = {}, config) => Service.post(`candidates/${candidateId}/notes`, data, config);

export const destroyNote = (candidateId, noteId, config) => Service.delete(`candidates/${candidateId}/notes/${noteId}`, config);

export const destroyAdmission = (candidateId, admissionId, config) =>
  Service.delete(`candidates/${candidateId}/admissions/${admissionId}`, config);

export const destroyPosition = (candidateId, positionId, config) =>
  Service.delete(`candidates/${candidateId}/positions/${positionId}`, config);

export const destroyEducation = (candidateId, educationId, config) =>
  Service.delete(`candidates/${candidateId}/educations/${educationId}`, config);

export const rejectSuggestion = (candidateId, suggestionId, config) =>
  Service.delete(`candidates/${candidateId}/suggestions/${suggestionId}`, config);

export const acceptSuggestion = (candidateId, suggestionId, config) =>
  Service.put(`candidates/${candidateId}/suggestions/${suggestionId}`, {status: 'accepted'}, config);

export const makeSuggestion = (candidateId, suggestion, config) =>
  Service.post(`candidates/${candidateId}/suggestions`, suggestion, config);
