import {useMemo} from 'react';

const useOrganisationSearchSelect = firms =>
  useMemo(
    () =>
      firms
        .filter(firm => !firm.is_hidden)
        .map(({id, name}) => ({
          id,
          search: name.toLowerCase(),
          text: name,
        })),
    [firms]
  );

export default useOrganisationSearchSelect;
