import {ServiceForbidden, ServiceNotFound} from 'api';

import * as SectorsApi from './api';

export const fetchSectors = params => async (dispatch, getState) => {
  dispatch(setSectorsLoading());

  try {
    const response = await SectorsApi.list(params);

    dispatch(setSectors(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setSectors([]));

      return null;
    }
    dispatch(setSectorsError(e.message));
  }
  return [];
};

export const createSector = sector => async (dispatch, getState) => {
  const response = await SectorsApi.post(sector);

  dispatch(setSectors([response.data]));

  return response.data;
};

export const saveSector = (id, sector) => async (dispatch, getState) => {
  const response = await SectorsApi.put(id, sector);

  dispatch(setSectors([response.data]));

  return response.data;
};

export const destroySector = id => async (dispatch, getState) => {
  const response = await SectorsApi.destroy(id);

  dispatch(removeSectors([id]));

  return response.data;
};

export const setSectorsError = message => ({
  type: 'SET_SECTORS_ERROR',
  message,
});

export const setSectorsLoading = () => ({
  type: 'SET_SECTORS_LOADING',
});

export const setSectors = sectors => ({
  type: 'SET_SECTORS',
  sectors,
});

export const removeSectors = ids => ({
  type: 'REMOVE_SECTORS',
  ids,
});
