import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateRegistrationLegacy} from '../actionCreators';

const useCandidateCertificateActions = (candidateId, certificateId) => {
  const dispatch = useDispatch();

  const onSaveCertificate = useCallback(
    registration =>
      dispatch(
        updateCandidateRegistrationLegacy({
          id: candidateId,
          registration,
        })
      ),
    [candidateId, dispatch]
  );

  const onDeleteCertificate = useCallback(
    id =>
      dispatch(
        updateCandidateRegistrationLegacy({
          id: candidateId,
          registration: {
            id: certificateId,
            status: 'deleted',
          },
        })
      ),
    [candidateId, certificateId, dispatch]
  );

  return {
    onSaveCertificate,
    onDeleteCertificate,
  };
};

export default useCandidateCertificateActions;
