import {combineReducers} from 'redux';

const qualifications = (state = [], action) => {
  switch (action.type) {
    case 'SET_QUALIFICATIONS':
      return action.qualifications.reduce(
        (nextState, qualification) => ({
          ...nextState,
          [qualification.id]: qualification,
        }),
        state
      );
    case 'REMOVE_QUALIFICATIONS':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextQualifications} = nextState;
        return nextQualifications;
      }, state);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_QUALIFICATIONS_LOADING':
      return true;
    case 'SET_QUALIFICATIONS':
    case 'SET_QUALIFICATIONS_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_QUALIFICATIONS':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_QUALIFICATIONS_ERROR':
      return action.message;
    case 'SET_QUALIFICATIONS_LOADING':
    case 'SET_QUALIFICATIONS':
      return false;
    default:
      return state;
  }
};

export default combineReducers({qualifications, loading, loaded, error});
