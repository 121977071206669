import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams(params);

  return Service.get(`preferences?${parsedParams}`, config);
};

export const post = (data = {}, config = {}) => Service.post('preferences', data, config);
export const put = (id, data = {}, config = {}) => Service.put(`preferences/${id}`, data, config);
export const otp = (params = {}, config) => {
  const parsedParams = parseParams(params);
  return Service.get(`preferences/2fa?${parsedParams}`, config);
};
