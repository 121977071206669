import {withField} from 'react-form';

import {ValidationError, withValidation} from './validate';
import Form from './Form';
import CaptchaForm from './CaptchaForm';
import FormInput from './Input';
import FormEmail from './Email';
import FormSecret from './Secret';
import FormTextarea from './Textarea';
// import FormSelect from './Select';
import FormCheckbox from './Checkbox';
import Wrapper from './Wrapper';
import FormEditor from './Editor';
import FormQuill from './Quill';
import FormDatePicker from './DatePicker';
import FormDateRangePicker from './DateRangePicker';
import FormRangeSlider from './RangeSlider';
import FormUploader from './Uploader';
// import FormRadioImage from './RadioImage'
import FormAddress from './Address';
// import FormCardNumber from './card/Number'
// import FormCardExpiry from './card/Expiry'
// import FormCardCVC from './card/CVC'
import Message from './Message';
import Stepper from './Stepper';
import PopoverArchive from './PopoverArchive';

import FormNumberFormat from './NumberFormat';

export * from './PropTypesForm';

const Input = withValidation(withField(FormInput), FormInput.validationRules);
const Email = withValidation(withField(FormEmail), FormEmail.validationRules);
const Secret = withValidation(withField(FormSecret), FormSecret.validationRules);
const Textarea = withValidation(withField(FormTextarea), FormTextarea.validationRules);
// const Select = withValidation(withField(FormSelect), FormSelect.validationRules);
const Checkbox = withValidation(withField(FormCheckbox), FormCheckbox.validationRules);
const Editor = withValidation(withField(FormEditor), FormEditor.validationRules);
const Quill = withValidation(withField(FormQuill), FormQuill.validationRules);
const DatePicker = withValidation(withField(FormDatePicker), FormDatePicker.validationRules);
const DateRangePicker = withValidation(withField(FormDateRangePicker), FormDateRangePicker.validationRules);
const RangeSlider = withValidation(withField(FormRangeSlider), FormRangeSlider.validationRules);
const Uploader = withValidation(withField(FormUploader), FormUploader.validationRules);
const NumberFormat = withValidation(withField(FormNumberFormat), FormNumberFormat.validationRules);
// const RadioImage = withValidation(withField(FormRadioImage))
const Address = withValidation(withField(FormAddress));
// const CardNumber = withValidation(withField(FormCardNumber))
// const CardExpiry = withValidation(withField(FormCardExpiry))
// const CardCVC = withValidation(withField(FormCardCVC))
const ArchivePopover = withValidation(withField(PopoverArchive), PopoverArchive.validationRules);

export {
  ValidationError,
  withField,
  withValidation,
  Wrapper,
  Form,
  CaptchaForm,
  Input,
  Email,
  Secret,
  Textarea,
  // Select,
  Checkbox,
  Editor,
  Quill,
  DatePicker,
  DateRangePicker,
  RangeSlider,
  // RadioImage,
  Message,
  Stepper,
  Uploader,
  NumberFormat,
  Address,
  // CardNumber,
  // CardExpiry,
  // CardCVC
  ArchivePopover,
};
