import {combineReducers} from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PIPELINES':
      return action.pipelines.reduce(
        (nextPipeline, pipeline) => ({
          ...nextPipeline,
          [pipeline.id]: {...state[pipeline.id], ...pipeline},
        }),
        {}
      );

    case 'UPDATE_PIPELINES':
      return action.pipelines.reduce(
        (nextPipeline, pipeline) => ({
          ...nextPipeline,
          [pipeline.id]: {...nextPipeline[pipeline.id], ...pipeline},
        }),
        {...state}
      );

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'SET_PIPELINES':
      return action.pipelines.map(pipeline => pipeline.id);

    case 'UPDATE_PIPELINES':
      return [...new Set([...state, ...action.pipelines.map(pipeline => pipeline.id)])];

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_PIPELINES_LOADING':
      return true;
    case 'SET_PIPELINES':
    case 'UPDATE_PIPELINES':
    case 'SET_PIPELINES_ERROR':
      return false;
    default:
      return state;
  }
};
const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_PIPELINES':
    case 'UPDATE_PIPELINES':
      return Date.now();
    default:
      return state;
  }
};

const saving = (state = false, action) => {
  switch (action.type) {
    case 'SET_PIPELINES_SAVING':
      return true;
    case 'SET_PIPELINES':
    case 'UPDATE_PIPELINES':
    case 'SET_PIPELINES_ERROR':
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_PIPELINES_LOADING':
    case 'SET_PIPELINES_SAVING':
    case 'SET_PIPELINES':
    case 'UPDATE_PIPELINES':
      return false;
    case 'SET_PIPELINES_ERROR':
      return action.message;
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  loading,
  loaded,
  saving,
  error,
});
