import {ServiceForbidden, ServiceNotFound} from 'api';

import * as AreaOfExpertiseApi from '../api';
import {getAreasOfExpertiseLoading} from '../selectors';

import setAreaOfExpertiseLoading from './setAreaOfExpertiseLoading';
import setAreasOfExpertise from './setAreasOfExpertise';
import setAreaOfExpertiseError from './setAreaOfExpertiseError';

const fetchAreasOfExpertise = () => async (dispatch, getState) => {
  if (getAreasOfExpertiseLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setAreaOfExpertiseLoading());

  try {
    const response = await AreaOfExpertiseApi.list();

    dispatch(setAreasOfExpertise(response.data));
    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setAreasOfExpertise([]));

      return null;
    }
    dispatch(setAreaOfExpertiseError(e.message));
  }
  return null;
};

export default fetchAreasOfExpertise;
