import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getPipelinesLoading, getPipelinesLoaded, getPipelinesFilteredBy, getPipelinesError, sortPipelinesByDefault} from '../selectors';
import {fetchPipelines} from '../actionCreators';

const filterPipelinesBy = filters => state => getPipelinesFilteredBy(state, filters, sortPipelinesByDefault);

const defaultFilters = {status: ['active', 'approach']};
const defaultParams = {filter: {status: 'active||approach'}};

const usePipelines = (filters = defaultFilters, params = defaultParams) => {
  const pipelines = useSelector(filterPipelinesBy(filters));
  const loaded = useSelector(getPipelinesLoaded);
  const loading = useSelector(getPipelinesLoading);
  const error = useSelector(getPipelinesError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPipelines(params));
    return () => null;
  }, [dispatch, params]);

  return [pipelines, loading, error, loaded];
};

export default usePipelines;
