import {combineReducers} from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LIVEROLES':
      return action.liveroles.reduce(
        (nextLiveRole, liveRole) => ({
          ...nextLiveRole,
          [liveRole.id]: {...state[liveRole.id], ...liveRole},
        }),
        {}
      );

    case 'UPDATE_LIVEROLES':
      return action.liveroles.reduce(
        (nextLiveRole, liveRole) => ({
          ...nextLiveRole,
          [liveRole.id]: {...nextLiveRole[liveRole.id], ...liveRole},
        }),
        {...state}
      );

    case 'HIDE_LIVEROLE':
      // eslint-disable-next-line no-case-declarations, no-unused-vars, fp/no-delete, no-param-reassign
      const {[action.liveRoleId]: selectedId = 0, ...rest} = state;
      return state;

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIVEROLES':
      return action.liveroles.map(liveRole => liveRole.id);

    case 'UPDATE_LIVEROLES':
      return [...new Set([...state, ...action.liveroles.map(liveRole => liveRole.id)])];

    case 'HIDE_LIVEROLE':
      return state.filter(x => x !== action.liveRoleId);

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_LIVEROLES_LOADING':
      return true;
    case 'SET_LIVEROLES':
    case 'UPDATE_LIVEROLES':
    case 'HIDE_LIVEROLE':
      return false;
    default:
      return state;
  }
};
const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_LIVEROLES':
    case 'UPDATE_LIVEROLES':
      return Date.now();
    default:
      return state;
  }
};

const saving = (state = false, action) => {
  switch (action.type) {
    case 'SET_LIVEROLES_SAVING':
      return true;
    case 'SET_LIVEROLES':
    case 'UPDATE_LIVEROLES':
    case 'SET_LIVEROLES_ERROR':
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_LIVEROLES_LOADING':
    case 'SET_LIVEROLES_SAVING':
    case 'SET_LIVEROLES':
    case 'UPDATE_LIVEROLES':
      return false;
    case 'SET_LIVEROLES_ERROR':
      return action.message;
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  loading,
  loaded,
  saving,
  error,
});
