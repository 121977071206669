import {ValidationError} from 'components/Legacy/CdForms';

import {ServiceNotFound} from 'api';
import {fetchUser, saveUser, removeUser} from 'features/users/actionCreators';
import * as UsersApi from 'features/users/api';

import * as OrganisationsApi from './api';
import {getOrganisationsLoading, getFirmsLoading} from './selectors';

export const fetchOrganisations = () => async (dispatch, getState) => {
  if (getOrganisationsLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setOrganisationsLoading());

  try {
    const response = await OrganisationsApi.getOrganisations();
    dispatch(setOrganisationsLoaded());
    dispatch(setOrganisations(response.data));
    return response.data;
  } catch (error) {
    dispatch(setOrganisationsError(error.message));
    throw error;
  }
};

export const fetchFirms = () => async (dispatch, getState) => {
  if (getFirmsLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setFirmsLoading());

  try {
    const response = await OrganisationsApi.getFirms();
    dispatch(setFirmsLoaded());
    dispatch(setFirms(response.data));
    return response.data;
  } catch (error) {
    dispatch(setFirmsError(error.message));
    throw error;
  }
};

export const fetchSubscribers = () => async (dispatch, getState) => {
  dispatch(setSubscribersLoading());

  try {
    const response = await OrganisationsApi.getSubscribers();
    dispatch(setSubscribersLoaded());
    dispatch(setSubscribers(response.data));
    return response.data;
  } catch (error) {
    dispatch(setSubscribersError(error.message));
    throw error;
  }
};

export const addNewFirm =
  (name, is_subscriber = 0) =>
  async (dispatch, getState) => {
    const firm =
      name && typeof name === 'object'
        ? {
            is_subscriber,
            ...name,
          }
        : {
            name,
            is_subscriber,
          };

    dispatch(setOrganisationsSaving());

    try {
      const response = await OrganisationsApi.post(firm);

      dispatch(setFirm({...firm, ...response.data}));

      return response.data;
    } catch (e) {
      dispatch(setOrganisationsError(e.message));
      throw e;
    }
  };

export const subscribeFirm = (id, email) => async (dispatch, getState) => {
  dispatch(setOrganisationsSaving());

  try {
    await OrganisationsApi.subscribe(id, email);

    dispatch(
      updateFirm({
        id,
        email,
        is_subscriber: 1,
      })
    );
  } catch (e) {
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const unsubscribeFirm = id => async (dispatch, getState) => {
  dispatch(setOrganisationsSaving());

  try {
    await OrganisationsApi.unsubscribe(id);

    dispatch(
      updateFirm({
        id,
        is_subscriber: 0,
      })
    );
  } catch (e) {
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const saveFirm = (id, firm) => async (dispatch, getState) => {
  dispatch(setOrganisationsSaving());

  try {
    const response = await OrganisationsApi.put(id, firm);

    dispatch(updateFirm(response.data));

    return response.data;
  } catch (e) {
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const deleteFirm = id => async (dispatch, getState) => {
  dispatch(setOrganisationsSaving());

  try {
    const response = await OrganisationsApi.remove(id);

    dispatch(removeFirm(id));

    return response.data;
  } catch (e) {
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const fetchOrganisationDetailLite =
  (organisationIds = [], where = {}) =>
  (dispatch, getState) => {
    const organisationIdChunks = [];
    const chunkSize = 500;

    // In order to avoid abortive requests due to URLs being too long,
    // we chunk ID requests up to only fetch 500 max per request.
    // Note this trigger multiple requests, but it's unlikely that we'll
    // saturate the server if we're sensible about what we're requesting.
    /* eslint-disable fp/no-mutating-methods */
    /* eslint-disable fp/no-mutation */
    for (let i = 0, len = organisationIds.length; i < len; i += chunkSize) {
      organisationIdChunks.push(organisationIds.slice(i, i + chunkSize));
    }
    /* eslint-enable fp/no-mutating-methods */
    /* eslint-enable fp/no-mutation */

    return Promise.all(
      organisationIdChunks.map(chunkIds =>
        OrganisationsApi.getDetail({
          include: 'users',
          filter: {
            id: chunkIds ? chunkIds.join('||') : null,
            ...where,
          },
        })
          .then(response => {
            dispatch(updateFirms(response.data));
          })
          .catch(e => {
            console.error(e);

            // Handle 404 responses within this catch handler.
            // API code 2013 represents a "not found" error as we don't have access to the actual response.
            if (e instanceof ServiceNotFound) {
              dispatch(updateFirms([]));
            } else {
              dispatch(setOrganisationsError());
            }
          })
      )
    );
  };

export const fetchOrganisationDetail =
  (organisationIds = [], where = {}) =>
  (dispatch, getState) => {
    const organisationIdChunks = [];
    const chunkSize = 500;

    // In order to avoid abortive requests due to URLs being too long,
    // we chunk ID requests up to only fetch 500 max per request.
    // Note this trigger multiple requests, but it's unlikely that we'll
    // saturate the server if we're sensible about what we're requesting.
    /* eslint-disable fp/no-mutating-methods */
    /* eslint-disable fp/no-mutation */
    for (let i = 0, len = organisationIds.length; i < len; i += chunkSize) {
      organisationIdChunks.push(organisationIds.slice(i, i + chunkSize));
    }
    /* eslint-enable fp/no-mutating-methods */
    /* eslint-enable fp/no-mutation */

    return Promise.all(
      organisationIdChunks.map(chunkIds =>
        OrganisationsApi.getDetail({
          include: 'users,candidates,candidate_suggestions',
          filter: {
            id: chunkIds ? chunkIds.join('||') : null,
            ...where,
          },
        })
          .then(response => {
            dispatch(updateFirms(response.data));
          })
          .catch(e => {
            console.error(e);

            // Handle 404 responses within this catch handler.
            // API code 2013 represents a "not found" error as we don't have access to the actual response.
            if (e instanceof ServiceNotFound) {
              dispatch(updateFirms([]));
            } else {
              dispatch(setOrganisationsError());
            }
          })
      )
    );
  };

/* eslint-disable camelcase */
export const createNewFirmUser =
  (organisationId, {scout_candidates_id, ...userdata}) =>
  /* eslint-enable camelcase */
  async (dispatch, getState) => {
    dispatch(setOrganisationsSaving());

    try {
      const response = await UsersApi.post({
        username: userdata.email,
        firms: [organisationId],
        ...userdata,
      });

      dispatch(fetchOrganisationDetail([organisationId]));
      dispatch(fetchUser(response.data.id));

      return response.data;
    } catch (e) {
      if (e instanceof ValidationError) {
        const errors = e.getErrors();
        if (errors.email && errors.email.indexOf('The email has already been taken.') === 0) {
          console.log('NEED TO ASSIGN THIS USER :::');
        }
      }

      console.error(e);
      dispatch(setOrganisationsError(e.message));
      throw e;
    }
  };

export const saveFirmUser = (organisationId, userId, userdata) => async (dispatch, getState) => {
  try {
    const result = await dispatch(saveUser(userId, userdata));

    dispatch(fetchOrganisationDetail([organisationId]));
    dispatch(fetchUser(userId));

    return result;
  } catch (e) {
    console.error(e);
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const removeFirmUser = (organisationId, userId) => async (dispatch, getState) => {
  dispatch(setOrganisationsSaving());

  try {
    const response = await OrganisationsApi.removeContact(organisationId, userId);

    dispatch(fetchOrganisationDetail([organisationId]));
    dispatch(removeUser(userId));

    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(setOrganisationsError(e.message));
    throw e;
  }
};

export const setFirm = firm => setOrganisations([firm]);
export const updateFirm = firm => updateFirms([firm]);
export const removeFirm = id => removeFirms([id]);

export const setSubscribersLoading = () => ({
  type: 'SET_SUBSCRIBERS_LOADING',
});

export const setFirmsLoading = () => ({
  type: 'SET_FIRMS_LOADING',
});

export const setFirmsLoaded = () => ({
  type: 'SET_FIRMS_LOADED',
});

export const setCompaniesLoaded = () => ({
  type: 'SET_COMPANIES_LOADED',
});

export const setSubscribersLoaded = () => ({
  type: 'SET_SUBSCRIBERS_LOADED',
});

export const setOrganisationsLoaded = () => ({
  type: 'SET_ORGANISATIONS_LOADED',
});

export const setOrganisationsSaving = () => ({
  type: 'SET_ORGANISATIONS_SAVING',
});

export const setOrganisationsLoading = () => ({
  type: 'SET_ORGANISATIONS_LOADING',
});

export const setOrganisations = firms => ({
  type: 'SET_ORGANISATIONS',
  firms,
});

export const setSubscribers = firms => ({
  type: 'SET_SUBSCRIBERS',
  firms,
});

export const setFirms = firms => ({
  type: 'SET_FIRMS',
  firms,
});

export const addFirm = firm => ({
  type: 'ADD_ORGANISATION',
  firm,
});

export const updateFirms = firms => ({
  type: 'UPDATE_ORGANISATIONS',
  firms,
});

export const removeFirms = ids => ({
  type: 'REMOVE_ORGANISATIONS',
  ids,
});
export const setOrganisationsError = (message = 'Sorry, there was an unexpected error') => ({
  type: 'SET_ORGANISATIONS_ERROR',
  message,
});
export const setFirmsError = (message = 'Sorry, there was an unexpected error') => ({
  type: 'SET_FIRMS_ERROR',
  message,
});
export const setSubscribersError = (message = 'Sorry, there was an unexpected error') => ({
  type: 'SET_SUBSCRIBERS_ERROR',
  message,
});
