import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesFieldApi } from './PropTypesForm';

const Input = ({
    handleChange,
    message,
    placeholder

}) => {
    return (
        <input
            className='inputClass'
            type="text"
            id="message"
            name="message"
            onChange={handleChange}
            value={message}
            placeholder={placeholder}
        />
    )
};

Input.propTypes = {
    handleChange: PropTypes.func.isRequired,
    message: PropTypes.func.isRequired,
    placeholder: PropTypes.func.isRequired,
}

const ConditionalInput = ({
    isChecked,
    onclick,
    label,

}) => {
    return (
        <label className="popover-container" onClick={onclick} style={{color: isChecked ? "#2196F3" : "inherit"}}>{label}
            {isChecked ? (
                <input type="radio" name="radio" checked/>
            ) : (
                <input type="radio" name="radio"/>
            )}
            <span className="checkmark"></span>
        </label>
    )
};

ConditionalInput.propTypes = {
    isChecked: PropTypes.bool,
    onclick: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
}

ConditionalInput.propTypes = {
    isChecked: false,
}

const PopoverInterior = ({
    fieldApi,
    messageOne,
    messageTwo,
    messageThree,
    messageFour,
    messageFive,
    buttonOne,
    buttonTwo,
    buttonThree,
    buttonFour,
    buttonFive,
    handleButtonOneChange,
    handleButtonTwoChange,
    handleButtonThreeChange,
    handleButtonFourChange,
    handleButtonFiveChange,
    handleChangeInputOne,
    handleChangeInputTwo,
    handleChangeInputThree,
    handleChangeInputFour,
    handleChangeInputFive,
    isReason,
    isChannel,
    isType,
    checkboxLabelOne,
    checkboxLabelTwo,
    checkboxLabelThree,
    checkboxLabelFour,
    checkboxLabelFive,
    inputPlaceholderOne,
    inputPlaceholderTwo,
    inputPlaceholderThree,
    inputPlaceholderFour,
    inputPlaceholderFive,
}) => {
    return (
        <div className='inputBox' style={{borderTopStyle: "solid", borderTopWidth: "1px", borderTopRightRadius: 5, borderTopLeftRadius: 5}}>
            <ConditionalInput isChecked={buttonOne} onclick={handleButtonOneChange} label={checkboxLabelOne(isReason, isChannel, isType)}/>
            {isType && !!buttonOne && ( <Input handleChange={handleChangeInputOne} message={messageOne} placeholder={inputPlaceholderOne}/> )}
            <ConditionalInput isChecked={buttonTwo} onclick={handleButtonTwoChange} label={checkboxLabelTwo(isReason, isChannel, isType)}/>
            {isChannel && !!buttonTwo && ( <Input handleChange={handleChangeInputTwo} message={messageTwo} placeholder={inputPlaceholderTwo}/> )}
            <ConditionalInput isChecked={buttonThree} onclick={handleButtonThreeChange} label={checkboxLabelThree(isReason, isChannel, isType)}/>
            {(isChannel || isType) && !!buttonThree && ( <Input handleChange={handleChangeInputThree} message={messageThree} placeholder={inputPlaceholderThree(isChannel, isType)}/> )}
            {(isChannel || isType) && (
                <>
                    <ConditionalInput isChecked={buttonFour} onclick={handleButtonFourChange} label={checkboxLabelFour(isChannel, isType)}/>
                    { !!buttonFour && (<Input handleChange={handleChangeInputFour} message={messageFour} placeholder={inputPlaceholderFour(isChannel, isType)}/>)}
                </>
            )}
            {isChannel && (
                <>
                    <ConditionalInput isChecked={buttonFive} onclick={handleButtonFiveChange} label={checkboxLabelFive}/>
                    { !!buttonFive && (<Input handleChange={handleChangeInputFive} message={messageFive} placeholder={inputPlaceholderFive}/>)}
                </>
            )}
        </div>
    )
};

PopoverInterior.propTypes = {
    popoverType: PropTypes.string,
    fieldApi: PropTypesFieldApi.isRequired,
    messageOne: PropTypes.string.isRequired,
    messageTwo: PropTypes.string.isRequired,
    messageThree: PropTypes.string.isRequired,
    messageFour: PropTypes.string.isRequired,
    messageFive: PropTypes.string.isRequired,
    buttonOne: PropTypes.bool.isRequired,
    buttonTwo: PropTypes.bool.isRequired,
    buttonThree: PropTypes.bool.isRequired,
    buttonFour: PropTypes.bool.isRequired,
    buttonFive: PropTypes.bool.isRequired,
    handleButtonOneChange: PropTypes.func.isRequired,
    handleButtonTwoChange: PropTypes.func.isRequired,
    handleButtonThreeChange: PropTypes.func.isRequired,
    handleButtonFourChange: PropTypes.func.isRequired,
    handleButtonFiveChange: PropTypes.func.isRequired,
    handleChangeInputOne: PropTypes.func.isRequired,
    handleChangeInputTwo: PropTypes.func.isRequired,
    handleChangeInputThree: PropTypes.func.isRequired,
    handleChangeInputFour: PropTypes.func.isRequired,
    handleChangeInputFive: PropTypes.func.isRequired,
    isReason: PropTypes.bool.isRequired,
    isChannel: PropTypes.bool.isRequired,
    isType: PropTypes.bool.isRequired,
    checkboxLabelOne: PropTypes.func.isRequired,
    checkboxLabelTwo: PropTypes.func.isRequired,
    checkboxLabelThree: PropTypes.func.isRequired,
    checkboxLabelFour: PropTypes.func.isRequired,
    checkboxLabelFive: PropTypes.string.isRequired,
    inputPlaceholderOne: PropTypes.string.isRequired,
    inputPlaceholderTwo: PropTypes.string.isRequired,
    inputPlaceholderThree: PropTypes.func.isRequired,
    inputPlaceholderFour: PropTypes.func.isRequired,
    inputPlaceholderFive: PropTypes.string.isRequired,
}

PopoverInterior.defaultProps={
    popoverType: "reason",
}

export default PopoverInterior;
