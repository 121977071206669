import {normalizeDiacritics} from 'normalize-text';
import {createSelector} from 'reselect';

const scope = state => state.languages;

/* eslint-disable fp/no-mutating-methods */
export const sortLanguagesByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortLanguagesByDefault = sortLanguagesByName;
/* eslint-enable fp/no-mutating-methods */

export const getLanguagesLoading = state => scope(state).loading;
export const getLanguagesError = state => scope(state).error;
export const getLanguagesLoaded = state => scope(state).loaded;
export const getLanguages = state => Object.values(scope(state).languages);
export const getLanguagesSortByName = createSelector(getLanguages, sortLanguagesByName);
export const getLanguagesSortByDefault = createSelector(getLanguages, sortLanguagesByDefault);

const filteredLanguagesName = (list, name) =>
  list.filter(language => normalizeDiacritics(language.name.toLowerCase()).includes(normalizeDiacritics(name.toLowerCase())));

const filteredLanguages = {
  name: filteredLanguagesName,
};

export const getLanguagesFilteredBy = (state, filters, sortLanguages = sortLanguagesByName) => {
  const users = getLanguages(state);

  return sortLanguages(
    Object.keys(filters).reduce((nextLanguages, filter) => {
      const filterByFunction = filteredLanguages[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextLanguages, filters[filter] ?? '');
      }

      return nextLanguages;
    }, users)
  );
};
