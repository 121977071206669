/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {Loading} from 'components/Loading';
import {ErrorLayout, ErrorBoundary, NotFound} from 'components/Errors';
import {Login, Activate, ForgotPassword, ResetPassword, Logout} from 'components/Auth';
import {Dashboard} from 'components/Dashboard';
import {FirmsList} from 'components/Firms';
import {Recruit} from 'components/Recruit';
import {Approach} from 'components/Approach';
import {Preferences} from 'components/Preferences';
import {Setup} from 'components/Setup';
import {TermsAndConditions} from 'components/TermsAndConditions';
import {UserManage} from 'components/User';
import {PipelinesDashboard} from 'components/Pipelines';
import {LiveRolesDashboard, LiveRolesRecruiterDashboard, RoleEdit} from 'components/LiveRoles';
import {LiveRoleInvitation} from 'components/LiveRoleInvitation';

/**
 * Route options:
 *
 */
const RoutesHandler = () => (
  <BrowserRouter>
    <div className="app__wrapper">
      <ErrorBoundary fallback={<ErrorLayout message="Please reload and try again" />}>
        <Suspense fallback={<Loading delay={300} />}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/liveroles" component={LiveRolesDashboard} />
            <Route exact path="/recruiter" component={LiveRolesRecruiterDashboard} />
            <Route exact path="/pipelines" component={PipelinesDashboard} />
            <Route exact path="/:selectedFirmOption(subscribers|firms)" component={FirmsList} />
            <Route
              exact
              path="/recruit/:selectedList(search|review)?/:selectedCandidateId?/:selectedCandidateTab(details|history|expertise|insights|notes)?/:tabId?"
              component={Recruit}
            />
            <Route
              exact
              path="/:roleType(role|liverole|legacyrole|pipeline)?/:selectedRoleId?/recruit/:selectedList(search|longlist|recruit-list|unsuited)?/:selectedCandidateId?/:selectedCandidateTab(details|history|expertise|insights|notes)?/:tabId?"
              component={Recruit}
            />
            <Route exact path="/liverole/:selectedRoleId?/invitation/:emailCode?" component={LiveRoleInvitation} />
            <Route
              exact
              path="/review/:selectedRoleId?/:selectedCandidateId?/:selectedCandidateTab(details|history|expertise|insights|notes)?/:tabId?"
              component={Approach}
            />
            <Route exact path="/preferences" component={Preferences} />
            <Route exact path="/email/verify/:userId/:hash" component={Activate} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotten" component={ForgotPassword} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/manage-accounts" component={UserManage} />
            <Route exact path="/:roleType(role|liverole|legacyrole|pipeline)?/:id/edit" component={RoleEdit} />
            <Route exact path="/terms-and-conditions/:countryId?" component={TermsAndConditions} />
            <Route path="/setup" component={Setup} />
            <Route path="/logout" component={Logout} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </div>
  </BrowserRouter>
);

export default RoutesHandler;
