import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateAdmissionDefaultValues = (admission = {}) => {
  const {id, admission_date: admissionDate} = admission || {};

  return useMemo(() => {
    if (!admission || !Object.keys(admission).length) {
      return {};
    }

    const admissionDateParsed = admissionDate ? dayjs(admissionDate) : dayjs(null);

    return {
      id,
      ...admission,
      admission_date: admissionDateParsed.isValid() ? admissionDateParsed : null,
    };
  }, [admission, admissionDate, id]);
};

export default useCandidateAdmissionDefaultValues;
