import {combineReducers} from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ROLES':
      return action.roles.reduce(
        (nextRole, role) => ({
          ...nextRole,
          [role.id]: {...state[role.id], ...role},
        }),
        {}
      );

    case 'UPDATE_ROLES':
      return action.roles.reduce(
        (nextRole, role) => ({
          ...nextRole,
          [role.id]: {...nextRole[role.id], ...role},
        }),
        {...state}
      );

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'SET_ROLES':
      return action.roles.map(role => role.id);

    case 'UPDATE_ROLES':
      return [...new Set([...state, ...action.roles.map(role => role.id)])];

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ROLES_LOADING':
      return true;
    case 'SET_ROLES':
    case 'UPDATE_ROLES':
    case 'SET_ROLES_ERROR':
      return false;
    default:
      return state;
  }
};
const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_ROLES':
    case 'UPDATE_ROLES':
      return Date.now();
    default:
      return state;
  }
};

const saving = (state = false, action) => {
  switch (action.type) {
    case 'SET_ROLES_SAVING':
      return true;
    case 'SET_ROLES':
    case 'UPDATE_ROLES':
    case 'SET_ROLES_ERROR':
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_ROLES_LOADING':
    case 'SET_ROLES_SAVING':
    case 'SET_ROLES':
    case 'UPDATE_ROLES':
      return false;
    case 'SET_ROLES_ERROR':
      return action.message;
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  loading,
  loaded,
  saving,
  error,
});
