import React from 'react';

import HeaderNav from './HeaderNav';

const Header = () => (
  <header className="header">
    <HeaderNav />
  </header>
);
export default Header;
