import {useMemo} from 'react';

const useDefaultSearchCriteria = (filters, search = {}) =>
  useMemo(
    () =>
      filters.reduce((nextFilter, filter) => {
        if (search && filter.field in search) {
          // eslint-disable-next-line fp/no-mutation, no-param-reassign
          nextFilter[filter.field] = search[filter.field];
          return nextFilter;
        }

        if (!('default' in filter) || !filter.includeDefault) return nextFilter;

        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        nextFilter[filter.field] = filter.default;
        return nextFilter;
      }, {}),
    [filters, search]
  );

export default useDefaultSearchCriteria;
