import React from 'react';
import {Header} from 'components/Header';
import Loading from './Loading';

const LoadingLayout = () => (
  <React.Fragment>
    <Header />
    <Loading delay={300} />
  </React.Fragment>
);

export default LoadingLayout;
