const collator = new Intl.Collator();

// eslint-disable-next-line fp/no-mutating-methods
const sortOrganisationsByName = organisations => organisations.slice(0).sort((a, b) => collator.compare(a.name, b.name));
const filterOrganisationsByName = (organisations, filter) =>
  organisations.filter(organisation => organisation.name.toLowerCase().includes(filter));

export const getOrganisations = state => Object.values(state.organisations.organisationsById);

export const getFirms = state => sortOrganisationsByName(state.organisations.firmIds.map(id => state.organisations.organisationsById[id]));
export const getFirmsById = (state, firmIds) => sortOrganisationsByName(firmIds.map(id => state.organisations.organisationsById[id]));
export const getCompanies = state =>
  sortOrganisationsByName(state.organisations.companyIds.map(id => state.organisations.organisationsById[id]));

export const getOrganisationsSortByName = state => sortOrganisationsByName(getOrganisations(state));

export const getOrganisationsFiltered = (state, filter) => {
  const organisations = getOrganisations(state);

  if (!filter || filter.trim() === '') {
    return sortOrganisationsByName(organisations);
  }

  const filterValue = filter.toLowerCase();

  return sortOrganisationsByName(filterOrganisationsByName(organisations, filterValue));
};

export const getSubscribers = state =>
  sortOrganisationsByName(state.organisations.subscriberIds.map(id => state.organisations.organisationsById[id]));

export const getSubscribersFiltered = (state, filter = '') => {
  const subscribers = getSubscribers(state);

  if (!filter || filter.trim() === '') {
    return sortOrganisationsByName(subscribers);
  }

  const filterValue = filter.toLowerCase();

  return sortOrganisationsByName(filterOrganisationsByName(subscribers, filterValue));
};

export const getOrganisationById = (state, id) => state.organisations.organisationsById[id];
export const getOrganisationsCount = state => getOrganisations(state).length;
export const getSubscribersCount = state => getSubscribers(state).length;
export const getOrganisationsLoading = state => state.organisations.organisationsLoading;
export const getOrganisationsError = state => state.organisations.organisationsError;
export const getOrganisationsLoaded = state => state.organisations.organisationsLoaded;
export const getSubscribersLoading = state => state.organisations.subscribersLoading;
export const getSubscribersError = state => state.organisations.organisationsError;
export const getFirmsLoading = state => state.organisations.firmsLoading;
export const getFirmsError = state => state.organisations.organisationsError;
export const getFirmsLoaded = state => state.organisations.firmsLoaded;
export const getCompaniesLoading = state => state.organisations.companiesLoading;
export const getCompaniesLoaded = state => state.organisations.companiesLoaded;
export const getSubscribersLoaded = state => state.organisations.subscribersLoaded;

export const getOrganisationsSelectArray = state =>
  sortOrganisationsByName(getOrganisations(state)).map(organisation => ({
    id: organisation.id,
    search: organisation.name.toLowerCase(),
    text: organisation.name,
  }));
