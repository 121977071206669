import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Alert, Intent} from '@blueprintjs/core';
import {confirmable, createConfirmation} from 'react-confirm';

const Confirm = ({confirmation, show, proceed, ...rest}) => {
  const handleCancel = useCallback(() => proceed(false), [proceed]);
  const handleProceed = useCallback(() => proceed(true), [proceed]);

  return (
    <Alert
      icon="warning-sign"
      intent={Intent.WARNING}
      canEscapeKeyCancel
      confirmButtonText="Okay"
      cancelButtonText="Cancel"
      {...rest}
      isOpen={show}
      onCancel={handleCancel}
      onConfirm={handleProceed}>
      {confirmation}
    </Alert>
  );
};

Confirm.propTypes = {
  confirmation: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func.isRequired, // called when ok button is clicked.
};

const confirm = (confirmation, options = {}) =>
  createConfirmation(confirmable(Confirm))({
    confirmation,
    ...options,
  });

export default confirm;
