/* eslint-disable camelcase */
import {useMemo} from 'react';

const useCandidateApproachDefaultValues = (approach = {}) => {
  const {candidate_status, due} = approach || {};

  return useMemo(() => {
    if (!approach || !Object.keys(approach).length) {
      return {};
    }

    return {
      candidate_status,
      due,
      ...approach,
    };
  }, [approach, candidate_status, due]);
};

export default useCandidateApproachDefaultValues;
