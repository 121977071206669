import React from 'react';

import {DefaultLayout} from 'components/Layout';

const LoginLazy = React.lazy(() => import(/* webpackChunkName: "Login" */ './Login'));
const LogoutLazy = React.lazy(() => import(/* webpackChunkName: "Logout" */ './Logout'));
const ActivateLazy = React.lazy(() => import(/* webpackChunkName: "Activate" */ './Activate'));
const ForgotPasswordLazy = React.lazy(() => import(/* webpackChunkName: "ForgotPassword" */ './ForgotPassword'));
const ResetPasswordLazy = React.lazy(() => import(/* webpackChunkName: "ResetPassword" */ './ResetPassword'));

export const Login = props => <DefaultLayout component={LoginLazy} {...props} />;
export const Logout = props => <LogoutLazy {...props} />;
export const Activate = props => <DefaultLayout component={ActivateLazy} {...props} />;
export const ForgotPassword = props => <DefaultLayout component={ForgotPasswordLazy} {...props} />;
export const ResetPassword = props => <DefaultLayout component={ResetPasswordLazy} {...props} />;
