import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateProfile} from '../actionCreators';

const useCandidateSaveLanguages = candidateId => {
  const dispatch = useDispatch();

  return useCallback(
    languages =>
      dispatch(
        updateCandidateProfile(candidateId, {
          languages,
        })
      ),
    [dispatch, candidateId]
  );
};

export default useCandidateSaveLanguages;
