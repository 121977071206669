import {useMemo} from 'react';
import useCandidateSuggestions from './useCandidateSuggestions';

const useCandidateSuggestionIds = (candidateId, fields = []) => {
  const suggestions = useCandidateSuggestions(candidateId, fields);

  return useMemo(() => (Array.isArray(suggestions) ? suggestions.map(({id}) => id) : []), [suggestions]);
};

export default useCandidateSuggestionIds;
