import {useEffect, useState} from 'react';

import Service from 'api';

const useFilterGetPresence = (presenceUrl, searchHash) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [itemsAvailable, setItemsAvailable] = useState([]);

  useEffect(() => {
    if (!searchHash || !presenceUrl) return () => null;

    const doFetchList = async () => {
      setLoading(true);
      setError(false);
      try {
        const result = await Service.get(presenceUrl, {params: {searchHash}});
        setItemsAvailable(result.data);
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };

    doFetchList();
    return () => null;
  }, [presenceUrl, searchHash]);

  return [itemsAvailable, loading, error];
};

export default useFilterGetPresence;
