import {Toaster, Position} from '@blueprintjs/core';

import './Toaster.scss';

/** Singleton toaster instance. Create separate instances for different options. */
export const MainToaster = Toaster.create({
  className: 'toaster',
  position: Position.BOTTOM_RIGHT,
  maxToasts: 5,
});

export const MainToasterLeft = Toaster.create({
  className: 'toaster',
  position: Position.BOTTOM_LEFT,
  maxToasts: 5,
});
