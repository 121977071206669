import {normalizeDiacritics} from 'normalize-text';
import {createSelector} from 'reselect';

const scope = state => state.areasOfExpertise;

/* eslint-disable fp/no-mutating-methods */
const sortAreasOfExpertiseByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortAreasOfExpertiseByDefault = sortAreasOfExpertiseByName;
/* eslint-enable fp/no-mutating-methods */

export const getAreasOfexpertiseFilteredByPracticeAreaId = (state, practiceAreaId) =>
  sortAreasOfExpertiseByName(
    // eslint-disable-next-line camelcase
    getAreasOfExpertise(state).filter(areaOfExpertise => areaOfExpertise?.practice_area_ids.includes(practiceAreaId))
  );

export const getAreasOfExpertiseLoading = state => scope(state).loading;
export const getAreasOfExpertiseError = state => scope(state).error;
export const getAreasOfExpertiseLoaded = state => scope(state).loaded;
export const getAreasOfExpertise = state => Object.values(scope(state).areasOfExpertise);
export const getAreasOfExpertiseSortByName = createSelector(getAreasOfExpertise, sortAreasOfExpertiseByName);
export const getAreasOfExpertiseSortByDefault = createSelector(getAreasOfExpertise, sortAreasOfExpertiseByDefault);

const filteredAreasOfExpertiseName = (list, name) =>
  list.filter(areaOfExpertise => normalizeDiacritics(areaOfExpertise.name.toLowerCase()).includes(normalizeDiacritics(name.toLowerCase())));

const filteredAreasOfExpertise = {
  name: filteredAreasOfExpertiseName,
};

export const getAreasOfExpertiseFilteredBy = (state, filters, sortAreasOfExpertise = sortAreasOfExpertiseByName) => {
  const users = getAreasOfExpertise(state);

  return sortAreasOfExpertise(
    Object.keys(filters).reduce((nextAreasOfExpertise, filter) => {
      const filterByFunction = filteredAreasOfExpertise[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextAreasOfExpertise, filters[filter] ?? '');
      }

      return nextAreasOfExpertise;
    }, users)
  );
};
