/* eslint-disable camelcase */
import {useMemo} from 'react';

export const makeQualificationsList = (qualifications, includeHonours = false) =>
  qualifications.reduce((nextQualifications, {id, name, description, has_honours = false}) => {
    const addQualifications = [];

    if (has_honours && includeHonours) {
      // eslint-disable-next-line fp/no-mutating-methods
      addQualifications.push({
        id: `${id}-hons`,
        search: `${name} ${description} honours hons`.toLowerCase(),
        text: `${description} (Hons)`,
        honours: true,
      });
    }

    // eslint-disable-next-line fp/no-mutating-methods
    addQualifications.push({
      id,
      search: `${name} ${description}`.toLowerCase(),
      text: `${description}`,
      honours: includeHonours ? false : undefined,
    });

    return [...nextQualifications, ...addQualifications];
  }, []);

const useQualificationSearchSelect = (qualifications, includeHonours) =>
  useMemo(() => makeQualificationsList(qualifications, includeHonours), [qualifications, includeHonours]);

export default useQualificationSearchSelect;
