import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    limit: 0,
    ...params,
  });

  return Service.get(`approaches?${parsedParams}`, config);
};

export const get = (id, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`approaches/${id}?${parsedParams}`, config);
};

export const put = (id, data = {}, config = {}) => Service.put(`approaches/${id}`, data, config);
export const post = (data = {}, config = {}) => Service.post('approaches', data, config);
