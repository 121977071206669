import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchCandidateWith} from '../actionCreators';
import useCandidate from './useCandidate';

const useCandidateUpdate = (candidateId, fetchWith = 'subscriberData') => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const candidate = useCandidate(candidateId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!candidateId) {
      return () => null;
    }

    const doFetchCandidate = async () => {
      setLoading(true);
      setError(false);

      try {
        await dispatch(fetchCandidateWith(candidateId, fetchWith));
      } catch (e) {
        setError(e.message);
      }

      setLoading(false);
    };

    doFetchCandidate();

    return () => null;
  }, [candidateId, dispatch, fetchWith]);

  return [candidate, loading, error];
};

export default useCandidateUpdate;
