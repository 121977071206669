import PropTypes from 'prop-types';

export default PropTypes.shape({
  pqeRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  areasOfExpertise: PropTypes.arrayOf(PropTypes.number),
  workmix: PropTypes.arrayOf(PropTypes.number),
  languages: PropTypes.arrayOf(PropTypes.number),
  fullnameOrKeywords: PropTypes.string,
  currentLocationArea: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      zone_name: PropTypes.string,
      country_name: PropTypes.string,
    })
  ),
  connectedLocationArea: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      zone_name: PropTypes.string,
      country_name: PropTypes.string,
    })
  ),
  qualifications: PropTypes.arrayOf(PropTypes.number),
  universities: PropTypes.arrayOf(PropTypes.number),
  currentFirm: PropTypes.number,
  previousFirm: PropTypes.number,
  practicing: PropTypes.bool,
});
