import {combineReducers} from 'redux';

const practiceAreas = (state = [], action) => {
  switch (action.type) {
    case 'SET_PRACTICE_AREAS':
      return action.practiceAreas.reduce(
        (nextState, practiceArea) => ({
          ...nextState,
          [practiceArea.id]: practiceArea,
        }),
        state
      );
    case 'REMOVE_PRACTICE_AREAS':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextPracticeAreas} = nextState;
        return nextPracticeAreas;
      }, state);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_PRACTICE_AREAS_LOADING':
      return true;
    case 'SET_PRACTICE_AREAS':
    case 'SET_PRACTICE_AREAS_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_PRACTICE_AREAS':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_PRACTICE_AREAS_ERROR':
      return action.message;
    case 'SET_PRACTICE_AREAS_LOADING':
    case 'SET_PRACTICE_AREAS':
      return false;
    default:
      return state;
  }
};

export default combineReducers({practiceAreas, loading, loaded, error});
