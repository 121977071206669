import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

import 'react-quill/dist/quill.bubble.css';
import './Quill.scss';

export default class QuillInput extends Wrapper {
  componentDidMount() {
    const {api, includeCss} = this.props;

    if (includeCss === true) {
      import('react-quill/dist/quill.bubble.css');
    }

    if (api) api(this);
  }

  componentWillUnmount() {
    const {api} = this.props;

    if (api) api();
  }

  onChange = (content, delta, source, editor) => {
    const {onChange, fieldApi} = this.props;

    fieldApi.setValue(content);

    if (onChange) {
      onChange(content);
    }
  };

  render() {
    const {toolbar, readOnly, placeholder} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        <ReactQuill
          theme="bubble"
          modules={{toolbar}}
          readOnly={readOnly}
          placeholder={placeholder}
          value={this.getValue()}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
