import React from 'react';
import {Loading} from 'components/Loading';
import {AuthedLayout} from 'components/Layout';

export {default as UserAvatar} from './UserAvatar';

const UserEditModalLazy = React.lazy(() => import(/* webpackChunkName: "UserEditModal" */ './UserEditModal'));
const UserEditPopoverLazy = React.lazy(() => import(/* webpackChunkName: "UserEditPopover" */ './UserEditPopover'));
const UserManageLazy = React.lazy(() => import(/* webpackChunkName: "UserManage" */ './UserManage'));

export const UserEditModal = props => (
  <React.Suspense fallback={<Loading delay={300} />}>
    <UserEditModalLazy {...props} />
  </React.Suspense>
);

export const UserEditPopover = props => (
  <React.Suspense fallback={<Loading delay={300} />}>
    <UserEditPopoverLazy {...props} />
  </React.Suspense>
);

export const UserManage = props => <AuthedLayout component={UserManageLazy} {...props} />;
