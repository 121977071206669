import {combineReducers} from 'redux';

const usersById = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USERS': {
      return action.users.reduce(
        (nextUser, user) => ({
          ...nextUser,
          [user.id]: {
            ...state[user.id],
            ...user,
          },
        }),
        state
      );
    }

    case 'REMOVE_USERS': {
      return action.ids.reduce(
        (nextUsers, id) => {
          if (id in nextUsers) {
            // eslint-disable-next-line fp/no-delete, no-param-reassign
            delete nextUsers[id];
          }
          return nextUsers;
        },
        {...state}
      );
    }

    default:
      return state;
  }
};

const usersLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_USER_LOADING':
      return true;

    case 'SET_USERS':
    case 'SET_USER_ERROR':
    case 'REMOVE_USERS':
      return false;

    default:
      return state;
  }
};

const usersSaving = (state = false, action) => {
  switch (action.type) {
    case 'SET_USER_SAVING':
      return true;

    case 'SET_USERS':
    case 'SET_USER_ERROR':
    case 'REMOVE_USERS':
      return false;

    default:
      return state;
  }
};

const usersError = (state = false, action) => {
  switch (action.type) {
    case 'SET_USER_ERROR':
      return {
        message: action.message,
        type: action.exception || 'Unknown',
      };

    case 'CLEAR_USER_ERROR':
    case 'SET_USER_LOADING':
    case 'SET_USER_SAVING':
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  usersById,
  usersLoading,
  usersSaving,
  usersError,
});
