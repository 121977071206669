import {createSelector} from 'reselect';

const scope = state => state.universities;

/* eslint-disable fp/no-mutating-methods */
export const sortUniversitiesByName = state => state.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortUniversitiesByDefault = state =>
  state.slice(0).sort((a, b) => (+a.order === +b.order ? a.name.localeCompare(b.name) : b.order - a.order));
/* eslint-enable fp/no-mutating-methods */

export const getUniversitiesLoading = state => scope(state).loading;
export const getUniversitiesError = state => scope(state).error;
export const getUniversitiesLoaded = state => scope(state).loaded;
export const getUniversities = state => Object.values(scope(state).universities);
export const getUniversitiesSortByName = createSelector(getUniversities, sortUniversitiesByName);
export const getUniversitiesSortByDefault = createSelector(getUniversities, sortUniversitiesByDefault);

const filterUniversitiesByName = (list, name) => list.filter(university => university.name.toLowerCase().includes(name.toLowerCase()));
const filterUniversitiesByCountry = (list, country) => {
  const countries = Array.isArray(country) ? country : [country];

  return list.filter(university => countries.includes(university.scout_countries_id));
};

const filterUniversitiesBy = {
  country: filterUniversitiesByCountry,
  name: filterUniversitiesByName,
};

export const getUniversitiesFilteredBy = (state, filters, sortUniversities = sortUniversitiesByName) => {
  const users = getUniversities(state);

  return sortUniversities(
    Object.keys(filters).reduce((nextUniversities, filter) => {
      const filterByFunction = filterUniversitiesBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextUniversities, filters[filter] ?? '');
      }

      return nextUniversities;
    }, users)
  );
};
