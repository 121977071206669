import {ServiceNotFound, ServiceForbidden} from 'api';

import {getLiveRolesLoading, getLiveRolesSaving} from './selectors';
import * as LiveRolesApi from './api';

export const createLiveRole = data => async (dispatch, getState) => {
  if (getLiveRolesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setLiveRolesSaving());

  try {
    const response = await LiveRolesApi.post(data);

    if (Object.keys(response?.data?.errors || {}).length > 0) {
      dispatch(setLiveRolesError());
    }

    dispatch(updateLiveRoles([response.data]));

    return response.data;
  } catch (err) {
    dispatch(setLiveRolesError());
    throw err;
  }
};

export const saveLiveRole = (id, data) => async (dispatch, getState) => {
  if (getLiveRolesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setLiveRolesSaving());

  try {
    const response = await LiveRolesApi.put(id, data);

    if (Object.keys(response?.data?.errors || {}).length > 0) {
      dispatch(setLiveRolesError());
    }

    dispatch(updateLiveRoles([response.data]));

    return response.data;
  } catch (err) {
    dispatch(setLiveRolesError());
    throw err;
  }
};

export const fetchLiveRoleById = (id, params) => async (dispatch, getState) => {
  if (getLiveRolesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setLiveRolesLoading());

  try {
    const response = await LiveRolesApi.get(id, params);

    dispatch(updateLiveRoles([response.data]));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(updateLiveRoles([]));
      return null;
    }
    dispatch(setLiveRolesError(e.message));
  }
  return null;
};

export const fetchLiveRoles = params => async (dispatch, getState) => {
  if (getLiveRolesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setLiveRolesLoading());

  try {
    const response = await LiveRolesApi.list(params);

    dispatch(updateLiveRoles(response.data));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      return null;
    }
    dispatch(setLiveRolesError(e.message));
  }
  return null;
};

export const fetchRecruiterLiveRoles = async params => {
  try {
    const response = await LiveRolesApi.recruiterList(params);

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      return null;
    }
  }
  return null;
};

export const setLiveRolesLoading = () => ({
  type: 'SET_LIVEROLES_LOADING',
});

export const setLiveRolesSaving = () => ({
  type: 'SET_LIVEROLES_SAVING',
});

export const setLiveRolesError = (message = 'Sorry, an error occurred') => ({
  type: 'SET_LIVEROLES_ERROR',
  message,
});

export const setLiveRoles = liveroles => ({
  type: 'SET_LIVEROLES',
  liveroles,
});

export const addLiveRole = liverole => ({
  type: 'ADD_LIVEROLE',
  liverole,
});

export const updateLiveRoles = liveroles => ({
  type: 'UPDATE_LIVEROLES',
  liveroles,
});

export const hideLiveRole = liveRoleId => ({
  type: 'HIDE_LIVEROLE',
  liveRoleId,
});
