import {normalizeDiacritics} from 'normalize-text';
import {createSelector} from 'reselect';

const scope = state => state.sectors;

/* eslint-disable fp/no-mutating-methods */
export const sortSectorsByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortSectorsByDefault = sortSectorsByName;
/* eslint-enable fp/no-mutating-methods */

export const getSectorsLoading = state => scope(state).loading;
export const getSectorsError = state => scope(state).error;
export const getSectorsLoaded = state => scope(state).loaded;
export const getSectors = state => Object.values(scope(state).sectors);
export const getSectorsSortByName = createSelector(getSectors, sortSectorsByName);
export const getSectorsSortByDefault = createSelector(getSectors, sortSectorsByDefault);

const filterSectorsByName = (list, name) =>
  list.filter(sector => normalizeDiacritics(sector.name.toLowerCase()).includes(normalizeDiacritics(name.toLowerCase())));

const filterSectorsBy = {
  name: filterSectorsByName,
};

export const getSectorsFilteredBy = (state, filters, sortSectors = sortSectorsByName) => {
  const users = getSectors(state);

  return sortSectors(
    Object.keys(filters).reduce((nextSectors, filter) => {
      const filterByFunction = filterSectorsBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextSectors, filters[filter] ?? '');
      }

      return nextSectors;
    }, users)
  );
};
