import {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useSessionPermissions} from 'features/session';

import {fetchCandidateNotes, fetchCandidateRoleNotes} from '../actionCreators';

const useCandidateNotes = (candidateId, roleId) => {
  const {hasRecruiterRole} = useSessionPermissions();
  const dispatch = useDispatch();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);

  useEffect(() => {
    if (!candidateId) return () => null;

    const doFetch = async () => {
      setLoading(true);
      setError(false);

      let theRoleId = roleId;
      if (!theRoleId && hasRecruiterRole) {
        const parts = window.location.pathname.split('/');
        // eslint-disable-next-line fp/no-mutation, no-plusplus
        for (let i = 0; i < parts.length; i++) {
          if (i + 1 < parts.length && (parts[i] === 'liverole' || parts[i] === 'pipeline' || parts[i] === 'review')) {
            // eslint-disable-next-line fp/no-mutation
            theRoleId = parts[i + 1];
            break;
          }
        }
      }

      try {
        const response = await dispatch(
          roleId || hasRecruiterRole ? fetchCandidateRoleNotes(candidateId, theRoleId) : fetchCandidateNotes(candidateId)
        );
        setNotes(response);
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };

    doFetch();

    return () => null;
  }, [dispatch, candidateId, roleId, reloadCounter, hasRecruiterRole]);

  const reload = useCallback(() => {
    setReloadCounter(reloadCounter + 1);
  }, [reloadCounter]);

  return [notes, loading, error, reload];
};

export default useCandidateNotes;
