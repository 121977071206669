import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    limit: 0,
    sort: 'created desc',
    ...params,
  });

  return Service.get(`roles?${parsedParams}`, config);
};

export const sendConfirmation = (roleId, config = {}) =>
  Service.post(`/role_briefs/${roleId}/send-longlist-completed-notification `, config);

export const recruiterList = (params = {}, config) => {
  const parsedParams = parseParams({
    limit: 0,
    sort: 'created desc',
    ...params,
  });

  return Service.get(`recruiters/liveroles?${parsedParams}`, config);
};

export const get = (id, params = {}, config) => {
  const parsedParams = parseParams({
    ...params,
  });

  return Service.get(`roles/${id}?${parsedParams}`, config);
};

export const acceptRecruiterTerms = firmId => Service.post(`firms/agreed-to-recruiters-tc`, {firm_id: firmId});

export const put = (id, data = {}, config = {}) => Service.put(`roles/${id}`, data, config);
export const post = (data = {}, config = {}) => Service.post('roles', data, config);
