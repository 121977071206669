import * as AreaOfExpertiseApi from '../api';

import removeAreasOfExpertise from './removeAreasOfExpertise';

const destroyAreaOfExpertise = id => async (dispatch, getState) => {
  const response = await AreaOfExpertiseApi.destroy(id);

  dispatch(removeAreasOfExpertise([id]));

  return response.data;
};

export default destroyAreaOfExpertise;
