import React from 'react';
import PropTypes from 'prop-types';

import './Stepper.scss';

export default class Stepper extends React.Component {
  static propTypes = {
    steps: PropTypes.array.isRequired, // Number of steps on the form
    activeStep: PropTypes.any, // number of the currently active step
    className: PropTypes.string, // classname of the steps
  };

  static defaultProps = {
    activeStep: 1,
    steps: [{label: 'Label'}, {label: 'Complete'}],
    className: 'form-steps',
  };

  renderStep = (step, stepIdx) => {
    const {className, activeStep} = this.props;

    const {iconActive, iconBefore, iconAfter} = step;

    let icon = iconBefore;
    let classNamePosition = 'todo';
    if (stepIdx < activeStep) {
      icon = iconAfter;
      classNamePosition = 'done';
    }
    if (stepIdx === activeStep) {
      icon = iconActive;
      classNamePosition = 'active';
    }
    return (
      <li key={stepIdx} className={`${className}__step ${className}__step--${classNamePosition}`}>
        <div className={`${className}__wrapper`}>
          {icon}
          <span className={`${className}__title`}>{step.label}</span>
        </div>
      </li>
    );
  };

  render() {
    const {steps, className} = this.props;

    return <ol className={`${className}`}>{steps.map((step, i) => this.renderStep(step, i + 1))}</ol>;
  }
}
