import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {savePracticeArea, createPracticeArea, destroyPracticeArea} from '../actionCreators';

const usePracticeAreaActions = sectorId => {
  const dispatch = useDispatch();

  const onSavePracticeArea = useCallback(
    // This must be async so the form submit knows to wait.
    async values => {
      if (sectorId) {
        await dispatch(savePracticeArea(sectorId, values));
      } else {
        await dispatch(createPracticeArea(values));
      }
    },
    [sectorId, dispatch]
  );
  const onDestroyPracticeArea = useCallback(() => (sectorId ? dispatch(destroyPracticeArea(sectorId)) : null), [sectorId, dispatch]);

  return {
    onSavePracticeArea,
    onDestroyPracticeArea,
  };
};

export default usePracticeAreaActions;
