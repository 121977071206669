import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getSectorsFilteredBy, getSectorsLoading, getSectorsError, getSectorsLoaded, sortSectorsByDefault} from '../selectors';
import {fetchSectors} from '../actionCreators';

const filterSectorsByName = name => state => getSectorsFilteredBy(state, {name}, sortSectorsByDefault);

const useSectors = (nameFilter, params, forceReload = false) => {
  const sectors = useSelector(filterSectorsByName(nameFilter));
  const loaded = useSelector(getSectorsLoaded);
  const loading = useSelector(getSectorsLoading);
  const error = useSelector(getSectorsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchSectors(params));
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, forceReload]);

  return [sectors, loading, error];
};

export default useSectors;
