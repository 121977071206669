import {combineReducers} from 'redux';

const preferences = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES':
      return action.preferences.reduce((nextState, preference) => {
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        nextState[preference.type] = preference.value;
        return nextState;
      }, {});

    case 'UPDATE_PREFERENCES':
      return action.preferences.reduce(
        (nextState, preference) => ({
          ...nextState,
          [preference.type]: preference.value,
        }),
        state
      );

    default:
      return state;
  }
};

const preferenceSecurity = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES_SECURITY':
      return action.preferenceSecurity;
    default:
      return state;
  }
};

const preferencesLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES_LOADING':
      return true;

    case 'SET_PREFERENCES':
    case 'SET_PREFERENCES_ERROR':
    case 'UPDATE_PREFERENCES':
      return false;

    default:
      return state;
  }
};

const preferencesSaving = (state = false, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES_SAVING':
      return true;

    case 'SET_PREFERENCES':
    case 'UPDATE_PREFERENCES':
      return false;

    default:
      return state;
  }
};

const preferencesError = (state = false, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES_ERROR':
      return action.message;

    case 'SET_PREFERENCES_LOADING':
    case 'SET_PREFERENCES_SAVING':
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  preferences,
  preferenceSecurity,
  preferencesLoading,
  preferencesSaving,
  preferencesError,
});
