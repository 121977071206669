import {combineReducers} from 'redux';

const userdata = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      return action.userdata;
    case 'SET_SESSION_EXPIRED':
    case 'CLEAR_SESSION_DATA':
      return {};
    default:
      return state;
  }
};

const userId = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      if (action.userdata.id === undefined) {
        return null;
      }
      return action.userdata.id;
    case 'SET_SESSION_EXPIRED':
    case 'CLEAR_SESSION_DATA':
      return null;
    default:
      return state;
  }
};

const isLoginRequired = (state = false, action) => {
  switch (action.type) {
    case 'SET_LOGIN_REQUIREMENT':
      return action.require_login;
    default:
      return state;
  }
};

const expiresIn = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_EXPIRES':
      return action.sessionExpiresIn;
    case 'SET_SESSION_EXPIRED':
    case 'CLEAR_SESSION_DATA':
      return false;
    default:
      return state;
  }
};

const authMessage = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_AUTH_MESSAGE':
      return action.message;

    case 'SET_SESSION_LOADING':
    case 'SET_SESSION_ERROR':
    case 'CLEAR_SESSION_DATA':
      return false;

    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_ERROR':
      return action.message;
    case 'SET_SESSION_DATA':
    case 'SET_SESSION_LOADING':
    case 'SET_SESSION_SUBMITTING':
    case 'CLEAR_SESSION_DATA':
      return false;

    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case 'SET_SESSION_LOADING':
      return true;
    case 'SET_SESSION_DATA':
    case 'SET_SESSION_EXPIRED':
    case 'SET_SESSION_ERROR':
    case 'CLEAR_SESSION_DATA':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
    case 'SET_SESSION_ERROR':
      return true;
    case 'SET_SESSION_EXPIRED':
    case 'CLEAR_SESSION_DATA':
      return false;
    default:
      return state;
  }
};

const submitting = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_SUBMITTING':
      return true;
    case 'SET_SESSION_DATA':
    case 'SET_SESSION_ERROR':
    case 'CLEAR_SESSION_DATA':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  loaded,
  submitting,
  error,
  userdata,
  userId,
  isLoginRequired,
  expiresIn,
  authMessage,
});
