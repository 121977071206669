import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {disableUser} from '../actionCreators';

const useOnDisableUser = () => {
  const dispatch = useDispatch();

  return useCallback(userId => dispatch(disableUser(userId)), [dispatch]);
};

export default useOnDisableUser;
