import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveUser} from '../actionCreators';

const useOnSaveUser = () => {
  const dispatch = useDispatch();

  return useCallback((userId, userData) => dispatch(saveUser(userId, userData)), [dispatch]);
};

export default useOnSaveUser;
