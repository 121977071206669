import {combineReducers, createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import LegacyReducers from 'features/legacy/reducers';
import {TermsAndConditionsReducer} from 'features/termsAndConditions';
import {AreasOfExpertiseReducer} from 'features/areasOfExpertise';
import {QualificationsReducer} from 'features/qualifications';
import {PracticeAreasReducer} from 'features/practiceAreas';
import {OrganisationsReducer} from 'features/organisations';
import {UniversitiesReducer} from 'features/universities';
import {AuthoritiesReducer} from 'features/authorities';
import {PreferencesReducer} from 'features/preferences';
import {CandidatesReducer} from 'features/candidates';
import {CountriesReducer} from 'features/countries';
import {LanguagesReducer} from 'features/languages';
import {LiveRolesReducer} from 'features/liveroles';
import {PipelinesReducer} from 'features/pipelines';
import {SavedSearchReducer} from 'features/search';
import {WorkmixReducer} from 'features/workmix';
import {SectorsReducer} from 'features/sectors';
import {SessionReducer} from 'features/session';
import {UsersReducer} from 'features/users';
import {RolesReducer} from 'features/roles';
import withProvider from './withProvider';

/**
 * Create root reducer, containing
 * all features of the application
 */
const rootReducer = combineReducers({
  ...LegacyReducers,
  termsAndConditions: TermsAndConditionsReducer,
  areasOfExpertise: AreasOfExpertiseReducer,
  qualifications: QualificationsReducer,
  practiceAreas: PracticeAreasReducer,
  organisations: OrganisationsReducer,
  universities: UniversitiesReducer,
  authorities: AuthoritiesReducer,
  preferences: PreferencesReducer,
  savedSearch: SavedSearchReducer,
  candidates: CandidatesReducer,
  countries: CountriesReducer,
  searchSelectFilter: CandidatesReducer,
  languages: LanguagesReducer,
  liveRoles: LiveRolesReducer,
  pipelines: PipelinesReducer,
  workmix: WorkmixReducer,
  sectors: SectorsReducer,
  session: SessionReducer,
  users: UsersReducer,
  roles: RolesReducer,
});

/**
 * Initialize Redux Dev Tools,
 * if they are installed in browser.
 */
/* eslint-disable no-underscore-dangle */
/** Use Redux compose, if browser doesn't have Redux devtools */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

/** Create Redux store with root reducer and middleware included */
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

/**
 * Create HOC, which wraps given Component with Redux Provider
 */
export default withProvider({store, Provider});
