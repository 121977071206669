import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {getCandidateSuggestions} from '../selectors';

const useCandidateSuggestions = (candidateId, fields = []) => {
  const getCandidateSuggestionsById = useCallback(state => getCandidateSuggestions(state, candidateId, fields), [candidateId, fields]);

  return useSelector(getCandidateSuggestionsById);
};

export default useCandidateSuggestions;
