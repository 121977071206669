import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {saveQualification, createQualification, destroyQualification} from '../actionCreators';

const useQualificationActions = qualificationId => {
  const dispatch = useDispatch();

  const onSaveQualification = useCallback(
    // This needs to be async so that the form waits and
    // captures exceptions properly
    async values => {
      if (qualificationId) {
        await dispatch(saveQualification(qualificationId, values));
      } else {
        await dispatch(createQualification(values));
      }
    },
    [qualificationId, dispatch]
  );
  const onDestroyQualification = useCallback(
    () => (qualificationId ? dispatch(destroyQualification(qualificationId)) : null),
    [qualificationId, dispatch]
  );

  return {
    onSaveQualification,
    onDestroyQualification,
  };
};

export default useQualificationActions;
