import {normalizeDiacritics} from 'normalize-text';
import {createSelector} from 'reselect';

const scope = state => state.workmix;

/* eslint-disable fp/no-mutating-methods */
const sortWorkmixByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
export const sortWorkmixByDefault = sortWorkmixByName;
/* eslint-enable fp/no-mutating-methods */

export const getWorkmixFilteredByAreaOfExpertiseId = (state, areaOfexpertiseId) =>
  // eslint-disable-next-line camelcase
  sortWorkmixByName(getWorkmix(state).filter(workmix => workmix?.area_of_expertise_ids.includes(areaOfexpertiseId)));

export const getWorkmixLoading = state => scope(state).loading;
export const getWorkmixError = state => scope(state).error;
export const getWorkmixLoaded = state => scope(state).loaded;
export const getWorkmix = state => Object.values(scope(state).workmix);
export const getWorkmixSortByName = createSelector(getWorkmix, sortWorkmixByName);
export const getWorkmixSortByDefault = createSelector(getWorkmix, sortWorkmixByDefault);

const filteredWorkmixName = (list, name) =>
  list.filter(workmix => normalizeDiacritics(workmix.name.toLowerCase()).includes(normalizeDiacritics(name.toLowerCase())));

const filteredWorkmix = {
  name: filteredWorkmixName,
};

export const getWorkmixFilteredBy = (state, filters, sortWorkmix = sortWorkmixByName) => {
  const users = getWorkmix(state);

  return sortWorkmix(
    Object.keys(filters).reduce((nextWorkmix, filter) => {
      const filterByFunction = filteredWorkmix[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextWorkmix, filters[filter] ?? '');
      }

      return nextWorkmix;
    }, users)
  );
};
