export const applyFilter = (name, values) => {
  switch (name) {
    case 'pqeRange':
      return {
        clauses: {
          [name]: {
            values,
          },
        },
      };
    default:
      return {
        clauses: {
          [name]: values,
        },
      };
  }
};
