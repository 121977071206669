import {useMemo} from 'react';

const useSearchSelect = (array, idProp = 'id', valueProp = 'name') =>
  useMemo(
    () =>
      array.map(item => ({
        id: item[idProp],
        search: item[valueProp].toLowerCase(),
        text: item[valueProp],
      })),
    [array, idProp, valueProp]
  );

export default useSearchSelect;
