import {createSelector} from 'reselect';

const scope = state => state.countries;

/* eslint-disable fp/no-mutating-methods */
const sortCountriesByName = list => list.slice(0).sort((a, b) => a.name.localeCompare(b.name));
const sortCountriesByDefault = list =>
  list.slice(0).sort((a, b) => (a.order === b.order ? a.name.localeCompare(b.name) : b.order - a.order));
/* eslint-enable fp/no-mutating-methods */

export const getCountriesLoading = state => scope(state).loading;
export const getCountriesError = state => scope(state).error;
export const getCountriesLoaded = state => scope(state).loaded;
export const getCountries = state => Object.values(scope(state).countries);
export const getCountriesSortByName = createSelector(getCountries, sortCountriesByName);
export const getCountriesSortByDefault = createSelector(getCountries, sortCountriesByDefault);
