import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getCountries, getCountriesLoading, getCountriesError, getCountriesLoaded} from '../selectors';
import {fetchCountries} from '../actionCreators';

const useCountries = (forceReload = false) => {
  const countries = useSelector(getCountries);
  const loaded = useSelector(getCountriesLoaded);
  const loading = useSelector(getCountriesLoading);
  const error = useSelector(getCountriesError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchCountries());
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forceReload]);

  return [countries, loading, error];
};

export default useCountries;
