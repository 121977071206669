import {useMemo} from 'react';

const useUniversitySearchSelect = firms =>
  useMemo(
    () =>
      firms.map(({id, name}) => ({
        id,
        search: name.toLowerCase(),
        text: name,
      })),
    [firms]
  );

export default useUniversitySearchSelect;
