import React from 'react';
import NumberFormat from 'react-number-format';
import Wrapper from './Wrapper';

export default class NumberInputField extends Wrapper {
  static validationRules = {
    numericality: {
      strict: true,
    },
  };

  onValueChange = value => {
    const {onValueChange, fieldApi} = this.props;

    fieldApi.setValue(value.value);
    fieldApi.setTouched();

    if (onValueChange) {
      onValueChange(value.value);
    }
  };

  render() {
    const {onValueChange, defaultValue, classElement, required, fieldApi, label, className, value, children, ...rest} = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        {/* TODO : add renderLabel() method from latest CD-Forms, meantime using .children props */}
        {this.props.children}
        <NumberFormat className={this.getElementClass()} value={this.getValue()} {...rest} onValueChange={this.onValueChange} />
        {this.renderMessage()}
      </div>
    );
  }
}
