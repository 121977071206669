/* eslint-disable max-classes-per-file */
import axios from 'axios';
import {ValidationError} from 'components/Legacy/CdForms';
import {ServiceError, ServiceForbidden, ServiceNotFound, ServiceAbort} from './errors';

export const {CancelToken} = axios;

export const handleSuccess = response => response.data;
export const handleSuccessRoot = response => response;

export const handleError = error => {
  /* eslint-disable fp/no-mutation  */
  if (axios.isCancel(error)) {
    return Promise.reject(new ServiceAbort(error));
  }
  if (!error.response) {
    if (window.console && console.log) console.log(error);
    return Promise.reject(new Error("Server Didn't Respond"));
  }
  const {status} = error.response;
  const {errors} = error.response.data;
  let {message} = error.response.data;
  message = message !== undefined ? message : '';
  if (!message && errors) {
    for (const key in errors) {
      if (errors[key]) {
        message = `${message} ${errors[key].toString()} `;
      }
    }
  }

  /* eslint-enable */
  switch (status) {
    case 422:
      return Promise.reject(new ValidationError(errors, message));
    case 403:
    case 401:
      return Promise.reject(new ServiceForbidden(error, message));
    case 404:
      return Promise.reject(new ServiceNotFound(error));
    default:
      return Promise.reject(new ServiceError(error, !message ? 'An unexpected error has occurred. Please try again.' : message));
  }
};

export const ServiceFactory = (serviceHandleSuccess = handleSuccessRoot, serviceHandleError = handleError) => {
  const AxiosService = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
    withCredentials: true,
  });

  AxiosService.interceptors.response.use(serviceHandleSuccess, serviceHandleError);

  return AxiosService;
};

const Service = ServiceFactory(handleSuccess, handleError);

export default Service;
export {ServiceError, ServiceForbidden, ServiceNotFound, ServiceAbort};

export const parseIncludes = withParam => {
  if (typeof withParam !== 'string' && typeof withParam !== 'undefined') {
    return withParam.join(',');
  }
  return withParam;
};

export const parseParams = (params, prefix) => {
  const getStr = [];
  let param;

  for (param in params) {
    if (Object.prototype.hasOwnProperty.call(params, param)) {
      const key = prefix ? `${prefix}[${param}]` : param;
      const value = params[param];
      /* eslint-disable fp/no-mutating-methods */
      if (value !== null && value !== undefined) {
        // If is an array of strings / numbers if there is a single object (that is not null (typeof null is 'object)) then
        // seperate as per normal - else use the object filter.
        if (Array.isArray(value) && value.filter(val => typeof val === 'object' && val !== null).length === 0) {
          getStr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.join('||'))}`);
        } else {
          getStr.push(typeof value === 'object' ? parseParams(value, key) : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
      }
      /* eslint-enable */
    }
  }

  return getStr.join('&');
};
