import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getFirms, getFirmsById, getFirmsLoading, getFirmsError, getFirmsLoaded} from '../selectors';
import {fetchFirms} from '../actionCreators';

const filterFirmsById = firmIds => state => getFirmsById(state, firmIds);

const useFirms = (firmIds, forceReload = false) => {
  const firms = useSelector(Array.isArray(firmIds) ? filterFirmsById(firmIds) : getFirms);
  const loaded = useSelector(getFirmsLoaded);
  const loading = useSelector(getFirmsLoading);
  const error = useSelector(getFirmsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchFirms());
    return () => null;
  }, [dispatch, loaded, forceReload]);

  return [firms, loading, error];
};

export default useFirms;
