/* eslint-disable fp/no-mutating-methods */
export const sortLiveRolesByCreated = state => state;
export const sortLiveRolesByName = state => state.slice(0).sort((a, b) => a.role_title.localeCompare(b.role_title));
export const sortLiveRolesByDefault = sortLiveRolesByCreated;
/* eslint-enable fp/no-mutating-methods */

export const getLiveRoles = state => state.liveRoles.ids.map(id => state.liveRoles.byId[id]);
export const getLiveRoleById = (state, id) => getLiveRolesById(state)[id];
export const getLiveRolesLoaded = state => state.liveRoles.loaded;
export const getLiveRolesLoading = state => state.liveRoles.loading;
export const getLiveRolesError = state => state.liveRoles.error;
export const getLiveRolesSaving = state => state.liveRoles.saving;
export const getLiveRolesFirmId = (state, id) => {
  const liveRole = getLiveRoleById(state, id);

  if (liveRole && 'for_firm_id' in liveRole && liveRole.for_firm_id) {
    return liveRole.for_firm_id;
  }

  if (liveRole && 'scout_firms_id' in liveRole) {
    return liveRole.scout_firms_id;
  }

  return null;
};

export const getLiveRolesById = (state, liveRoleIds = false) => {
  if (!liveRoleIds) return state.liveRoles.byId;

  return liveRoleIds.reduce(
    (nextLiveRoles, id) => ({
      ...nextLiveRoles,
      [id]: state.liveRoles.byId[id],
    }),
    {}
  );
};

const filterLiveRolesByActive = list => list.filter(liveRole => liveRole.status !== 'inactive');
const filterLiveRolesByName = (list, name) =>
  list.filter(liveRole =>
    [liveRole.firm_name, liveRole.role_team, liveRole.role_title, liveRole.role_location]
      .filter(val => val)
      .join(' ')
      .toLowerCase()
      .includes(name)
  );
const filterLiveRolesByStatus = (list, status) => {
  const statuses = Array.isArray(status) ? status : [status];
  return list.filter(liveRole => statuses.includes(liveRole.status));
};
const filterLiveRolesByTeamUser = (liveRoles, teamUsers) =>
  liveRoles.filter(liveRole => liveRole.team?.filter(user => teamUsers.includes(user.id)).length > 0);

export const filterLiveRolesBy = {
  active: filterLiveRolesByActive,
  status: filterLiveRolesByStatus,
  name: filterLiveRolesByName,
  team: filterLiveRolesByTeamUser,
};

export const getLiveRolesFilteredBy = (state, filters, sortLiveRoles = sortLiveRolesByDefault) => {
  const users = getLiveRoles(state);

  return sortLiveRoles(
    Object.keys(filters).reduce((nextLiveRoles, filter) => {
      const filterByFunction = filterLiveRolesBy[filter];

      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextLiveRoles, filters[filter] ?? '');
      }

      return nextLiveRoles;
    }, users)
  );
};
