import {useMemo} from 'react';

const useWorkmixSearchSelect = workmix =>
  useMemo(
    () =>
      workmix.map(({id, name}) => ({
        id,
        search: name.toLowerCase(),
        text: name,
      })),
    [workmix]
  );

export default useWorkmixSearchSelect;
