/* eslint-disable camelcase */
import FetchApi, {fetchPost, fetchDelete} from '@customd/cd-fetch-model';

class CandidatesModel extends FetchApi {
  api_url = `${process.env.REACT_APP_LEGACY_ENDPOINT}/api/v1/candidates`;

  setEducation = data => fetchPost(`${process.env.REACT_APP_API_ENDPOINT}/api/candidates/${data.scout_candidates_id}/educations`, data);

  // eslint-disable-next-line max-len, prettier/prettier
  deleteEducation = (id, candidates_id) => fetchDelete(`${process.env.REACT_APP_API_ENDPOINT}/api/candidates/${candidates_id}/educations/${id}`);

  updateAdmission = data => fetchPost(`${process.env.REACT_APP_API_ENDPOINT}/api/candidates/${data.id}/admissions`, data);

  // eslint-disable-next-line max-len, prettier/prettier
  updateRegistration = data => fetchPost(`${process.env.REACT_APP_API_ENDPOINT}/api/candidates/${data.id}/registrations`, data);
}

const Candidates = new CandidatesModel();

export default Candidates;
