import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {setCandidateReviewed} from '../actionCreators';

const useCandidateReviewed = candidateId => {
  const dispatch = useDispatch();

  return useCallback(data => dispatch(setCandidateReviewed(candidateId, {status: 'active'})), [dispatch, candidateId]);
};

export default useCandidateReviewed;
