import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FineUploaderTraditional from 'fine-uploader-wrappers';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import ProgressBar from 'react-fine-uploader/progress-bar';
import {Loading} from 'components/Loading';

import deepMerge from './deepMerge';

const uploaderDefaults = {
  options: {
    deleteFile: {
      enabled: true,
      endpoint: '/admin/edit/upload_image',
    },
    request: {
      endpoint: '/admin/edit/upload_image',
    },
  },
};

const UploadedFile = ({url, name, readOnly = false, removeButtonLabel, removeUploadedFile}) => (
  <div className="form__upload-file">
    <div className="form__upload-field">
      <img src={url} alt={name} style={{maxWidth: '100%'}} />
    </div>
    {!readOnly && (
      <div className="form__finalise-changes">
        <button className="btn btn__ghost--blue" onClick={removeUploadedFile}>
          {removeButtonLabel || 'Remove Image'}
        </button>
      </div>
    )}
  </div>
);

class Uploader extends Component {
  static propTypes = {
    className: PropTypes.string,
    onUploaded: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    uploaderSettings: PropTypes.object,
    uploaded: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
    dropzoneEmptyEnabled: PropTypes.bool,
    dropzoneButtonEnabled: PropTypes.bool,
    dropzoneEnabled: PropTypes.bool,
    dropzoneLabel: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    multiple: PropTypes.bool,
    buttonEnabled: PropTypes.bool,
    emptyLabel: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    buttonLabel: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    renderUploaded: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    dropActiveClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    accept: PropTypes.string,
    removeButtonLabel: PropTypes.string,
    getUploader: PropTypes.func,
  };

  static defaultProps = {
    getUploader: () => null,
    uploaderSettings: {},
    uploaded: null,
    dropzoneEmptyEnabled: false,
    dropzoneButtonEnabled: false,
    dropzoneEnabled: false,
    dropzoneLabel: <span>Drag and Drop here</span>,
    dropzoneLoading: () => <Loading />,
    emptyLoading: null,
    buttonEnabled: false,
    multiple: false,
    readOnly: false,
    buttonLabel: 'Upload File(s)',
    dropActiveClassName: 'form__upload-field--active',
    buttonClassName: 'btn-uploader',
    accept: 'image/*',
    renderUploaded: UploadedFile,
  };

  constructor(props) {
    super(props);

    const {multiple, uploaded, uploaderSettings, fieldApi} = props;
    const settings = deepMerge(uploaderDefaults, uploaderSettings);
    const uploader = new FineUploaderTraditional(settings);

    this.state = {
      settings,
      uploader,
      uploaded: uploaded || (multiple ? [] : null),
      uploading: false,
    };

    if (Array.isArray(uploaded) && uploaded.length > 0) {
      uploader.methods.addInitialFiles(uploaded);

      let value;
      if (multiple) {
        value = uploaded.map(file => file.id);
      } else {
        value = uploaded[0].id;
      }

      fieldApi.setValue(value);
    }

    uploader.on('submit', this.handleUploadStart);
    uploader.on('cancel', this.handleUploadCancel);
    uploader.on('complete', this.handleUploadComplete);
  }

  componentDidUpdate(prevProps, prevState) {
    const {uploaded, multiple, fieldApi} = this.props;

    if (uploaded !== prevProps.uploaded) {
      const {uploader} = this.state;

      uploader.methods.addInitialFiles(uploaded);

      let value;
      if (multiple) {
        value = uploaded.map(file => file.id);
      } else {
        value = uploaded[0].id;
      }

      fieldApi.setValue(value);

      this.setState({uploaded});
    }

    if (this.state.uploader !== prevState.uploader) {
      this.props.getUploader(this.state.uploader);
    }
  }

  componentWillUnmount() {
    this.state.uploader.off('submit', this.handleUploadStart);
    this.state.uploader.off('cancel', this.handleUploadCancel);
    this.state.uploader.off('complete', this.handleUploadComplete);
  }

  handleUploadStart = () => {
    this.setState({uploading: true});
  };

  handleUploadCancel = () => {
    this.setState({uploading: false});
  };

  handleUploadComplete = (id, name, response) => {
    const {onUploaded, onChange, multiple, fieldApi} = this.props;

    this.setState({uploading: false});

    if (response.success) {
      const file = {
        ...response,
        id: response.file_id,
        url: response.filelink,
        name: response.name,
        type: response.mime,
      };

      try {
        if (multiple) {
          const value = Array.isArray(fieldApi.value) ? fieldApi.value : [];
          const nextValue = [response.file_id, ...value];
          const uploaded = Array.isArray(this.state.uploaded) ? this.state.uploaded : [];

          this.setState({uploaded: [file, ...uploaded]});
          fieldApi.setValue(nextValue);

          if (onUploaded) onUploaded(nextValue);
          if (onChange) onChange(nextValue);
        } else {
          this.setState({uploaded: [file]});
          fieldApi.setValue(response.file_id);

          if (onUploaded) onUploaded(response.file_id);
          if (onChange) onChange(response.file_id);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  removeUploadedFile = id => {
    const {onChange, onDelete, fieldApi, multiple} = this.props;
    const {uploader, uploaded, settings} = this.state;
    const doApiDelete = settings.options.deleteFile.enabled;

    if (!multiple) {
      this.setState({uploaded: null});
      fieldApi.setValue(null);
      if (doApiDelete) {
        uploader.methods.deleteFile(0);
      }

      if (onDelete) {
        onDelete(null);
      }
      if (onChange) {
        onChange(null);
      }
    } else {
      const uploads = uploader.methods.getUploads();
      const uploadToDelete = uploaded && uploaded.find(file => file.id === id);
      const nextUploaded = uploaded && uploaded.filter(file => file.id !== id);
      const uploadedIds = nextUploaded.map(file => file.id);
      const deleteIdx = uploads.findIndex(file => file.uuid === uploadToDelete.uuid);

      if (doApiDelete) {
        uploader.methods.deleteFile(deleteIdx);
      }

      this.setState({uploaded: nextUploaded});
      fieldApi.setValue(uploadedIds);

      if (onDelete) {
        onDelete(uploadedIds);
      }
      if (onChange) {
        onChange(uploadedIds);
      }
    }
  };

  render() {
    const {uploader, uploaded, uploading} = this.state;
    const {
      className,
      renderUploaded: UploadedFile,
      removeButtonLabel,
      emptyLabel,
      dropzoneEmptyEnabled,
      dropzoneButtonEnabled,
      dropzoneEnabled,
      dropzoneLabel,
      dropzoneLoading,
      emptyLoading,
      multiple,
      buttonEnabled,
      buttonLabel,
      dropActiveClassName,
      buttonClassName,
      accept,
      readOnly,
    } = this.props;
    const hasUploads = Array.isArray(uploaded) && uploaded.length > 0;

    if (hasUploads && !multiple) {
      const [file] = uploaded;

      return (
        <UploadedFile
          {...file}
          readOnly={readOnly}
          removeButtonLabel={removeButtonLabel}
          removeUploadedFile={() => this.removeUploadedFile(file.id)}
        />
      );
    }

    return (
      <div className={className}>
        {dropzoneEnabled && (
          <Dropzone dropActiveClassName={dropActiveClassName} uploader={uploader} multiple={multiple}>
            <div className="form__upload-field">
              {uploading || dropzoneLabel}
              {uploading && dropzoneLoading}
            </div>
          </Dropzone>
        )}
        {buttonEnabled && (
          <>
            <div className="form__finalise-changes">
            <FileInput className={buttonClassName} accept={accept} uploader={uploader} multiple={multiple}>
              {buttonLabel}
            </FileInput>
          </div>
            {multiple &&
              hasUploads &&
              uploaded.map(file => (
                <UploadedFile
                  key={file.key || file.id}
                  {...file}
                  readOnly={readOnly}
                  removeButtonLabel={removeButtonLabel}
                  removeUploadedFile={() => this.removeUploadedFile(file.id)}
                />
              ))}
          </>
        )}
      
        {dropzoneButtonEnabled && (
          <Dropzone dropActiveClassName={dropActiveClassName} uploader={uploader} multiple={multiple}>
            <div className="form__dropzone-button">
              <FileInput className={buttonClassName} accept={accept} uploader={uploader} multiple={multiple}>
                {uploading || dropzoneLabel}
                {uploading && dropzoneLoading}
                {uploading && <ProgressBar uploader={uploader} />}
              </FileInput>
            </div>

            {multiple &&
            hasUploads &&
            uploaded.map(file => (
              <UploadedFile
                key={file.key || file.id}
                {...file}
                readOnly={readOnly}
                removeButtonLabel={removeButtonLabel}
                removeUploadedFile={() => this.removeUploadedFile(file.id)}
              />
            ))}
          </Dropzone>
        )}
        
        {!hasUploads && !dropzoneEmptyEnabled && emptyLabel}
        {!hasUploads && dropzoneEmptyEnabled && (
          <Dropzone dropActiveClassName={dropActiveClassName} uploader={uploader} multiple={multiple}>
            {uploading || emptyLabel}
            {uploading && (emptyLoading ?? emptyLabel)}
          </Dropzone>
        )}
      </div>
    );
  }
}

export default Uploader;
