import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {updateCandidateProfile} from '../actionCreators';

const useCandidateSave = candidateId => {
  const dispatch = useDispatch();

  return useCallback(data => dispatch(updateCandidateProfile(candidateId, data)), [dispatch, candidateId]);
};

export default useCandidateSave;
