import {useMemo} from 'react';

const useCandidateHistoryDefaultValues = ({languages} = {}) =>
  useMemo(
    () => ({
      languages: languages?.map(language => language.id) ?? [],
    }),
    [languages]
  );

export default useCandidateHistoryDefaultValues;
