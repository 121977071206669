import * as CountriesApi from './api';

export const fetchCountries = () => async (dispatch, getState) => {
  dispatch(setCountriesLoading());

  try {
    const response = await CountriesApi.list();

    dispatch(setCountries(response.data));
    return response.data;
  } catch (e) {
    dispatch(setCountriesError(e.message));
  }
  return [];
};

export const setCountriesError = message => ({
  type: 'SET_COUNTRIES_ERROR',
  message,
});

export const setCountriesLoading = () => ({
  type: 'SET_COUNTRIES_LOADING',
});

export const setCountries = countries => ({
  type: 'SET_COUNTRIES',
  countries,
});
