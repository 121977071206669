/* eslint-disable fp/no-mutating-methods */
export const sortPipelinesByCreated = state => state;
export const sortPipelinesByName = state => state.slice(0).sort((a, b) => a.pipeline_title.localeCompare(b.pipeline_title));
export const sortPipelinesByDefault = sortPipelinesByCreated;
/* eslint-enable fp/no-mutating-methods */

export const getPipelines = state => state.pipelines.ids.map(id => state.pipelines.byId[id]);
export const getPipelineById = (state, id) => getPipelinesById(state)[id];
export const getPipelinesLoaded = state => state.pipelines.loaded;
export const getPipelinesLoading = state => state.pipelines.loading;
export const getPipelinesError = state => state.pipelines.error;
export const getPipelinesSaving = state => state.pipelines.saving;
export const getPipelinesFirmId = (state, id) => {
  const pipeline = getPipelineById(state, id);

  if (pipeline && 'for_firm_id' in pipeline && pipeline.for_firm_id) {
    return pipeline.for_firm_id;
  }

  if (pipeline && 'scout_firms_id' in pipeline) {
    return pipeline.scout_firms_id;
  }

  return null;
};

export const getPipelinesById = (state, pipelineIds = false) => {
  if (!pipelineIds) return state.pipelines.byId;

  return pipelineIds.reduce(
    (nextPipelines, id) => ({
      ...nextPipelines,
      [id]: state.pipelines.byId[id],
    }),
    {}
  );
};

const filterPipelinesByActive = list => list.filter(pipeline => pipeline.status !== 'inactive');
const filterPipelinesByName = (list, name) =>
  list.filter(pipeline =>
    [pipeline.firm_name, pipeline.role_team, pipeline.role_title, pipeline.role_location]
      .filter(val => val)
      .join(' ')
      .toLowerCase()
      .includes(name)
  );
const filterPipelinesByStatus = (list, status) => {
  const statuses = Array.isArray(status) ? status : [status];

  return list.filter(pipeline => statuses.includes(pipeline.status));
};
const filterPipelinesByTeamUser = (pipelines, teamUsers) =>
  pipelines.filter(pipeline => pipeline.team?.filter(user => teamUsers.includes(user.id)).length > 0);

export const filterPipelinesBy = {
  active: filterPipelinesByActive,
  status: filterPipelinesByStatus,
  name: filterPipelinesByName,
  team: filterPipelinesByTeamUser,
};

export const getPipelinesFilteredBy = (state, filters, sortPipelines = sortPipelinesByDefault) => {
  const pipelines = getPipelines(state);

  return sortPipelines(
    Object.keys(filters).reduce((nextPipelines, filter) => {
      const filterByFunction = filterPipelinesBy[filter];
      if (typeof filterByFunction === 'function') {
        return filterByFunction(nextPipelines, filters[filter] ?? '');
      }

      return nextPipelines;
    }, pipelines)
  );
};
