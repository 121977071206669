import {ServiceNotFound, ServiceForbidden} from 'api';

import {getPipelinesLoading, getPipelinesSaving} from './selectors';
import * as PipelinesApi from './api';

export const createPipeline = data => async (dispatch, getState) => {
  if (getPipelinesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setPipelinesSaving());
  try {
    const response = await PipelinesApi.post(data);

    if (Object.keys(response?.data?.errors || {}).length > 0) {
      dispatch(setPipelinesError());
    }

    dispatch(updatePipelines([response.data]));

    return response.data;
  } catch (err) {
    dispatch(setPipelinesError());
    throw err;
  }
};

export const savePipeline = (id, data) => async (dispatch, getState) => {
  if (getPipelinesSaving(getState())) {
    return Promise.resolve();
  }

  dispatch(setPipelinesSaving());

  const response = await PipelinesApi.put(id, data);

  dispatch(updatePipelines([response.data]));

  return response.data;
};

export const fetchPipelineById = (id, params) => async (dispatch, getState) => {
  if (getPipelinesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setPipelinesLoading());

  try {
    const response = await PipelinesApi.get(id, params);

    dispatch(updatePipelines([response.data]));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(updatePipelines([]));
      return null;
    }
    dispatch(setPipelinesError(e.message));
  }
  return null;
};

export const fetchPipelines = params => async (dispatch, getState) => {
  if (getPipelinesLoading(getState())) {
    return Promise.resolve();
  }

  dispatch(setPipelinesLoading());

  try {
    const response = await PipelinesApi.list(params);

    dispatch(updatePipelines(response.data));

    return response.data;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      return null;
    }
    dispatch(setPipelinesError(e.message));
  }
  return null;
};

export const setPipelinesLoading = () => ({
  type: 'SET_PIPELINES_LOADING',
});

export const setPipelinesSaving = () => ({
  type: 'SET_PIPELINES_SAVING',
});

export const setPipelinesError = (message = 'Sorry, an error occurred') => ({
  type: 'SET_PIPELINES_ERROR',
  message,
});

export const setPipelines = pipelines => ({
  type: 'SET_PIPELINES',
  pipelines,
});

export const addPipeline = pipeline => ({
  type: 'ADD_PIPELINE',
  pipeline,
});

export const updatePipelines = pipelines => ({
  type: 'UPDATE_PIPELINES',
  pipelines,
});
