import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getSavedSearchLoading, getSavedSearchError} from '../selectors';
import {addSavedSearch, fetchSavedSearch, setSavedSearchDeleted} from '../actionCreators';

const useSavedSearch = (type, role, list) => {
  const [newSavedSearches, setNewSavedSearches] = useState([]);
  const loading = useSelector(getSavedSearchLoading);
  const error = useSelector(getSavedSearchError);
  const dispatch = useDispatch();

  const removeSavedSearch = useCallback(
    async id => {
      await dispatch(setSavedSearchDeleted(id));
      setNewSavedSearches(prev => prev.filter(search => search.id !== id));
    },
    [dispatch]
  );

  const createSavedSearch = useCallback(
    async values => {
      const result = await dispatch(
        addSavedSearch({
          ...values,
        })
      );
      setNewSavedSearches(prev => [...prev, result]);
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchSavedSearches = async () => {
      const data = await dispatch(fetchSavedSearch(type, role, list));

      setNewSavedSearches(data);
    };
    fetchSavedSearches();
  }, [dispatch, type, role, list]);

  return [newSavedSearches, createSavedSearch, removeSavedSearch, loading, error];
};

export default useSavedSearch;
