import React from 'react';
import PropTypes from 'prop-types';
import {Position, Tooltip} from '@blueprintjs/core';
import {MdInfo} from 'react-icons/md';

import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Wrapper from './Wrapper';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// https://jpuri.github.io/react-draft-wysiwyg/#/docs

import './Editor.scss';

export default class EditorInput extends Wrapper {
  static propTypes = {
    toolbarOnFocus: PropTypes.bool,
    toolbarHidden: PropTypes.bool,
    toolbar: PropTypes.object,
    toolbarCustomButtons: PropTypes.array,
    includeCss: PropTypes.bool, // disable loading of the components base css
    wrapperClassName: PropTypes.string,
    toolbarClassName: PropTypes.string,
  };

  static defaultProps = {
    toolbarOnFocus: false,
    toolbarHidden: false,
    toolbar: {},
    toolbarCustomButtons: [],
    includeCss: true,
    wrapperClassName: 'editor__wrapper',
    toolbarClassName: 'editor__toolbar',
  };

  constructor(props) {
    super(props);
    const html = props.fieldApi.value || '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  componentDidMount() {
    const {api, includeCss} = this.props;

    if (includeCss === true) {
      import('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    }

    if (api) api(this);
  }

  componentWillUnmount() {
    const {api} = this.props;

    if (api) api();
  }

  onChange = editorState => {
    const {onChange, fieldApi} = this.props;
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const cleanValue = value.replace(/(<p><\/p>\s*)+$/g, '').trim();

    this.setState({editorState});
    fieldApi.setValue(cleanValue);

    if (onChange) {
      onChange(cleanValue);
    }
  };

  setEditorState(html = '') {
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      this.setState({
        editorState,
      });
    }
  }

  setValue(value = '') {
    const {onChange, fieldApi} = this.props;

    this.setEditorState(value);
    fieldApi.setValue(value);

    if (onChange) {
      onChange(value);
    }
  }

  onBlur = e => {
    const {onBlur, fieldApi} = this.props;

    fieldApi.setTouched();
    this.hideTooltip();
    this.setState({hasFocus: false});

    if (onBlur) {
      onBlur(this.getValue());
    }
  };

  renderLabel({...labelProps} = {}) {
    const {id, label, required, tooltip, readOnly} = this.props;

    if (!label) {
      return null;
    }

    return (
      <label htmlFor={id} className={this.getLabelClass()} {...labelProps}>
        {label}&nbsp;
        {required && !readOnly && <span className="highlight">*</span>}
        {tooltip && !readOnly && (
          <Tooltip lazy usePortal boundary="viewport" position={Position.TOP} content={tooltip}>
            <MdInfo size={18} />
          </Tooltip>
        )}
      </label>
    );
  }

  render() {
    const {editorState} = this.state;
    const {
      includeCss,
      classElement,
      required,
      fieldApi,
      label,
      className,
      wrapperClassName,
      toolbarClassName,
      value,
      tooltip,
      ...rest
    } = this.props;

    return (
      <div className={this.getWrapperClass()}>
        {this.renderLabel()}
        <Editor
          spellCheck
          stripPastedStyles
          {...rest}
          editorState={editorState}
          toolbarClassName={toolbarClassName}
          wrapperClassName={wrapperClassName}
          editorClassName={this.getElementClass()}
          onEditorStateChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {this.renderMessage()}
      </div>
    );
  }
}
