import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
  });

  return Service.get(`sectors?${parsedParams}`, config);
};

export const post = (data = {}, config = {}) => Service.post('sectors', data, config);
export const put = (id, data = {}, config = {}) => Service.put(`sectors/${id}`, data, config);
export const destroy = (id, config) => Service.delete(`sectors/${id}`, config);
