import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {destroyCandidateNote} from '../actionCreators';

const useCandidateDeleteNote = candidateId => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const onDelete = useCallback(
    async noteId => {
      setDeleting(noteId);
      setError(false);

      try {
        await dispatch(destroyCandidateNote(candidateId, noteId));
      } catch (e) {
        setError(e.message);
      }

      setDeleting(false);
    },
    [candidateId, dispatch]
  );

  return [onDelete, deleting, error];
};

export default useCandidateDeleteNote;
