import {useState, useCallback} from 'react';

const useSuggestUserActions = onCheckUsername => {
  const [suggestedUser, setSuggestedUser] = useState(false);

  const onCheckUserNameDetail = useCallback(
    e => {
      if (onCheckUsername) {
        const suggestUser = onCheckUsername(e.target.value);
        setSuggestedUser(suggestUser);
      }
    },
    [onCheckUsername]
  );

  const onSelectUserSuggestion = useCallback(
    formApi => () => {
      if (suggestedUser) {
        formApi.setValue('email', suggestedUser.email);
        formApi.setValue('scout_candidates_id', suggestedUser.id);
        setSuggestedUser(false);
      }
    },
    [suggestedUser]
  );

  return {suggestedUser, onCheckUserNameDetail, onSelectUserSuggestion};
};

export default useSuggestUserActions;
