import {combineReducers} from 'redux';

const firmIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_FIRMS':
      return Array.from(new Set(action.firms.map(firm => +firm.id)));

    case 'SET_ORGANISATIONS':
    case 'SET_COMPANIES':
    case 'SET_SUBSCRIBERS':
    case 'UPDATE_ORGANISATIONS':
      return Array.from(new Set([...state, ...action.firms.filter(firm => firm.type === 'firm').map(firm => +firm.id)]));

    case 'RESET_FIRM_RESULTS':
      return [];

    default:
      return state;
  }
};

const subscriberIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_SUBSCRIBERS':
      return Array.from(new Set(action.firms.map(firm => +firm.id)));

    case 'SET_ORGANISATIONS':
    case 'SET_FIRMS':
    case 'SET_COMPANIES':
    case 'UPDATE_ORGANISATIONS':
      return Array.from(new Set([...state, ...action.firms.filter(firm => +firm.is_subscriber === 1).map(firm => +firm.id)]));

    case 'RESET_SUBSCRIBER_RESULTS':
      return [];

    default:
      return state;
  }
};

const companyIds = (state = [], action) => {
  switch (action.type) {
    case 'SET_COMPANIES':
      return Array.from(new Set(action.firms.map(firm => +firm.id)));

    case 'SET_ORGANISATIONS':
    case 'SET_FIRMS':
    case 'SET_SUBSCRIBERS':
    case 'UPDATE_ORGANISATIONS':
      return Array.from(new Set([...state, ...action.firms.filter(firm => firm.type !== 'firm').map(firm => +firm.id)]));

    case 'RESET_COMPANY_RESULTS':
      return [];

    default:
      return state;
  }
};

const organisationsById = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS':
    case 'SET_SUBSCRIBERS':
    case 'SET_FIRMS': {
      return action.firms.reduce(
        (nextFirms, firm) => {
          // We're doing this for performance. Because we've already spread over state when
          // setting up the reducer's inital value, the mutation doesn't matter, and it's
          // MUCH more performant than doing a spread over `nextFirms` each iteration.

          /* eslint-disable fp/no-mutation */
          /* eslint-disable no-param-reassign */
          nextFirms[firm.id] = {...state[firm.id], ...firm};
          /* eslint-enable fp/no-mutation */
          /* eslint-enable no-param-reassign */
          return nextFirms;
        },
        {...state}
      );
    }
    case 'ADD_ORGANISATION': {
      const nextState = {
        ...state,
      };
      const {firm} = action;
      // eslint-disable-next-line fp/no-mutation
      nextState[firm.id] = firm;

      return nextState;
    }
    case 'UPDATE_ORGANISATIONS':
      return action.firms.reduce(
        (nextFirm, firms) => ({
          ...nextFirm,
          [firms.id]: {
            ...nextFirm[firms.id],
            ...firms,
          },
        }),
        {...state}
      );
    case 'REMOVE_ORGANISATIONS': {
      return action.ids.reduce(
        (nextFirms, id) => {
          if (id in nextFirms) {
            // eslint-disable-next-line fp/no-delete, no-param-reassign
            delete nextFirms[id];
          }
          return nextFirms;
        },
        {...state}
      );
    }
    case 'CLEAR_ORGANISATIONS':
      return {};
    default:
      return state;
  }
};

const organisationsError = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_ERROR':
      return action.message;
    case 'SET_ORGANISATIONS_SAVING':
      return false;
    default:
      return state;
  }
};

const organisationsLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_LOADING':
      return true;
    case 'SET_ORGANISATIONS_ERROR':
    case 'SET_ORGANISATIONS':
      return false;
    default:
      return state;
  }
};

const firmsLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_LOADING':
    case 'SET_FIRMS_LOADING':
      return true;
    case 'SET_ORGANISATIONS_ERROR':
    case 'SET_ORGANISATIONS':
    case 'SET_FIRMS_ERROR':
    case 'SET_FIRMS':
      return false;
    default:
      return state;
  }
};

const companiesLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_LOADING':
    case 'SET_COMPANIES_LOADING':
      return true;
    case 'SET_ORGANISATIONS_ERROR':
    case 'SET_ORGANISATIONS':
    case 'SET_COMPANIES_ERROR':
    case 'SET_COMPANIES':
      return false;
    default:
      return state;
  }
};

const subscribersLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_LOADING':
    case 'SET_SUBSCRIBERS_LOADING':
      return true;
    case 'SET_ORGANISATIONS_ERROR':
    case 'SET_ORGANISATIONS':
    case 'SET_SUBSCRIBERS_ERROR':
    case 'SET_SUBSCRIBERS':
      return false;
    default:
      return state;
  }
};

const organisationsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORGANISATIONS_LOADED':
      return Date.now();

    default:
      return state;
  }
};

const firmsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_FIRMS_LOADED':
    case 'SET_ORGANISATIONS_LOADED':
      return Date.now();

    default:
      return state;
  }
};

const companiesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_COMPANIES_LOADED':
    case 'SET_ORGANISATIONS_LOADED':
      return Date.now();

    default:
      return state;
  }
};

const subscribersLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIBERS_LOADED':
    case 'SET_ORGANISATIONS_LOADED':
      return Date.now();

    default:
      return state;
  }
};

export default combineReducers({
  firmIds,
  companyIds,
  subscriberIds,
  organisationsById,
  organisationsError,
  organisationsLoading,
  organisationsLoaded,
  firmsLoading,
  firmsLoaded,
  companiesLoading,
  companiesLoaded,
  subscribersLoading,
  subscribersLoaded,
});
