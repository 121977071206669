import React from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import {Form} from 'react-form';

class CaptchaForm extends React.Component {
  state = {
    values: {},
  };

  onResolved = () => {
    const {onSubmit} = this.props;
    const {values} = this.state;

    values.gRecaptchaResponse = this.recaptcha.getResponse();
    onSubmit(values);
  };

  onSubmit = values => {
    this.setState({values});
    this.recaptcha.execute();
  };

  render() {
    const {children, siteKey, onSubmit, ...rest} = this.props;

    return (
      <Form onSubmit={this.onSubmit} {...rest}>
        {formApi => {
          const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, {...formApi}));
          return (
            <div>
              {childrenWithProps}
              <Recaptcha ref={ref => (this.recaptcha = ref)} sitekey={siteKey} onResolved={this.onResolved} />
            </div>
          );
        }}
      </Form>
    );
  }
}

export default CaptchaForm;
