import Service from 'api';

export const get = () => Service.get(`auth/profile`);
export const logout = () => Service.get(`auth/logout`);
export const login = (email, password) => Service.post(`auth/login`, {email, password});
// login misc
export const forgot = (email, config) => Service.post('auth/forgotten', {email}, config);
export const reset = (data, config) => Service.post('auth/reset', data, config);
export const activate = (userId, hash, data = {}, config = {}) => Service.post(`auth/verify/${userId}/${hash}`, data, config);
export const acceptTerms = (data = {}, config = {}) => Service.post(`auth/terms/accept`, data, config);
// two factor authentication
export const twoFAVerify = (token, otpCurrent) => Service.post(`security/2fa/verify`, {token, otpCurrent});
export const otpCheck = token => Service.post(`security/2fa/check`, {token});
export const sendOtpEmail = token => Service.post(`security/2fa/send-email`, {token});
