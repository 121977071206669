import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchStaff} from '../actionCreators';
import {getUsersFilteredBy, getUsersLoading, getUsersError} from '../selectors';

//
const useFilterUsersBy = filterKeyword =>
  useCallback(
    state =>
      getUsersFilteredBy(state, {
        role: ['Admin', 'Staff', 'Researcher'],
        fullname: filterKeyword,
      }),
    [filterKeyword]
  );

const useStaff = filterKeyword => {
  const staff = useSelector(useFilterUsersBy(filterKeyword));
  const loading = useSelector(getUsersLoading);
  const error = useSelector(getUsersError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStaff());
    return () => null;
  }, [dispatch]);

  return [staff, loading, error];
};

export default useStaff;
