import {combineReducers} from 'redux';

import clients, * as fromClients from './clients';

export default combineReducers({
  clients,
});

// Clients UI Selectors
export const getClientFilter = state => fromClients.getClientFilter(state.clients);
