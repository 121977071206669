/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import classnames from 'classnames';
import {Tooltip} from '@blueprintjs/core';

import {MdError} from 'react-icons/md';

import {getCandidateFullname} from 'features/candidates';

import './UserAvatar.scss';

const UserAvatar = ({
  color,
  email,
  fullname,
  first_name,
  middle_name,
  last_name,
  avatar,
  avatar_url,
  size,
  tooltip,
  tooltipText,
  confirmEmail,
  className,
}) => {
  const userName = fullname || getCandidateFullname({first_name, middle_name, last_name});
  const avatarUrl = avatar_url || (avatar ? avatar.url : null);

  const missingEmail = confirmEmail && email === null;

  const avatarComponent = (
    <React.Fragment>
      <figure
        style={{height: size || '28', width: size || '28'}}
        className={classnames(
          'avatar',
          {
            'avatar--missing-email': missingEmail,
          },
          className
        )}>
        <Avatar color={color} maxInitials={2} size={size} name={userName} src={avatarUrl} round />
      </figure>
      {missingEmail && <MdError size={size / 2 + 2} className="avatar__missing-email" />}
    </React.Fragment>
  );

  return tooltip || tooltipText ? (
    <Tooltip
      content={
        <React.Fragment>
          {tooltipText || userName}
          {confirmEmail && email === null && " doesn't have a valid email"}
        </React.Fragment>
      }>
      {avatarComponent}
    </Tooltip>
  ) : (
    avatarComponent
  );
};

UserAvatar.propTypes = {
  color: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  fullname: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  middle_name: PropTypes.string,
  last_name: PropTypes.string,
  avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  avatar_url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.number,
  confirmEmail: PropTypes.bool,
  className: PropTypes.string,
};

UserAvatar.defaultProps = {
  color: null,
  size: 28,
  tooltip: false,
  tooltipText: null,
  confirmEmail: false,
  fullname: null,
  email: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  avatar: null,
  avatar_url: null,
  className: null,
};

export default UserAvatar;
