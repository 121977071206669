import {useMemo} from 'react';
import {useSessionPermissions} from 'features/session/selectors';

const useUserPermissionOptions = (firmType = null) => {
  const {hasStaffRole, hasAdminRole, hasSubscriberAdminRole} = useSessionPermissions();

  return useMemo(() => {
    if (firmType === 'recruiter') {
      if (hasAdminRole) {
        return ['Admin', 'Staff', 'Researcher', 'Portal'];
      }
      if (hasStaffRole) {
        return ['Researcher', 'Portal'];
      }
    }

    if (!firmType) {
      if (hasAdminRole) {
        return ['Admin', 'Staff', 'Researcher', 'Owner', 'Operator', 'Viewer', 'Portal'];
      }

      if (hasStaffRole) {
        return ['Researcher', 'Owner', 'Operator', 'Viewer', 'Portal'];
      }
    }

    if (hasAdminRole || hasStaffRole || hasSubscriberAdminRole) {
      return ['Owner', 'Operator', 'Viewer', 'Portal'];
    }

    return [];
  }, [firmType, hasAdminRole, hasStaffRole, hasSubscriberAdminRole]);
};

export default useUserPermissionOptions;
